import http from "../general/httpService";
import api from "../../constants/config.json";
import {
  PaymentVerification,
  PaymentVerificationResponse,
  PaymentVerificationListResponse,
  UnconfirmedFeesResponse,
  PaymentIntentVerificationRequest,
  FeeConfirmationRequest,
  FeeConfirmation,
  TenantAcceptanceModel,
} from "../../types/payment/payment-verification";

export async function verifyPayment(
  data: PaymentIntentVerificationRequest
): Promise<PaymentVerificationResponse> {
  const fullApiEndpoint =
    api.apiEndpoint + `/payment_verification/verify-payment`;
  const { data: result } = await http.post(fullApiEndpoint, data);
  return result;
}

export async function confirmFee(
  data: FeeConfirmationRequest
): Promise<PaymentVerification> {
  const fullApiEndpoint = api.apiEndpoint + `/payment_verification/confirm-fee`;
  const { data: result } = await http.post(fullApiEndpoint, data);
  return result;
}

export async function addFee(
  paymentVerificationId: string,
  fee: FeeConfirmation
): Promise<PaymentVerificationResponse> {
  const fullApiEndpoint =
    api.apiEndpoint + `/payment_verification/add-fee/${paymentVerificationId}`;
  const { data: result } = await http.post(fullApiEndpoint, fee);
  return result;
}

export async function getUnconfirmedFees(
  paymentVerificationId: string
): Promise<UnconfirmedFeesResponse> {
  const fullApiEndpoint =
    api.apiEndpoint +
    `/payment_verification/unconfirmed-fees/${paymentVerificationId}`;
  const { data: result } = await http.get(fullApiEndpoint);
  return result;
}

export async function getVerification(
  paymentIntentId: string
): Promise<PaymentVerification> {
  const fullApiEndpoint =
    api.apiEndpoint + `/payment_verification/verification/${paymentIntentId}`;
  const { data: result } = await http.get(fullApiEndpoint);
  return result;
}

export async function getVerificationsByContract(
  rentalContractId: string
): Promise<PaymentVerificationListResponse> {
  const fullApiEndpoint =
    api.apiEndpoint +
    `/payment_verification/verifications/contract/${rentalContractId}`;
  const { data: result } = await http.get(fullApiEndpoint);
  return result;
}

export async function getVerificationsByFeePack(
  rentalFeePackId: string
): Promise<PaymentVerificationListResponse> {
  const fullApiEndpoint =
    api.apiEndpoint +
    `/payment_verification/verifications/fee-pack/${rentalFeePackId}`;
  const { data: result } = await http.get(fullApiEndpoint);
  return result;
}

//Post process_tenant_acceptance
//belongs to tenant_rental_contract but is used in payment_verification since its related to tenant acceptance
export async function processTenantAcceptance(
  acceptance_data: TenantAcceptanceModel
): Promise<{ message?: string; error?: string }> {
  const fullApiEndpoint =
    api.apiEndpoint + `/tenant_rental_contract/process_tenant_acceptance`;
  const { data: result } = await http.post(fullApiEndpoint, acceptance_data);
  return result;
}
