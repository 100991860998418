import React, { useEffect, useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import PageTitleBox from "../../../../../reusable/title/pageTitleBox";
import { getPropertyBankAccountByPropBankId } from "../../../../../../services/bank/bankManager";
import { formatCurrency } from "../../../../../../services/utils/currencyUtils";
import { getAllExpensesForPropertyUnitBankAccount } from "../../../../../../services/propertyUnits/revenueAndExpenses/expenses/propertyUnitExpenseManagement";
import newDocumentImg from "../../../../../../styletheme/images/new-document.png";
import { getExpenseCategories } from "../../../../../../services/systemDefined/categories/expenses/sysExpenseCategoryManagement";
import { getRevenueCategories } from "../../../../../../services/systemDefined/categories/revenue/sysRevenueCategoryManagement";
import { getAllRevenueForPropertyUnitBankAccount } from "../../../../../../services/propertyUnits/revenueAndExpenses/revenue/propertyUnitRevenueManagement";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ShimmerEffect from "../../../../../reusable/loading/ShimmerEffect";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useThemeClasses } from "../../../../../../services/utils/hooks/useThemeClasses";

const PropertyBankAccountDetail = ({ isDarkMode }) => {
  const navigate = useNavigate();
  const { propertyBankAccountId } = useParams();
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const [totalAvailableBalance, setTotalAvailableBalance] = useState(0);
  const [bankAccount, setBankAccount] = useState({
    property_bank_account_id: "",
    bank_account_id: "",
    property_id: "",
    property_unit_id: "",
    bank_alias_name: "",
    account_balance: 0,
    is_reserve_account: false,
    reserve_amount: 0,
    bank_account: {
      account_id: "",
      account_name: "",
      account_mask: "",
      account_subtype: "",
      account_type: "",
      available_balance: 0,
      bank_account_id: "",
      bank_id: "",
      bank_name: "",
      created_at: "",
      currency_id: "",
      current_balance: 0,
      institution_id: "",
      is_default_account: false,
      logo: "",
      routing_number: "",
      routing_numbers: [],
      updated_at: "",
      url: "",
    },
    bank: {
      bank_country: "",
      bank_name: "",
      ownership_id: "",
      bank_id: "",
      created_at: "",
      institution_id: "",
      is_default_bank: false,
      logo: "",
      routing_number: "",
      routing_numbers: [],
      swift_code: "",
      updated_at: "",
      url: "",
    },
  });

  //revenue transactions
  const [revenueTransactions, setRevenueTransactions] = useState([
    {
      revenue_category_id: "",
      created_at: "",
      is_active: true,
      revenue_category: "",
      updated_at: "",
      property_bank_account_id: "",
      bank_account_id: "",
      bank_id: "",
      bank_name: "",
    },
  ]);

  //expenses transactions
  const [expensesTransactions, setExpensesTransactions] = useState([
    {
      expense_category_id: "",
      created_at: "",
      expense_category: "",
      is_active: true,
      updated_at: "",
      property_bank_account_id: "",
      bank_account_id: "",
      bank_id: "",
      bank_name: "",
    },
  ]);

  //combined transactions
  const [transactions, setTransactions] = useState([
    {
      is_revenue_or_expense: "",
      unit_revenue_expense_id: "",
      property_unit_id: "",
      category_id: 0,
      amount: 0,
      date: new Date(),
      is_active: true,
      is_received_from_tenant: false,
      payment_date: new Date(),
      is_capital_revenue: false,
      title: "",
      description: "",
      receipt_link: "",
      day: new Date().getDate(),
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      created_at: new Date(),
      updated_at: new Date(),
      property_bank_account_id: "",
      bank_account_id: "",
      bank_id: "",
      bank_name: "",
    },
  ]);
  const transactionColumns = [
    {
      field: "date",
      headerName: "Date",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name">
            {new Date(params.row.date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </div>
        </div>
      ),
    },
    {
      field: "logo_url",
      headerName: "",
      width: 90,
      renderCell: (params) => (
        <img
          src={params.row.logo_url ?? newDocumentImg}
          style={{ width: "80%", height: "80%" }}
          alt="transaction"
        />
      ),
    },
    {
      field: "account",
      headerName: "Account",
      width: 300,
      editable: true,
      //placeholder
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name">{params.row?.bank_name}</div>
        </div>
      ),
    },
    {
      field: "title",
      headerName: "Title",
      width: 250,
      editable: true,
      //placeholder
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name">{params.row.title}</div>
        </div>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      width: 350,
      editable: true,

      //placeholder
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name">{params.row.description}</div>
        </div>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      width: 110,
      editable: true,
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name">
            {/* Format the amount, two decimal places etc*/}

            {params.row.amount?.toFixed(2)}
          </div>
        </div>
      ),
    },
    {
      field: "category",
      headerName: "Category",
      description: "Category of the transaction",
      sortable: false,
      width: 300,
      editable: true,
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name">
            {findCategoryName(
              params.row.category_id,
              params.row.is_revenue_or_expense
            )}
          </div>
        </div>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      width: 110,
      editable: true,
      renderCell: (params) => {
        return (
          <span className="badge bg-primary-subtle text-primary">
            {params.row.is_revenue_or_expense}
          </span>
        );
      },
    },
  ];

  //get expense categories
  const [sysExpenseCategories, setSysExpenseCategories] = useState([]);

  const [sysRevenueCategories, setSysRevenueCategories] = useState([]);
  const fetchExpenseCategories = async () => {
    //get the expense categories

    const sysExpenseCategories = await getExpenseCategories();

    if (sysExpenseCategories) {
      setSysExpenseCategories(sysExpenseCategories);

      console.log(sysExpenseCategories);
    }
  };

  //get revenue categories
  const fetchRevenueCategories = async () => {
    //get the revenue categories
    const sysRevenueCategories = await getRevenueCategories();

    if (sysRevenueCategories) {
      setSysRevenueCategories(sysRevenueCategories);

      console.log(sysRevenueCategories);
    }
  };
  const findCategoryName = (categoryId, isRevenue) => {
    if (isRevenue === "revenue") {
      const category = sysRevenueCategories.find(
        (category) => category.revenue_category_id === categoryId
      );
      return category ? category.revenue_category : "";
    } else {
      const category = sysExpenseCategories.find(
        (category) => category.expense_category_id === categoryId
      );
      return category ? category.expense_category : "";
    }
  };

  const fetchAllTransactions = async (
    _propertyUnitId,
    _propertyBankAccountId
  ) => {
    const [revenueTransactions, expensesTransactions] = await Promise.all([
      fetchAllRevenueForPropertyUnitBankAccount(
        _propertyUnitId,
        _propertyBankAccountId
      ),
      fetchAllExpensesForPropertyUnitBankAccount(
        _propertyUnitId,
        _propertyBankAccountId
      ),
    ]);

    const transactions = [...revenueTransactions, ...expensesTransactions].map(
      (transaction) => {
        if (transaction.is_revenue_or_expense === "revenue") {
          return {
            is_revenue_or_expense: transaction.is_revenue_or_expense,
            unit_revenue_expense_id: transaction.unit_revenue_id,
            property_unit_id: transaction.property_unit_id,
            category_id: transaction.revenue_category_id,
            amount: transaction.revenue_amount,
            date: new Date(transaction.revenue_date),
            is_active: transaction.is_active,
            is_received_from_tenant: transaction.is_received_from_tenant,
            payment_date: new Date(transaction.payment_date),
            is_capital_revenue: transaction.is_capital_revenue,
            title: transaction.revenue_title,
            description: transaction.revenue_description,
            receipt_link: transaction.receipt_link,
            day: new Date(transaction.revenue_date).getDate(),
            month: new Date(transaction.revenue_date).getMonth() + 1,
            year: new Date(transaction.revenue_date).getFullYear(),
            created_at: new Date(transaction.created_at),
            updated_at: new Date(transaction.updated_at),
            property_bank_account_id: transaction.property_bank_account_id,
            bank_account_id: transaction.bank_account_id,
            bank_id: transaction.bank_id,
            bank_name: transaction.bank_name,
          };
        } else {
          return {
            is_revenue_or_expense: transaction.is_revenue_or_expense,
            unit_revenue_expense_id: transaction.unit_expense_id,
            property_unit_id: transaction.property_unit_id,
            category_id: transaction.expense_category_id,
            amount: transaction.expense_amount,
            date: new Date(transaction.expense_date),
            is_active: transaction.is_active,
            is_paid_by_tenant: transaction.is_paid_by_tenant,
            is_paid_by_landlord: transaction.is_paid_by_landlord,
            payment_date: new Date(transaction.payment_date),
            is_capital_expense: transaction.is_capital_expense,
            title: transaction.expense_title,
            description: transaction.expense_description,
            receipt_link: transaction.receipt_link,
            day: new Date(transaction.expense_date).getDate(),
            month: new Date(transaction.expense_date).getMonth() + 1,
            year: new Date(transaction.expense_date).getFullYear(),
            created_at: new Date(transaction.created_at),
            updated_at: new Date(transaction.updated_at),
            property_bank_account_id: transaction.property_bank_account_id,
            bank_account_id: transaction.bank_account_id,
            bank_id: transaction.bank_id,
            bank_name: transaction.bank_name,
          };
        }
      }
    );

    setTransactions(transactions);
    console.log("transactions");
    console.log(transactions);
  };

  /*
  useEffect(() => {
    window.scrollTo(0, 0);

    fetchPropertyBankAccount();
  }, []);
  */

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? "dark" : "light",
    },
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    Promise.all([fetchExpenseCategories(), fetchRevenueCategories()])
      .then(() => fetchPropertyBankAccount())
      .then(() => setIsLoading(false))
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, []);

  const fetchPropertyBankAccount = async () => {
    const result = await getPropertyBankAccountByPropBankId(
      propertyBankAccountId
    );

    if (result) {
      console.log("Property Bank Account:", result);
      setBankAccount(result);
      fetchAllTransactions(
        result.property_unit_id,
        result.property_bank_account_id
      );
    }
  };

  const fetchAllExpensesForPropertyUnitBankAccount = async (
    _propertyUnitId,
    _propertyBankAccountId
  ) => {
    const expensesTransactions = await getAllExpensesForPropertyUnitBankAccount(
      _propertyUnitId,
      _propertyBankAccountId
    );
    console.log("Expenses:", expensesTransactions);

    if (expensesTransactions) {
      const expensesTransactionsWithRevenueOrExpense = expensesTransactions.map(
        (transaction) => ({
          ...transaction,
          is_revenue_or_expense: "expense",
        })
      );

      setExpensesTransactions(expensesTransactionsWithRevenueOrExpense);

      console.log("Count:", expensesTransactionsWithRevenueOrExpense.length);
      return expensesTransactionsWithRevenueOrExpense; // Return the modified transactions
    }
  };

  const fetchAllRevenueForPropertyUnitBankAccount = async (
    _propertyUnitId,
    _propertyBankAccountId
  ) => {
    const revenueTransactions = await getAllRevenueForPropertyUnitBankAccount(
      _propertyUnitId,
      _propertyBankAccountId
    );
    console.log("Revenue:", revenueTransactions);

    if (revenueTransactions) {
      const revenueTransactionsWithRevenueOrExpense = revenueTransactions.map(
        (transaction) => ({
          ...transaction,
          is_revenue_or_expense: "revenue",
        })
      );

      setRevenueTransactions(revenueTransactionsWithRevenueOrExpense);

      console.log("Count:", revenueTransactionsWithRevenueOrExpense.length);
      return revenueTransactionsWithRevenueOrExpense; // Return the modified transactions
    }
  };

  return (
    <React.Fragment>
      <div className={`${getThemeClasses()}`}>
        <div className="container-fluid">
          <PageTitleBox pageTitle="Bank Account Detail" />
          <div className="row">
            <div className="col-xl-3 col-md-6">
              <div className="card card-height-100">
                <div className="card-body">
                  {isLoading ? (
                    <ShimmerEffect
                      type="card"
                      height={100}
                      darkMode={isDarkMode}
                    />
                  ) : (
                    <div className="d-flex align-items-center">
                      <div className="avatar-sm flex-shrink-0">
                        <span className="avatar-title bg-warning-subtle text-warning rounded-2 fs-2 material-shadow">
                          <i className=" ri-bank-line"></i>
                        </span>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <p className="text-lowercase fw-small text-muted mb-3">
                          Bank
                        </p>
                        <h4 className="fs-4 mb-3">
                          <span className="counter-value" data-target="7522">
                            {bankAccount?.bank?.bank_name}
                          </span>
                        </h4>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="card card-height-100">
                <div className="card-body">
                  {isLoading ? (
                    <ShimmerEffect
                      type="card"
                      height={100}
                      darkMode={isDarkMode}
                    />
                  ) : (
                    <div className="d-flex align-items-center">
                      <div className="avatar-sm flex-shrink-0">
                        <span className="avatar-title bg-info-subtle text-info rounded-2 fs-2 material-shadow">
                          <i className="bx bx-money"></i>
                        </span>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <p className="text-uppercase fw-medium text-muted mb-3">
                          Available Balance
                        </p>
                        <h4 className="fs-4 mb-3">
                          <span className="counter-value" data-target="7522">
                            {formatCurrency(
                              bankAccount?.bank_account?.available_balance
                            )}
                          </span>
                        </h4>
                      </div>
                      <div hidden className="flex-shrink-0 align-self-center">
                        <span className="badge bg-danger-subtle text-danger fs-12">
                          <i className="ri-arrow-down-s-line fs-13 align-middle me-1"></i>
                          10.35 %
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="col-lg-12">
              <div className="row">
                <div className="card-body">
                  <div>
                    <div className="p-2">
                      <ThemeProvider theme={theme}>
                        <Box sx={{ height: 650, width: "100%" }}>
                          {isLoading ? (
                            <ShimmerEffect
                              type="chart"
                              height={650}
                              darkMode={isDarkMode}
                            />
                          ) : (
                            <DataGrid
                              rows={transactions || []}
                              getRowId={(row) =>
                                row.unit_revenue_expense_id || 0
                              }
                              columns={transactionColumns}
                              initialState={{
                                pagination: {
                                  paginationModel: {
                                    pageSize: 10,
                                  },
                                },
                              }}
                              slots={{ toolbar: GridToolbar }}
                              slotProps={{
                                toolbar: {
                                  showQuickFilter: true,
                                },
                              }}
                              pageSizeOptions={[5]}
                              checkboxSelection
                              disableRowSelectionOnClick
                              processRowUpdate={(updatedRow, originalRow) => {
                                console.log(updatedRow, originalRow);
                                //update the transaction
                              }}
                              onProcessRowUpdateError={(
                                error,
                                updatedRow,
                                originalRow
                              ) => console.log(error, updatedRow, originalRow)}
                            />
                          )}
                        </Box>
                      </ThemeProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PropertyBankAccountDetail;
