import React from "react";
import { useNavigate } from "react-router-dom";
import { useSettings } from "../../services/authentication/contexts/settings/settingsContext";
import { useNotifications } from "../../context/notification/NotificationContext";
import { formatDistanceToNow } from "date-fns";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  DocumentTextIcon,
  ClipboardDocumentListIcon,
} from "@heroicons/react/24/outline";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const NotificationsPage: React.FC = () => {
  const navigate = useNavigate();
  const { settings } = useSettings();
  const { notifications, markAsRead, markAllAsRead } = useNotifications();

  const getIcon = (type: string) => {
    switch (type) {
      case "task":
        return ClipboardDocumentListIcon;
      case "ticket":
        return ExclamationCircleIcon;
      case "contract":
        return DocumentTextIcon;
      default:
        return CheckCircleIcon;
    }
  };

  const handleNotificationClick = (notification: any) => {
    markAsRead(notification.id);
    navigate(notification.href);
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };

  return (
    <div className="tw-container tw-mx-auto tw-px-4 tw-py-8">
      <div className="tw-flex tw-items-center tw-justify-between tw-mb-6">
        <h1
          className={classNames(
            "tw-text-2xl tw-font-bold",
            settings.isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
          )}
        >
          Notifications
        </h1>
        <button
          onClick={markAllAsRead}
          className={classNames(
            "tw-px-4 tw-py-2 tw-rounded-md tw-text-sm tw-font-medium",
            settings.isDarkMode
              ? "tw-bg-gray-700 tw-text-gray-100 hover:tw-bg-gray-600"
              : "tw-bg-gray-100 tw-text-gray-700 hover:tw-bg-gray-200"
          )}
        >
          Mark all as read
        </button>
      </div>

      {notifications.length === 0 ? (
        <div
          className={classNames(
            "tw-text-center tw-py-12",
            settings.isDarkMode ? "tw-text-gray-400" : "tw-text-gray-500"
          )}
        >
          <p className="tw-text-lg">No notifications yet</p>
        </div>
      ) : (
        <div className="tw-space-y-4">
          {notifications.map((notification) => {
            const Icon = getIcon(notification.type);
            return (
              <div
                key={notification.id}
                onClick={() => handleNotificationClick(notification)}
                className={classNames(
                  "tw-p-4 tw-rounded-lg tw-shadow-sm tw-transition-colors tw-cursor-pointer",
                  !notification.read
                    ? settings.isDarkMode
                      ? "tw-bg-gray-800"
                      : "tw-bg-blue-50"
                    : settings.isDarkMode
                    ? "tw-bg-gray-900"
                    : "tw-bg-white",
                  settings.isDarkMode
                    ? "hover:tw-bg-gray-700"
                    : "hover:tw-bg-gray-50"
                )}
              >
                <div className="tw-flex tw-items-start tw-gap-4">
                  <div
                    className={classNames(
                      "tw-mt-1 tw-flex tw-h-10 tw-w-10 tw-flex-none tw-items-center tw-justify-center tw-rounded-lg",
                      settings.isDarkMode ? "tw-bg-gray-700" : "tw-bg-gray-100"
                    )}
                  >
                    <Icon className="tw-h-5 tw-w-5 tw-text-gray-600" />
                  </div>
                  <div className="tw-flex-1">
                    <h3
                      className={classNames(
                        "tw-text-sm tw-font-medium",
                        settings.isDarkMode
                          ? "tw-text-gray-100"
                          : "tw-text-gray-900"
                      )}
                    >
                      {truncateText(notification.title, 100)}
                    </h3>
                    <p
                      className={classNames(
                        "tw-mt-1 tw-text-sm",
                        settings.isDarkMode
                          ? "tw-text-gray-400"
                          : "tw-text-gray-500"
                      )}
                    >
                      {truncateText(notification.description, 200)}
                    </p>
                    <p
                      className={classNames(
                        "tw-mt-2 tw-text-xs",
                        settings.isDarkMode
                          ? "tw-text-gray-500"
                          : "tw-text-gray-400"
                      )}
                    >
                      {formatDistanceToNow(notification.timestamp, {
                        addSuffix: true,
                      })}
                    </p>
                  </div>
                  {!notification.read && (
                    <div className="tw-ml-4">
                      <span className="tw-inline-block tw-h-2 tw-w-2 tw-rounded-full tw-bg-blue-400"></span>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default NotificationsPage;
