import React from "react";

export interface InputProps {
  name: string;
  label: string;
  type: string;
  value?: string | number;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement> | { name: string; value: string }
  ) => void;
  error?: string;
  // Optional props
  className?: string;
  placeholder?: string;
  labelClass?: string;
  isFocused?: boolean;
  isDisabled?: boolean;
  min?: string | number;
  max?: string | number;
}

const InputTS: React.FC<InputProps> = ({
  name,
  label,
  type,
  value,
  onChange,
  error,
  // Optional props with defaults
  className = "form-control",
  placeholder = "",
  labelClass = "",
  isFocused = false,
  isDisabled = false,
  min,
  max,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (type === "date") {
      onChange({ name, value: event.target.value });
    } else {
      onChange(event);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    if (type === "number" && target.value === "0") {
      target.value = "";
    }
  };

  return (
    <div className="form-group">
      <label className={labelClass} htmlFor={name}>
        {label}
      </label>

      <input
        autoFocus={isFocused}
        value={value}
        onChange={handleChange}
        onClick={handleClick}
        placeholder={placeholder}
        id={name}
        name={name}
        type={type}
        className={className}
        disabled={isDisabled}
        min={min}
        max={max}
      />

      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default InputTS;
