import http from "../../general/httpService";
import api from "../../../constants/config.json";
import config from "../../../constants/config";
//rental_fee_pack/create_rental_fee_pack_template
export async function createRentalFeePack(rentalFeePack) {
  const fullApiEndpoint =
    api.apiEndpoint + `/rental_fee_pack/create_rental_fee_pack_template`;
  const { data: result } = await http.post(fullApiEndpoint, rentalFeePack);

  return result;
}

//update_rental_fee_pack_template
export async function updateRentalFeePack(rentalFeePack) {
  const fullApiEndpoint =
    api.apiEndpoint + `/rental_fee_pack/update_rental_fee_pack_template`;
  const { data: result } = await http.put(fullApiEndpoint, rentalFeePack);

  return result;
}

//get_rental_fee_pack_by_id
export async function getRentalFeePackById(rentalFeePackId) {
  const fullApiEndpoint =
    api.apiEndpoint +
    `/rental_fee_pack/get_rental_fee_pack_by_id/${rentalFeePackId}`;
  const { data: result } = await http.get(fullApiEndpoint);

  return result;
}

//get_rental_fee_pack_template_by_id
export async function getRentalFeePackTemplateById(rentalFeePackTemplateId) {
  const fullApiEndpoint =
    api.apiEndpoint +
    `/rental_fee_pack/get_rental_fee_pack_template_by_id/${rentalFeePackTemplateId}`;
  const { data: result } = await http.get(fullApiEndpoint);

  return result;
}

//get_rental_fee_pack_templates/{user_id}
export async function getRentalFeePackTemplates() {
  const user_id = localStorage.getItem(config.user_id);
  const fullApiEndpoint =
    api.apiEndpoint +
    `/rental_fee_pack/get_rental_fee_pack_templates/${user_id}`;
  const { data: result } = await http.get(fullApiEndpoint);

  return result;
}

//copy_rental_fee_pack_template_to_campaign/{rental_fee_pack_template_id}/{campaign_id}
export async function copyRentalFeePackTemplateToCampaign(
  rentalFeePackTemplateId,
  campaignId
) {
  const fullApiEndpoint =
    api.apiEndpoint +
    `/rental_fee_pack/copy_rental_fee_pack_template_to_campaign/${rentalFeePackTemplateId}/${campaignId}`;
  const { data: result } = await http.get(fullApiEndpoint);

  return result;
}
