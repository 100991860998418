import http from "../services/general/httpService";
import config from "../constants/config.json";
import {
  StripeConnectFormData,
  StripeConnectResponse,
  StripeConnectStatusResponse,
  StripeConnectRequirementsResponse,
} from "../types/stripe-connect";

const apiEndpoint = config.apiEndpoint + "/stripe/connect";

export async function createOrUpdateConnectAccount(
  data: StripeConnectFormData
): Promise<StripeConnectResponse> {
  const { data: response } = await http.post(
    `${apiEndpoint}/create-or-update`,
    data
  );
  return response;
}

export async function getConnectAccountStatus(
  accountId: string
): Promise<StripeConnectStatusResponse> {
  const { data: response } = await http.get(
    `${apiEndpoint}/status/${accountId}`
  );
  return response;
}

export async function refreshOnboardingLink(
  accountId: string
): Promise<{ onboarding_link: string }> {
  const { data: response } = await http.post(
    `${apiEndpoint}/refresh-onboarding`,
    { account_id: accountId }
  );
  return response;
}

export async function getAccountRequirements(
  accountId: string
): Promise<StripeConnectRequirementsResponse> {
  const { data: response } = await http.get(
    `${apiEndpoint}/requirements/${accountId}`
  );
  return response;
}

export function openOnboardingWindow(url: string): Window | null {
  return window.open(url, "StripeConnect", "width=600,height=800");
}

export function pollOnboardingCompletion(windowRef: Window): Promise<void> {
  return new Promise((resolve, reject) => {
    const checkWindow = setInterval(() => {
      if (!windowRef || windowRef.closed) {
        clearInterval(checkWindow);
        resolve();
      }
    }, 1000);

    // Timeout after 30 minutes
    setTimeout(() => {
      clearInterval(checkWindow);
      reject(new Error("Onboarding window timed out"));
    }, 30 * 60 * 1000);
  });
}
