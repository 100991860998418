import React, { useState, useEffect } from "react";
import { Popover } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "../../context/notification/NotificationContext";
import { formatDistanceToNow } from "date-fns";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  DocumentTextIcon,
  ClipboardDocumentListIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

//TODO: next need to when pressed take to the correct page to take action, first the verify payment page

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const NotificationHub: React.FC<{ isDarkMode?: boolean }> = ({
  isDarkMode,
}) => {
  const { notifications, unreadCount, markAsRead, markAllAsRead } =
    useNotifications();
  const navigate = useNavigate();

  const getIcon = (type: string) => {
    switch (type) {
      case "task":
        return ClipboardDocumentListIcon;
      case "ticket":
        return ExclamationCircleIcon;
      case "contract":
        return DocumentTextIcon;
      default:
        return CheckCircleIcon;
    }
  };

  const handleNotificationClick = (notification: any, close: () => void) => {
    markAsRead(notification.id);
    navigate(notification.href);
    close();
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };

  return (
    <Popover className="tw-relative">
      {({ close }) => (
        <>
          <Popover.Button className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle tw-relative">
            <i className="bx bx-bell fs-22"></i>
            {unreadCount > 0 && (
              <span className="tw-absolute -tw-top-1 -tw-right-1 tw-flex tw-h-4 tw-w-4 tw-items-center tw-justify-center tw-rounded-full tw-bg-red-500 tw-text-[10px] tw-text-white tw-font-medium">
                {unreadCount}
              </span>
            )}
          </Popover.Button>

          <Popover.Panel className="tw-absolute tw-right-0 tw-z-[1000] tw-mt-2 tw-w-screen tw-max-w-[calc(100vw-2rem)] lg:tw-max-w-sm tw-transform tw-px-2">
            <div
              className={classNames(
                "tw-overflow-hidden tw-rounded-lg tw-shadow-lg tw-ring-1 tw-ring-black/5",
                isDarkMode ? "tw-bg-[#1b222c]" : "tw-bg-white"
              )}
            >
              <div className="tw-p-4">
                <div className="tw-flex tw-items-center tw-justify-between">
                  <h5
                    className={classNames(
                      "tw-m-0 tw-text-sm tw-font-medium",
                      isDarkMode ? "tw-text-gray-200" : "tw-text-gray-900"
                    )}
                  >
                    Notifications
                  </h5>
                  <Link
                    to="/notifications"
                    onClick={() => close()}
                    className={classNames(
                      "tw-text-sm tw-font-medium",
                      isDarkMode
                        ? "tw-text-blue-400 hover:tw-text-blue-300"
                        : "tw-text-blue-600 hover:tw-text-blue-500"
                    )}
                  >
                    View All
                  </Link>
                </div>

                <div className="tw-divide-y tw-divide-gray-700">
                  {notifications.slice(0, 5).map((notification) => {
                    const Icon = getIcon(notification.type);
                    return (
                      <div
                        key={notification.id}
                        onClick={() =>
                          handleNotificationClick(notification, close)
                        }
                        className={classNames(
                          "tw-flex tw-gap-x-4 tw-p-4 tw-cursor-pointer",
                          !notification.read && isDarkMode
                            ? "tw-bg-gray-800/50"
                            : !notification.read
                            ? "tw-bg-blue-50"
                            : "",
                          isDarkMode
                            ? "hover:tw-bg-gray-800"
                            : "hover:tw-bg-gray-50"
                        )}
                      >
                        <div
                          className={classNames(
                            "tw-mt-1 tw-flex tw-h-10 tw-w-10 tw-flex-none tw-items-center tw-justify-center tw-rounded-lg",
                            isDarkMode ? "tw-bg-gray-800" : "tw-bg-gray-100"
                          )}
                        >
                          <Icon
                            className={classNames(
                              "tw-h-5 tw-w-5",
                              isDarkMode
                                ? "tw-text-gray-400"
                                : "tw-text-gray-600"
                            )}
                          />
                        </div>
                        <div className="tw-flex-auto tw-min-w-0">
                          <h6
                            className={classNames(
                              "tw-text-sm tw-font-semibold tw-truncate",
                              isDarkMode
                                ? "tw-text-gray-200"
                                : "tw-text-gray-900"
                            )}
                          >
                            {truncateText(notification.title, 50)}
                          </h6>
                          <p
                            className={classNames(
                              "tw-mt-1 tw-text-sm tw-line-clamp-2",
                              isDarkMode
                                ? "tw-text-gray-400"
                                : "tw-text-gray-600"
                            )}
                          >
                            {truncateText(notification.description, 100)}
                          </p>
                          <p
                            className={classNames(
                              "tw-mt-1 tw-text-xs",
                              isDarkMode
                                ? "tw-text-gray-500"
                                : "tw-text-gray-400"
                            )}
                          >
                            {formatDistanceToNow(notification.timestamp, {
                              addSuffix: true,
                            })}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div
                className={classNames(
                  "tw-p-4",
                  isDarkMode ? "tw-bg-gray-800/50" : "tw-bg-gray-50"
                )}
              >
                <button
                  onClick={() => {
                    navigate("/notifications");
                    close();
                  }}
                  className={classNames(
                    "tw-block tw-w-full tw-rounded-lg tw-p-2 tw-text-center tw-text-sm tw-font-semibold",
                    isDarkMode
                      ? "tw-text-gray-200 hover:tw-bg-gray-700"
                      : "tw-text-gray-900 hover:tw-bg-gray-100"
                  )}
                >
                  View All Notifications
                </button>
              </div>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default NotificationHub;
