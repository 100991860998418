import React from "react";
import SignatureCanvas from "react-signature-canvas";
import { Trash } from "react-bootstrap-icons";

const SignaturePad = ({ onSave, onClear, isDarkMode }) => {
  let sigPad = React.useRef(null);
  const containerRef = React.useRef(null);
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 200 });

  React.useEffect(() => {
    if (containerRef.current) {
      const width = containerRef.current.offsetWidth;
      setDimensions({ width, height: 200 });
    }
  }, []);

  const clear = () => {
    sigPad.current.clear();
    onClear();
  };

  const save = () => {
    if (!sigPad.current.isEmpty()) {
      // Create a temporary canvas
      const tempCanvas = document.createElement("canvas");
      const tempCtx = tempCanvas.getContext("2d");
      const originalCanvas = sigPad.current.getCanvas();

      // Match dimensions
      tempCanvas.width = originalCanvas.width;
      tempCanvas.height = originalCanvas.height;

      // Draw original signature
      tempCtx.drawImage(originalCanvas, 0, 0);

      // Convert to black
      tempCtx.globalCompositeOperation = "source-in";
      tempCtx.fillStyle = "#000000";
      tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

      // Get the black signature data
      const signatureData = tempCanvas.toDataURL();
      onSave(signatureData);
    }
  };

  return (
    <div>
      <div
        ref={containerRef}
        className="tw-border tw-rounded-lg tw-p-3 tw-mb-3"
      >
        <SignatureCanvas
          ref={sigPad}
          penColor={isDarkMode ? "#ffffff" : "#000000"}
          canvasProps={{
            className: "tw-w-full tw-h-full",
            width: dimensions.width,
            height: dimensions.height,
            style: {
              background: isDarkMode ? "#1f2937" : "#ffffff",
            },
          }}
        />
      </div>
      <div className="tw-flex tw-justify-end tw-gap-2">
        <button
          onClick={clear}
          className="tw-flex tw-items-center tw-px-3 tw-py-1 tw-rounded-md tw-bg-red-50 tw-text-red-600 hover:tw-bg-red-100"
        >
          <Trash className="tw-mr-1" size={16} /> Clear
        </button>
        <button
          onClick={save}
          className="tw-px-3 tw-py-1 tw-rounded-md tw-bg-green-600 tw-text-white hover:tw-bg-green-700"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default SignaturePad;
