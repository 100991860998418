export const themeColors = {
  light: {
    background: "tw-bg-white",
    border: "tw-border-gray-200",
    text: "tw-text-gray-900",
  },
  dark: {
    background: "tw-bg-gray-800",
    border: "tw-border-gray-700",
    text: "tw-text-gray-100",
  },
};

export const themeTransitions = {
  default: "tw-transition-all tw-duration-200",
  hover: "hover:tw-shadow-md",
};

// src/theme/colors.ts

interface ColorScheme {
  background: {
    primary: string;
    secondary: string;
    table: {
      header: string;
      body: string;
      divider: string;
      ring: string;
    };
  };
  text: {
    primary: string;
    secondary: string;
    tertiary: string;
  };
  status: {
    approved: {
      background: string;
      text: string;
    };
    rejected: {
      background: string;
      text: string;
    };
    pending: {
      background: string;
      text: string;
    };
  };
  button: {
    primary: {
      background: string;
      hover: string;
      text: string;
    };
  };
}

export const colors: Record<"light" | "dark", ColorScheme> = {
  light: {
    background: {
      primary: "tw-bg-white",
      secondary: "tw-bg-gray-50",
      table: {
        header: "tw-bg-white",
        body: "tw-bg-white",
        divider: "tw-divide-gray-200",
        ring: "tw-ring-black/5",
      },
    },
    text: {
      primary: "tw-text-gray-900",
      secondary: "tw-text-gray-600",
      tertiary: "tw-text-gray-500",
    },
    status: {
      approved: {
        background: "tw-bg-green-100",
        text: "tw-text-green-700",
      },
      rejected: {
        background: "tw-bg-red-100",
        text: "tw-text-red-700",
      },
      pending: {
        background: "tw-bg-yellow-100",
        text: "tw-text-yellow-700",
      },
    },
    button: {
      primary: {
        background: "tw-text-indigo-600",
        hover: "hover:tw-text-indigo-500",
        text: "tw-text-white",
      },
    },
  },
  dark: {
    background: {
      primary: "tw-bg-gray-900",
      secondary: "tw-bg-gray-800",
      table: {
        header: "tw-bg-gray-800",
        body: "tw-bg-gray-900",
        divider: "tw-divide-gray-700",
        ring: "tw-ring-white/5",
      },
    },
    text: {
      primary: "tw-text-gray-200",
      secondary: "tw-text-gray-400",
      tertiary: "tw-text-gray-400",
    },
    status: {
      approved: {
        background: "tw-bg-green-900/50",
        text: "tw-text-green-400",
      },
      rejected: {
        background: "tw-bg-red-900/50",
        text: "tw-text-red-400",
      },
      pending: {
        background: "tw-bg-yellow-900/50",
        text: "tw-text-yellow-400",
      },
    },
    button: {
      primary: {
        background: "tw-text-indigo-400",
        hover: "hover:tw-text-indigo-300",
        text: "tw-text-white",
      },
    },
  },
};

export const getThemeColors = (isDarkMode: boolean) =>
  colors[isDarkMode ? "dark" : "light"];
