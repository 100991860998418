import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  PaymentReceipt,
  PaymentStatus,
} from "../../../types/payment/payment-receipt";
import { getPaymentReceipt } from "../../../services/payment/paymentReceiptService";
import { formatCurrency } from "../../../services/utils/currencyUtils";
import { devLog } from "../../../services/utils/logger";
import { useThemeClasses } from "../../../services/utils/hooks/useThemeClasses";
import ShimmerEffect from "../../reusable/loading/ShimmerEffect";
import PageTitleBox from "../../reusable/title/pageTitleBox";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

interface PaymentReceiptDetailProps {
  isDarkMode?: boolean;
}

const PaymentReceiptDetail: React.FC<PaymentReceiptDetailProps> = ({
  isDarkMode = false,
}) => {
  const { receiptId } = useParams<{ receiptId: string }>();
  const navigate = useNavigate();
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const [isLoading, setIsLoading] = useState(true);
  const [receipt, setReceipt] = useState<PaymentReceipt | null>(null);

  useEffect(() => {
    if (receiptId) {
      fetchReceipt();
    }
  }, [receiptId]);

  const fetchReceipt = async () => {
    try {
      setIsLoading(true);
      const data = await getPaymentReceipt(receiptId!);
      setReceipt(data);
    } catch (error) {
      devLog("Error fetching receipt:", error);
      toast.error("Failed to load receipt details");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className={getThemeClasses()}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle="Receipt Details"
            previousPageTitle=""
            previousPageLink="/"
          />
          <div className="row">
            <div className="col-12">
              <ShimmerEffect
                type="detail"
                height="400px"
                width="100%"
                darkMode={isDarkMode}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!receipt) {
    return (
      <div className={getThemeClasses()}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle="Receipt Details"
            previousPageTitle=""
            previousPageLink="/"
          />
          <div className="alert alert-danger">Receipt not found</div>
        </div>
      </div>
    );
  }

  return (
    <div className={getThemeClasses()}>
      <div className="container-fluid">
        <PageTitleBox
          pageTitle={`Receipt #${receipt.receipt_number}`}
          previousPageTitle=""
          previousPageLink="/"
        />

        <div className="row">
          <div className="col-lg-8">
            <Card className={`${getThemeClasses()} mb-4`}>
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <h5 className="card-title">Receipt Details</h5>
                </div>

                <div className="row mb-4">
                  <div className="col-md-6">
                    <h6>Tenant Information</h6>
                    <p>
                      {receipt.tenant_id}
                      <br />
                    </p>
                  </div>
                  <div className="col-md-6">
                    <h6>Property Information</h6>
                    <p>Property Unit ID: {receipt.property_unit_id}</p>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Description</th>
                        <th>Period</th>
                        <th className="text-end">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {receipt.line_items.map((item) => (
                        <tr key={item.item_name}>
                          <td>{item.item_name}</td>
                          <td>{item.description || "-"}</td>
                          <td>
                            {item.period_start
                              ? `${new Date(
                                  item.period_start
                                ).toLocaleDateString()} - ${new Date(
                                  item.period_end!
                                ).toLocaleDateString()}`
                              : "-"}
                          </td>
                          <td className="text-end">
                            {formatCurrency(item.amount)}
                          </td>
                        </tr>
                      ))}
                      <tr className="fw-bold">
                        <td colSpan={3}>Total</td>
                        <td className="text-end">
                          {formatCurrency(receipt.total_amount)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="mt-4">
                  <h6>Payment Information</h6>
                  <p>
                    Status:{" "}
                    <span
                      className={`badge ${
                        receipt.payment_status === PaymentStatus.COMPLETED
                          ? "bg-success"
                          : receipt.payment_status === PaymentStatus.PENDING
                          ? "bg-warning"
                          : "bg-danger"
                      }`}
                    >
                      {receipt.payment_status}
                    </span>
                    <br />
                    Method: {receipt.payment_method}
                    <br />
                    Date: {new Date(receipt.receipt_date).toLocaleDateString()}
                  </p>
                </div>

                {receipt.notes && (
                  <div className="mt-4">
                    <h6>Notes</h6>
                    <p>{receipt.notes}</p>
                  </div>
                )}
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentReceiptDetail;
