import { useThemeClasses } from "../../../services/utils/hooks/useThemeClasses";

interface HtmlPreviewProps {
  htmlContent?: string;
  height?: string;
  padding?: string;
  border?: string;
  borderRadius?: string;
  className?: string;
  isDarkMode?: boolean;
}

function HtmlPreview({
  htmlContent,
  height = "840px",
  padding = "10px",
  border = "1px solid #ddd",
  borderRadius = "5px",
  className = "mb-5",
  isDarkMode = false,
}: HtmlPreviewProps) {
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  return (
    <div
      className={`${getThemeClasses()}`}
      dangerouslySetInnerHTML={{
        __html:
          htmlContent?.replace(
            /<img /g,
            '<img style="max-width: 100%; height: auto;" '
          ) || "",
      }}
      style={{
        height,
        padding,
        border: isDarkMode ? "1px solid #495057" : border,
        borderRadius,
        overflow: "auto",
        backgroundColor: isDarkMode ? "#212529" : "white",
        color: isDarkMode ? "#e9ecef" : "inherit",
      }}
    />
  );
}

export default HtmlPreview;
