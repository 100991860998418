import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { formatDate } from "../../../../services/utils/dateUtils";
import { ThemeProvider, createTheme } from "@mui/material/styles";

export default function FeePackListTable({ rentalFeePacks, isDarkMode }) {
  const theme = createTheme({
    palette: {
      mode: isDarkMode ? "dark" : "light",
    },
  });
  // Define columns for DataGrid
  const columns = [
    {
      field: "fee_pack_name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "currency_name",
      headerName: "Currency",
      flex: 1,
    },
    {
      field: "fee_pack_description",
      headerName: "Description",
      flex: 2,
      hideable: true,
    },
    {
      field: "created_by",
      headerName: "Created By",
      flex: 1,
      hideable: true,
    },
    {
      field: "created_at",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => formatDate(params.row.created_at),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      sortable: false,
      renderCell: (params) => (
        <Link
          to={`/updateRentalFeePack/${params.row.rental_fee_pack_template_id}`}
          className={
            isDarkMode
              ? "tw-text-indigo-400 hover:tw-text-indigo-300"
              : "tw-text-indigo-600 hover:tw-text-indigo-900"
          }
        >
          Edit
        </Link>
      ),
    },
  ];

  // Prepare rows with unique ids
  const rows = rentalFeePacks.map((pack) => ({
    ...pack,
    id: pack.rental_fee_pack_template_id, // DataGrid requires unique id field
  }));

  return (
    <div
      className={`tw-px-4 tw-sm:px-6 tw-lg:px-8 card ${
        isDarkMode ? "tw-bg-gray-900" : "tw-bg-white"
      }`}
    >
      {/* Header Section */}
      <div className="tw-flex tw-items-center tw-justify-between">
        <div className="tw-flex-1">
          <h1
            className={`tw-text-base tw-font-semibold ${
              isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
            }`}
          >
            Fee Packs
          </h1>
          <p
            className={`tw-mt-2 tw-text-sm ${
              isDarkMode ? "tw-text-gray-300" : "tw-text-gray-600"
            }`}
          >
            A list of all the fee packs in your account. Fee packs are used to
            create templates of fees for your rental properties.
          </p>
        </div>
        <div className="tw-ml-4 tw-flex-shrink-0">
          <Link
            to="/createRentalFeePack"
            className="tw-rounded-md tw-bg-indigo-600 tw-px-3 tw-py-2 tw-text-sm 
                     tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-indigo-500"
          >
            Add fee pack
          </Link>
        </div>
      </div>

      {/* DataGrid */}
      <div className="tw-mt-8" style={{ height: 400, width: "100%" }}>
        <ThemeProvider theme={theme}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 25]}
            checkboxSelection={false}
            disableSelectionOnClick
            sx={{
              "& .MuiDataGrid-root": {
                backgroundColor: isDarkMode ? "#1f2937" : "#ffffff",
                color: isDarkMode ? "#f3f4f6" : "#111827",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: isDarkMode ? "#374151" : "#f3f4f6",
              },
              "& .MuiDataGrid-cell": {
                borderColor: isDarkMode ? "#374151" : "#e5e7eb",
              },
              "& .MuiDataGrid-columnSeparator": {
                color: isDarkMode ? "#4b5563" : "#e5e7eb",
              },
              "& .MuiTablePagination-root": {
                color: isDarkMode ? "#f3f4f6" : "#111827",
              },
            }}
          />
        </ThemeProvider>
      </div>
    </div>
  );
}
