import React from "react";
import { Modal, Button } from "react-bootstrap";
import { PencilFill, Upload } from "react-bootstrap-icons";
import SignaturePad from "./SignaturePad";
import SignatureUpload from "./SignatureUpload";

interface SignatureModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSign: () => void;
  signature: string;
  setSignature: (signature: string) => void;
  isDarkMode: boolean;
}

export const SignatureModal: React.FC<SignatureModalProps> = ({
  isOpen,
  onClose,
  onSign,
  signature,
  setSignature,
  isDarkMode,
}) => {
  const [signatureMode, setSignatureMode] = React.useState<"draw" | "upload">(
    "draw"
  );

  const handleSignatureUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (!file.type.startsWith("image/")) {
      // Handle error
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setSignature(reader.result as string);
    };
    reader.onerror = () => {
      // Handle error
    };
    reader.readAsDataURL(file);
  };

  const handleSaveSignature = () => {
    if (signature) {
      onSign();
      onClose();
    }
  };

  return (
    <Modal show={isOpen} onHide={onClose} size="lg">
      <Modal.Header closeButton className="border-0">
        <div>
          <Modal.Title className="text-xl font-semibold">
            Add Your Signature
          </Modal.Title>
          <p className="text-sm text-gray-500 mt-1">
            Draw your signature or upload an existing one
          </p>
        </div>
      </Modal.Header>

      <Modal.Body className="pt-0">
        <div className="mb-4">
          <div className="flex gap-4">
            <Button
              variant={signatureMode === "draw" ? "primary" : "light"}
              onClick={() => setSignatureMode("draw")}
              className="px-4 py-2 flex items-center w-[120px] justify-center"
            >
              <PencilFill className="mr-3" size={14} />
              Draw
            </Button>
            <Button
              variant={signatureMode === "upload" ? "primary" : "light"}
              onClick={() => setSignatureMode("upload")}
              className="px-4 py-2 w-[120px]"
            >
              <div className="flex items-center justify-center w-full">
                <Upload className="mr-3" size={14} />
                Upload
              </div>
            </Button>
          </div>
        </div>

        {signatureMode === "draw" ? (
          <SignaturePad
            onSave={setSignature}
            onClear={() => setSignature("")}
            isDarkMode={isDarkMode}
          />
        ) : (
          <SignatureUpload
            signature={signature}
            onUpload={handleSignatureUpload}
            onClear={() => setSignature("")}
            onSave={handleSaveSignature}
          />
        )}
      </Modal.Body>

      <Modal.Footer className="border-0">
        <Button variant="light" onClick={onClose} className="me-2">
          Cancel
        </Button>
        <Button variant="primary" onClick={onSign} disabled={!signature}>
          Sign Document
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
