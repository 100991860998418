import React, { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import PageTitleBox from "../../../reusable/title/pageTitleBox";
import LoaderView from "../../../reusable/loading/loaderView";

import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import NoRecordsFound from "../../tickets/list/sub/noRecordsFound";
import config from "../../../../constants/config.json";
import { getUniversalListAndItemsByUserId } from "../../../../services/task/universalListManagement";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useThemeClasses } from "../../../../services/utils/hooks/useThemeClasses";

const UniversalList = ({ isDarkMode }) => {
  const { propertyId } = useParams();
  const navigate = useNavigate();
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const [isLoading, setIsLoading] = useState(false);
  const [universalLists, setUniversalLists] = useState([]);
  const user_id = localStorage.getItem(config.user_id);

  const listTypeMap = {
    general: "General",
    tenant_screening: "Tenant Screening",
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    move_in: "Move-In",
    move_out: "Move-Out",
    maintenance: "Maintenance",
    seasonal: "Seasonal",
    emergency: "Emergency",
    legal_compliance: "Legal Compliance",
    financial: "Financial",
  };

  const columns = [
    { field: "name", headerName: "Name", width: 250 },
    { field: "description", headerName: "Description", width: 350 },
    {
      field: "list_type",
      headerName: "List Type",
      width: 180,
      valueGetter: (params) => {
        console.log("Value received:", params);
        let listType = params;

        // If params is an object (as originally expected), try to get list_type from it
        if (typeof params === "object" && params !== null) {
          listType = params.value || (params.row && params.row.list_type);
        }

        if (typeof listType === "string") {
          const cleanedType = listType.trim().toLowerCase();
          console.log("Cleaned list type:", cleanedType);
          const mappedType = listTypeMap[cleanedType];
          console.log("Mapped type:", mappedType);
          return mappedType || listType;
        }

        return "Unknown";
      },
    },

    { field: "items_count", headerName: "Items Count", width: 120 },
  ];

  useEffect(() => {
    setIsLoading(true);

    fetchUniversalLists();
  }, [user_id]);

  const fetchUniversalLists = async () => {
    try {
      const result = await getUniversalListAndItemsByUserId(user_id);
      const formattedLists = result.map(
        ({ universal_list, universal_list_items }) => ({
          ...universal_list,
          items_count: universal_list_items.length,
          id: universal_list.universal_list_id, // Required for DataGrid
          campaign_ids: universal_list.campaign_ids.join(", "),
          list_type: universal_list.list_type || "Unknown", // Ensure list_type is included
        })
      );
      console.log("Formatted lists:", formattedLists); // Add this line for debugging
      setUniversalLists(formattedLists);
    } catch (error) {
      console.error("Error fetching universal lists:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Create theme based on isDarkMode
  const theme = createTheme({
    palette: {
      mode: isDarkMode ? "dark" : "light",
    },
  });

  return (
    <React.Fragment>
      {isLoading && <LoaderView />}
      <div className={`${getThemeClasses()}`}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle="Universal Lists"
            previousPageLink={`/property/${propertyId}`}
            previousPageTitle="Property"
          />

          <div className="row">
            <div className="col-lg-12">
              <div className="card" id="universalListsCard">
                <div className="card-header border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">
                      Universal Lists
                    </h5>
                    <div className="d-flex flex-wrap gap-2">
                      <Link
                        to={`/createUniversalList/${propertyId}`}
                        className="btn btn-info add-btn"
                      >
                        <i className="ri-add-line align-bottom me-1"></i> Create
                        Universal List
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <div className="table-responsive table-card mb-4 px-3">
                    {universalLists.length === 0 ? (
                      <NoRecordsFound />
                    ) : (
                      <ThemeProvider theme={theme}>
                        <Box sx={{ height: 650, width: "100%" }}>
                          <DataGrid
                            rows={universalLists}
                            columns={columns}
                            checkboxSelection={false}
                            initialState={{
                              pagination: {
                                paginationModel: {
                                  pageSize: 10,
                                },
                              },
                            }}
                            pageSizeOptions={[5]}
                            disableRowSelectionOnClick
                            onRowClick={(params) => {
                              navigate(
                                `/universalListDetail/${params.row.universal_list_id}`
                              );
                            }}
                            slots={{
                              toolbar: GridToolbar,
                              noResultsOverlay: NoRecordsFound,
                            }}
                            slotProps={{
                              toolbar: {
                                showQuickFilter: true,
                              },
                            }}
                          />
                        </Box>
                      </ThemeProvider>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UniversalList;
