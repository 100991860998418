import http from "../general/httpService";
import config from "../../constants/config.json";
import {
  PaymentReceipt,
  PaymentReceiptFilter,
} from "../../types/payment/payment-receipt";
import { devLog } from "../utils/logger";

export async function createPaymentReceipt(
  receipt: Partial<PaymentReceipt>
): Promise<PaymentReceipt> {
  try {
    const fullApiEndpoint = config.apiEndpoint + `/payment_receipts/create`;
    const { data: result } = await http.post(fullApiEndpoint, receipt);
    return result;
  } catch (error) {
    devLog("Error creating payment receipt:", error);
    throw error;
  }
}

export async function createReceiptFromVerification(
  paymentVerificationId: string
): Promise<PaymentReceipt> {
  try {
    const fullApiEndpoint =
      config.apiEndpoint +
      `/payment_receipts/create-from-verification/${paymentVerificationId}`;
    const { data: result } = await http.post(fullApiEndpoint);
    return result;
  } catch (error) {
    devLog("Error creating receipt from verification:", error);
    throw error;
  }
}

export async function getPaymentReceipt(
  receiptId: string
): Promise<PaymentReceipt> {
  try {
    const fullApiEndpoint =
      config.apiEndpoint + `/payment_receipts/${receiptId}`;
    const { data: result } = await http.get(fullApiEndpoint);
    return result;
  } catch (error) {
    devLog("Error fetching payment receipt:", error);
    throw error;
  }
}
//mainly will search by property unit id
export async function searchPaymentReceipts(
  params: PaymentReceiptFilter
): Promise<PaymentReceipt[]> {
  try {
    const fullApiEndpoint = config.apiEndpoint + `/payment_receipts/search`;
    const { data: result } = await http.post(fullApiEndpoint, params);
    return result;
  } catch (error) {
    devLog("Error searching payment receipts:", error);
    throw error;
  }
}
