import http from "../general/httpService";
import config from "../../constants/config.json";
import { User } from "./model/user";

//declaring the constants
const tokenKey = config.access_token;
const refreshToken = config.refresh_token;
const email = config.email;
const userKey = config.user_id;
const firstName = config.first_name;
const lastName = config.last_name;
const loginCode = config.login_code;
const profileImg = config.profile_img;
const stripeCustomerId = config.stripe_customer_id;
const isPaid = config.is_paid;
const stripeConnectAccountId = config.stripe_connect_account_id;
http.setJwt(getJwt());

// methods
//The user will use a similar auth but different table so i can set other things

//need to change out the methods
export async function sendLoginRequest(accountPayLoad) {
  try {
    // console.log("Sending login request");
    //console.log(`Email: ${accountPayLoad.email_address} `);

    const fullApiEndpoint =
      config.apiEndpoint + "/authentication/send_login_code";
    const { data: message } = await http.post(fullApiEndpoint, accountPayLoad);

    //console.log("API Response");
    // console.log(message);

    return message;
  } catch (ex) {
    //console.log(ex);
  }
}

//need a LOGIN
export async function loginUser(accountPayLoad) {
  try {
    // console.log("Logging in");
    //console.log(`Email: ${accountPayLoad.email_address} `);

    const fullApiEndpoint =
      config.apiEndpoint + "/authentication/pwdless_login";
    const { data } = await http.post(fullApiEndpoint, accountPayLoad);

    // console.log("API Response");
    //console.log(data);
    //set if no error
    // Store tokens and other information in the local storage
    localStorage.setItem(config.access_token, data.access_token);
    localStorage.setItem(config.refresh_token, data.refresh_token);
    localStorage.setItem(config.user_id, data.user_id);
    localStorage.setItem(config.is_paid, false);
    //need to set stripe_customer_id
    if (data.stripe_customer_id) {
      localStorage.setItem(config.stripe_customer_id, data.stripe_customer_id);
    }

    if (data.stripe_account_id) {
      localStorage.setItem(
        config.stripe_connect_account_id,
        data.stripe_account_id
      );
    }

    //localStorage.setItem(config.first_name, data.first_name);
    //localStorage.setItem(config.last_name, data.last_name);
    localStorage.setItem(config.email, accountPayLoad.email_address);
    localStorage.setItem(config.login_code, accountPayLoad.login_code);

    // Return the server message and the is_error property
    return { message: data.server_message, isError: data.is_error };
  } catch (ex) {
    console.error(ex);
    // If there's an error, return it to the user
    return { message: ex.message, isError: true };
  }
}

///authentication/pwdless_register
export async function registerUser(accountPayLoad) {
  try {
    //console.log("Registering user");
    //console.log(`Email: ${accountPayLoad.email_address} `);

    const fullApiEndpoint =
      config.apiEndpoint + "/authentication/pwdless_register";
    const { data } = await http.post(fullApiEndpoint, accountPayLoad);

    //console.log("API Response");
    //console.log(data);
    //set if no error

    // Return the server message and the is_error property
    return { message: data.server_message, isError: data.is_error };
  } catch (ex) {
    console.error(ex);
    // If there's an error, return it to the user
    return { message: ex.message, isError: true };
  }
}

//TODO: need to change these methods to the new ones

export async function getCurrentUserProfileByEmail() {
  const token = localStorage.getItem(tokenKey);
  const email = localStorage.getItem(config.email);

  const fullApiEndpoint =
    config.apiEndpoint + `/authentication/fetch_user_by_email`;

  const { data: response } = await http.post(fullApiEndpoint, {
    email_address: email,
  });

  // Remove sensitive fields
  delete response.expires_at;
  delete response.password;
  delete response.security_question;
  delete response.security_answer;
  delete response.login_code;
  delete response._id;

  // Map the response to match our form fields
  const userData = {
    userId: response.user_pal_id,
    firstName: response.first_name,
    lastName: response.last_name,
    emailAddress: response.email_address,
    profileImg: response.profile_img,
    stripeCustomerId: response.stripe_customer_id,
    stripeConnectAccountId: response.stripe_account_id,
    // Form fields
    phone_number: response.phone_number || "",
    address_line1: response.address_line1 || "",
    address_line2: response.address_line2 || "",
    city: response.city || "",
    state: response.state || "",
    postal_code: response.postal_code || "",
    country: response.country || "US",
    business_name: response.business_name || "",
    business_tax_id: response.business_tax_id || "",
    business_website: response.business_website || "",
    dob_day: response.dob_day || "",
    dob_month: response.dob_month || "",
    dob_year: response.dob_year || "",
    ssn_last_4: response.ssn_last_4 || "",
    bank_account_holder_name: response.bank_account_holder_name || "",
    bank_account_holder_type: response.bank_account_holder_type || "",
    bank_routing_number: response.bank_routing_number || "",
    bank_account_number: response.bank_account_number || "",
  };

  return userData;
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export async function logout() {
  const fullApiEndpoint = config.apiEndpoint + "/authentication/logout";

  const { data: response } = await http.post(
    fullApiEndpoint,
    new User(
      localStorage.getItem(config.user_id),
      localStorage.getItem(config.first_name),
      localStorage.getItem(config.last_name),
      localStorage.getItem(config.email),
      null,
      null,
      null,
      null,
      localStorage.getItem(config.login_code)
    )
  );

  //console.log(response);

  if (response.is_error === false) {
    //console.log("User logged out successfully");
    // clear the local storage
    localStorage.removeItem(config.access_token);
    localStorage.removeItem(config.refresh_token);
    localStorage.removeItem(config.email);
    localStorage.removeItem(config.user_id);
    localStorage.removeItem(config.first_name);
    localStorage.removeItem(config.last_name);
    localStorage.removeItem(config.login_code);
    localStorage.removeItem(config.profile_img);
    localStorage.removeItem(config.stripe_customer_id);
    localStorage.removeItem(config.is_paid);
    localStorage.removeItem(config.stripe_connect_account_id);
    // return true to indicate successful logout
    return true;
  } else {
    //still clear
    //look into why there is  undefined token left over
    localStorage.removeItem(config.access_token);
    localStorage.removeItem(config.refresh_token);
    localStorage.removeItem(config.email);
    localStorage.removeItem(config.user_id);
    localStorage.removeItem(config.first_name);
    localStorage.removeItem(config.last_name);
    localStorage.removeItem(config.login_code);
    localStorage.removeItem(config.profile_img);
    localStorage.removeItem(config.stripe_customer_id);
    localStorage.removeItem(config.is_paid);
    localStorage.removeItem(config.stripe_connect_account_id);
    // return false to indicate unsuccessful logout
    return true;
  }
}

export function getCurrentUser() {
  try {
    //get current user
    const jwt = localStorage.getItem(tokenKey);
    //alert(jwt);
    //if its an object
    //return jwtDecode(jwt);
    return jwt;
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

///authentication/find_email_by_login_code
export async function findEmailByLoginCode(loginCode) {
  try {
    const fullApiEndpoint =
      config.apiEndpoint + "/authentication/find_email_by_login_code";
    const { data } = await http.post(fullApiEndpoint, {
      login_code: loginCode,
    });

    return { message: data.server_message, isError: data.is_error };
  } catch (ex) {
    console.info(ex);
    // If there's an error, return it to the user
    return { message: ex.message, isError: true };
  }
}

//authentication/update_landlord_property_manager_profile
export async function updateLandlordPropertyManagerProfile(profile) {
  const fullApiEndpoint =
    config.apiEndpoint +
    "/authentication/update_landlord_property_manager_profile";
  const { data } = await http.post(fullApiEndpoint, profile);

  return { message: data.server_message, isError: data.is_error };
}
