import React from "react";
import { Upload, X, Trash } from "lucide-react";

const SignatureUpload = ({ signature, onUpload, onClear, onSave }) => {
  return (
    <div>
      <div className="tw-border-2 tw-border-dashed tw-border-gray-300 tw-dark:tw-border-gray-600 tw-rounded-lg tw-p-4 tw-mb-3">
        {signature ? (
          <div className="tw-relative">
            <img
              src={signature}
              alt="Signature preview"
              className="tw-max-h-32 tw-mx-auto"
            />
            <button
              onClick={onClear}
              className="tw-absolute tw-top-0 tw-right-0 tw-p-1 tw-bg-red-100 tw-rounded-full hover:tw-bg-red-200"
            >
              <X className="tw-w-4 tw-h-4 tw-text-red-600" />
            </button>
          </div>
        ) : (
          <label className="tw-flex tw-flex-col tw-items-center tw-cursor-pointer">
            <Upload className="tw-w-8 tw-h-8 tw-text-gray-400" />
            <span className="tw-mt-2 tw-text-sm tw-text-gray-500">
              Click to upload or drag and drop
            </span>
            <input
              type="file"
              className="tw-hidden"
              accept="image/*"
              onChange={onUpload}
            />
          </label>
        )}
      </div>

      {signature && (
        <div className="tw-flex tw-justify-end tw-gap-2">
          <button
            onClick={onClear}
            className="tw-flex tw-items-center tw-text-red-600 hover:tw-text-red-700"
          >
            <Trash className="tw-mr-1" size={16} />
            Clear
          </button>
          <button
            onClick={onSave}
            className="tw-flex tw-items-center tw-px-3 tw-py-1 tw-bg-green-500 tw-text-white tw-rounded hover:tw-bg-green-600"
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default SignatureUpload;
