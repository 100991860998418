import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  PaymentVerification,
  FeeConfirmationRequest,
} from "../../types/payment/payment-verification";
import { FeeVerificationItem } from "./FeeVerificationItem";
import { formatCurrency } from "../../services/utils/currencyUtils";
import { useThemeClasses } from "../../services/utils/hooks/useThemeClasses";
import config from "../../constants/config.json";
import { cn } from "../../lib/utils";
import {
  getVerification,
  confirmFee,
  processTenantAcceptance,
} from "../../services/payment/paymentVerificationService";
import { devLog } from "services/utils/logger";
import { getRentalContractById } from "../../services/rent/tenant/invite/tenantRentalContractService";
import PageTitleBox from "components/reusable/title/pageTitleBox";

export const PaymentVerificationView: React.FC<{ isDarkMode?: boolean }> = ({
  isDarkMode = false,
}) => {
  const { paymentVerificationId } = useParams<{
    paymentVerificationId: string;
  }>(); //this is really the payment_intent_id
  const [verification, setVerification] = useState<PaymentVerification | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const navigate = useNavigate();

  useEffect(() => {
    if (paymentVerificationId) {
      loadVerification();
    }
  }, [paymentVerificationId]);

  const loadVerification = async () => {
    try {
      setIsLoading(true);
      const response = await getVerification(paymentVerificationId!);
      if (response) {
        devLog("Verification: ");
        devLog(response);
        setVerification(response);
      } else {
        toast.error("Failed to load payment verification");
      }
    } catch (error) {
      toast.error("An error occurred while loading payment verification");
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmFee = async (feeName: string, notes?: string) => {
    if (!verification) return;

    //loading animation
    setIsLoading(true);

    try {
      const confirmationData: FeeConfirmationRequest = {
        payment_verification_id: verification.payment_verification_id,
        fee_name: feeName,
        confirmed_by:
          localStorage.getItem(config.first_name) +
            " " +
            localStorage.getItem(config.last_name) || "",
        landlord_notes: notes,
      };

      const response = await confirmFee(confirmationData);

      if (response) {
        setVerification(response as any);
        toast.success(`Successfully confirmed ${feeName}`);

        if (response?.all_fees_confirmed) {
          try {
            const contractResponse = await getRentalContractById(
              response.rental_contract_id
            );
            if (!contractResponse) {
              toast.error("Failed to get rental contract details");
              return;
            }

            const acceptanceResponse = await processTenantAcceptance({
              campaign_id: contractResponse.campaign_id,
              accepted_tenant_id: contractResponse.tenant_id,
              rental_contract_id: contractResponse.rental_contract_id,
              payment_intent_id: paymentVerificationId ?? "",
            });

            if (acceptanceResponse.message) {
              //TODO: Should set task as is done using the payment_intent_id and rental_contract_id to find it
              toast.success(acceptanceResponse.message);
              navigate(`/propertyunit/${contractResponse.property_unit_id}`);
            } else {
              toast.error(
                acceptanceResponse.error ||
                  "Failed to process tenant acceptance"
              );
            }
          } catch (error) {
            toast.error("An error occurred while processing tenant acceptance");
          }
        }
      } else {
        toast.error(
          (response as any).server_message || "Failed to confirm fee"
        );
      }
    } catch (error) {
      toast.error("An error occurred while confirming the fee");
    } finally {
      //loading animation
      setIsLoading(false);
    }
  };

  const handleConfirmAll = async () => {
    if (!verification) return;

    //loading animation
    setIsLoading(true);

    const unconfirmedFees = verification.fee_confirmations.filter(
      (fee) => !fee.is_confirmed_by_landlord
    );

    for (const fee of unconfirmedFees) {
      await handleConfirmFee(fee.fee_name, "Bulk confirmation");
    }

    //loading animation
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <div className="tw-flex tw-justify-center tw-items-center tw-h-64">
        <div className="tw-animate-spin tw-rounded-full tw-h-12 tw-w-12 tw-border-b-2 tw-border-primary"></div>
      </div>
    );
  }

  if (!verification) {
    return (
      <div className="tw-text-center tw-py-12">
        <p
          className={cn(
            "tw-text-lg",
            isDarkMode ? "tw-text-gray-400" : "tw-text-gray-600"
          )}
        >
          No payment verification found
        </p>
      </div>
    );
  }

  const unconfirmedFeesCount = verification.fee_confirmations.filter(
    (fee) => !fee.is_confirmed_by_landlord
  ).length;

  return (
    <div className={getThemeClasses()}>
      <PageTitleBox
        pageTitle="Payment Verification"
        previousPageTitle="Dashboard"
        previousPageLink="/dashboard"
      />
      <div className="tw-max-w-4xl tw-mx-auto tw-py-8 tw-px-4">
        <div
          className={cn(
            "tw-bg-opacity-75 tw-rounded-lg tw-p-6 tw-mb-8",
            isDarkMode ? "tw-bg-gray-800" : "tw-bg-white",
            "tw-shadow-lg"
          )}
        >
          <div className="tw-flex tw-justify-between tw-items-start tw-mb-6">
            <div>
              <h1
                className={cn(
                  "tw-text-2xl tw-font-bold tw-mb-2",
                  isDarkMode ? "tw-text-white" : "tw-text-gray-900"
                )}
              >
                Payment Verification
              </h1>
              <p
                className={cn(
                  "tw-text-sm",
                  isDarkMode ? "tw-text-gray-400" : "tw-text-gray-600"
                )}
              >
                Payment Status:{" "}
                <span
                  className={cn(
                    verification.payment_status === "succeeded"
                      ? "tw-text-green-500"
                      : verification.payment_status === "pending"
                      ? "tw-text-yellow-500"
                      : "tw-text-red-500"
                  )}
                >
                  {verification.payment_status}
                </span>
              </p>
            </div>

            <div className="tw-text-right">
              <p
                className={cn(
                  "tw-text-lg tw-font-medium",
                  isDarkMode ? "tw-text-white" : "tw-text-gray-900"
                )}
              >
                Total Expected:{" "}
                {formatCurrency(
                  verification.total_amount_expected,
                  verification.currency
                )}
              </p>
              <p
                className={cn(
                  "tw-text-sm",
                  isDarkMode ? "tw-text-gray-400" : "tw-text-gray-600"
                )}
              >
                Total Received:{" "}
                {formatCurrency(
                  verification.total_amount_received,
                  verification.currency
                )}
              </p>
            </div>
          </div>

          {unconfirmedFeesCount > 0 && (
            <div className="tw-mb-6">
              <button
                onClick={handleConfirmAll}
                className={cn(
                  "tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-rounded-md tw-bg-primary hover:tw-bg-primary-dark tw-text-white"
                )}
              >
                Confirm All Payments ({unconfirmedFeesCount})
              </button>
            </div>
          )}

          <div className="tw-space-y-4">
            {verification.fee_confirmations.map((fee, index) => (
              <FeeVerificationItem
                key={`${fee.fee_name}-${index}`}
                fee={fee}
                onConfirm={handleConfirmFee}
                isDarkMode={isDarkMode}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
