import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import profileImg from "../../../../styletheme/images/user-icon/avatar.png";
import avatar1 from "../../../../styletheme/images/users/avatar-1.jpg";
import avatar3 from "../../../../styletheme/images/users/avatar-3.jpg";
import avatar4 from "../../../../styletheme/images/users/avatar-4.jpg";
import avatar5 from "../../../../styletheme/images/users/avatar-5.jpg";
import PageTitleBox from "../../../reusable/title/pageTitleBox";
//for the table
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import NoRecordsFound from "../../tickets/list/sub/noRecordsFound";
import {
  getContractorById,
  getSubContractorsByContractorId,
} from "../../../../services/contractor/contractorManagement";
import { getTicketsAssignedToContractor } from "../../../../services/tickets/unitTicketsManagement";
import { getAllExpensesForContractor } from "../../../../services/propertyUnits/revenueAndExpenses/expenses/propertyUnitExpenseManagement";
import { formatCurrency } from "../../../../services/utils/currencyUtils";

import { ThemeProvider, createTheme } from "@mui/material/styles";

//TODO: Edit contractor then assign contractor then do the tenant
const ContractorProfileDetail = ({ isDarkMode }) => {
  const navigate = useNavigate();
  const pageTitle = "Contractor Profile";
  const { contractorId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [contractor, setContractor] = useState({
    contractor_id: "",
    portfolio_id: "",
    property_id: "",
    first_name: "",
    last_name: "",
    email_address: "",
    phone_number: "",
    company_name: "",
    company_address: "",
    company_address_2: "",
    company_city: "",
    company_state: "",
    company_zip: "",
    company_country: "",
    company_logo: "",
    company_website: "",
    company_phone: "",
    company_email: "",
    company_description: "",
    company_license: "",
    company_insurance: "",
    company_insurance_expiration: new Date(),
    company_first_licensure_date: new Date(),
    company_license_expiration: new Date(),
  });

  //tickets the contractor is assigned to
  /* const [tickets, setTickets] = useState([
    {
      ticket_id: '',
      property_unit_id: '',
      ticket_type: '',
      ticket_title: '',
      ticket_description: '',
      ticket_priority_id: '',
      ticket_status_id: '',
      ticket_number: '',
      ticket_status: '',
      priority_info: {
        created_at: '',
        is_active: true,
        priority: '',
        priority_id: '',
        updated_at: '',
      },
    },
  ]);
*/
  //Test data
  const [tickets, setTickets] = useState([
    {
      ticket_id: "",
      property_unit_id: "",
      ticket_type: "",
      ticket_title: "",
      ticket_description: "",
      ticket_priority_id: "",
      ticket_status_id: "",
      ticket_number: "",
      ticket_status: "",
      priority_info: {
        created_at: "",
        is_active: true,
        priority: "",
        priority_id: "",
        updated_at: "",
      },
    },
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);

    getContractorDetail();

    getSubContractors();

    getTickets();

    fetchAllExpensesPaidToContractor();
  }, []);

  const getContractorDetail = async () => {
    const result = await getContractorById(contractorId);
    console.log("Contractor Detail:", result);
    setContractor(result);
  };

  //TODO: get the tickets assigned to the contractor
  //need to add the contractor's payments
  const [paymentsMadeToContractor, setPaymentsMadeToContractor] = useState([
    {
      unit_expense_id: "",
      property_unit_id: "",
      expense_category_id: "",
      expense_amount: 0,
      expense_date: new Date(),
      is_active: true,
      is_paid_by_tenant: false,
      is_paid_by_landlord: false,
      payment_date: new Date(),
      is_capital_expense: false,
      expense_title: "",
      expense_description: "",
      receipt_link: "",
      expense_day: 0,
      expense_month: 0,
      expense_year: 0,
      created_at: new Date(),
      updated_at: new Date(),
      property_bank_account_id: "",
      bank_account_id: "",
      bank_id: "",
      bank_name: "",
      //optional
      contractor_id: "",
      contractor_name: "",
    },
  ]);
  //table columns for paymentsMadeToContractor
  //TODO: set this from the expenses and revenue form, type of expense contractor then select the contractor
  const paymentsColumns = [
    {
      field: "expense_date",
      headerName: "Date",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name">
            {new Date(params.row.expense_date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </div>
        </div>
      ),
    },
    { field: "expense_title", headerName: "Title", width: 200 },
    {
      field: "expense_amount",
      headerName: "Amount",
      type: "number",
      width: 110,
      editable: true,
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name">
            {/* Format the amount, two decimal places etc*/}

            {formatCurrency(params.row.expense_amount)}
          </div>
        </div>
      ),
    },

    { field: "bank_name", headerName: "Account", width: 200 },

    { field: "expense_description", headerName: "Description", width: 400 },
  ];

  //get all sub-contractors
  const [subContractors, setSubContractors] = useState([
    {
      sub_contractor_id: "",
      contractor_id: "",
      first_name: "",
      last_name: "",
      email_address: "",
      phone_number: "",
      company_name: "",
      company_license: "",
      company_insurance: "",
      is_active: true,
      created_at: new Date(),
      updated_at: new Date(),
    },
  ]);

  //get all the sub-contractors by contractor_id
  const getSubContractors = async () => {
    const result = await getSubContractorsByContractorId(contractorId);
    console.log("Sub-Contractors:", result);
    setSubContractors(result);
  };

  //get all the tickets assigned to the contractor
  const getTickets = async () => {
    const tickets = await getTicketsAssignedToContractor(contractorId);
    console.log("Tickets:", tickets);
    setTickets(tickets);

    setIsLoading(false);
  };

  const fetchAllExpensesPaidToContractor = async () => {
    const expenses = await getAllExpensesForContractor(contractorId);
    console.log("Expenses Paid to Contractor:", expenses);
    setPaymentsMadeToContractor(expenses);
  };

  // Create theme based on isDarkMode
  const theme = createTheme({
    palette: {
      mode: isDarkMode ? "dark" : "light",
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageTitle="Contractors"
            previousPageLink={`/contractors`}
          />

          <div className="row  p-1">
            <div className="col-lg-12">
              <div>
                {/* Tab panes */}
                <div className="tab-content pt-4 text-muted">
                  <div
                    className="tab-pane active"
                    id="overview-tab"
                    role="tabpanel"
                  >
                    <div className="row">
                      <div className="col-xxl-3">
                        <div className="card">
                          <div className="card-body">
                            <div className="pt-4 mb-4 mb-lg-2 pb-lg-4 profile-wrapper p-3">
                              <div className="row g-4">
                                <div className="col-auto">
                                  <div className="avatar-lg">
                                    <img
                                      src={profileImg}
                                      alt="user-img"
                                      className="img-thumbnail rounded-circle"
                                    />
                                  </div>
                                </div>
                                {/*end col*/}
                                <div className="col">
                                  <div className="p-2">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <h3 className="text-primary mb-1">
                                        {contractor.first_name}{" "}
                                        {contractor.last_name}
                                      </h3>
                                      <Link
                                        to={`/updateContractor/${contractorId}`}
                                        className="btn btn-outline-success btn-sm"
                                      >
                                        <i className="ri-edit-box-line align-bottom"></i>{" "}
                                        Edit Profile
                                      </Link>
                                    </div>
                                    <p className="text-primary text-opacity-75">
                                      {contractor.company_name}
                                    </p>
                                    <div className="hstack gap-1 flex-wrap">
                                      <div className="me-2">
                                        <i className="ri-map-pin-user-line me-1 text-primary text-opacity-75 fs-16 align-middle"></i>
                                        {contractor.company_state},{" "}
                                        {contractor.company_country}
                                      </div>
                                      <div className="me-2">
                                        <i className="mdi mdi-web me-1 text-primary text-opacity-75 fs-16 align-middle"></i>
                                        <a
                                          href={
                                            contractor.company_website.startsWith(
                                              "https"
                                            )
                                              ? contractor.company_website
                                              : `https://${contractor.company_website}`
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="fw-semibold"
                                        >
                                          {contractor.company_website}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/*end col*/}

                                {/*end col*/}
                              </div>
                              {/*end row*/}
                            </div>
                          </div>
                        </div>

                        <div className="card">
                          <div className="card-body">
                            <h5 className="card-title mb-3">Info</h5>
                            <div className="table-responsive">
                              <table className="table table-borderless mb-0">
                                <tbody>
                                  <tr>
                                    <th className="ps-0" scope="row">
                                      Full Name :
                                    </th>
                                    <td className="text-muted">
                                      {contractor.first_name}{" "}
                                      {contractor.last_name}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="ps-0" scope="row">
                                      Mobile :
                                    </th>
                                    <td className="text-muted">
                                      {contractor.phone_number}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="ps-0" scope="row">
                                      E-mail :
                                    </th>
                                    <td className="text-muted">
                                      {contractor.email_address}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="ps-0" scope="row">
                                      Location :
                                    </th>
                                    <td className="text-muted">
                                      {contractor.company_address}{" "}
                                      {contractor.company_address_2 ? (
                                        <span>
                                          {contractor.company_address_2}{" "}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {contractor.company_city},{" "}
                                      {contractor.company_state},{" "}
                                      {contractor.company_country}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="ps-0" scope="row">
                                      Company Name :
                                    </th>
                                    <td className="text-muted">
                                      {contractor.company_name}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="ps-0" scope="row">
                                      Company Phone :
                                    </th>
                                    <td className="text-muted">
                                      {contractor.company_phone}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="ps-0" scope="row">
                                      Company Email :
                                    </th>
                                    <td className="text-muted">
                                      {contractor.company_email}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          {/* end card body */}
                        </div>
                        {/* end card */}

                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex align-items-center mb-4">
                              <div className="flex-grow-1">
                                <h5 className="card-title mb-0">
                                  Sub-Contractors
                                </h5>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <Link
                                  to={`/createSubContractor/${contractorId}`}
                                  className="btn btn-sm btn-outline-primary material-shadow-none"
                                >
                                  <i className="mdi mdi-account-plus align-middle"></i>
                                </Link>
                              </div>
                              <div className="flex-shrink-0" hidden>
                                <div className="dropdown">
                                  <a
                                    href="#"
                                    role="button"
                                    id="dropdownMenuLink2"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="ri-more-2-fill fs-14"></i>
                                  </a>

                                  <ul
                                    className="dropdown-menu dropdown-menu-end"
                                    aria-labelledby="dropdownMenuLink2"
                                  >
                                    <li>
                                      <a className="dropdown-item" href="#">
                                        View
                                      </a>
                                    </li>
                                    <li>
                                      <a className="dropdown-item" href="#">
                                        Edit
                                      </a>
                                    </li>
                                    <li>
                                      <a className="dropdown-item" href="#">
                                        Delete
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{ maxHeight: "300px", overflowY: "auto" }}
                            >
                              {subContractors.map((subContractor, index) => (
                                <div className="card" key={index}>
                                  <div className="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-p-4 tw-gap-4">
                                    {/* Avatar and Primary Info */}
                                    <div className="tw-flex tw-items-center tw-w-full">
                                      <div className="tw-flex-shrink-0">
                                        <img
                                          src={profileImg}
                                          className="tw-w-12 tw-h-12 tw-rounded-full tw-shadow-sm tw-object-cover"
                                          alt={`${subContractor.first_name} ${subContractor.last_name}`}
                                        />
                                      </div>
                                      <div className="tw-ml-4 tw-flex-grow">
                                        <h5 className="tw-text-lg tw-font-medium tw-mb-0.5">
                                          {subContractor.first_name} {subContractor.last_name}
                                        </h5>
                                        <p className="tw-text-sm tw-text-gray-600 tw-mb-0">
                                          {subContractor.company_name || 'N/A'}
                                        </p>
                                      </div>
                                      <div className="tw-flex tw-gap-2">
                                        <Link
                                          to={`mailto:${subContractor.email_address}`}
                                          className="tw-inline-flex tw-items-center tw-px-2.5 tw-py-1.5 tw-text-sm tw-bg-gray-50 tw-text-gray-700 tw-rounded hover:tw-bg-gray-100 tw-transition-colors"
                                        >
                                          <i className="ri-mail-line tw-mr-1"></i>
                                          Email
                                        </Link>
                                        <Link
                                          to={`tel:${subContractor.phone_number}`}
                                          className="tw-inline-flex tw-items-center tw-px-2.5 tw-py-1.5 tw-text-sm tw-bg-gray-50 tw-text-gray-700 tw-rounded hover:tw-bg-gray-100 tw-transition-colors"
                                        >
                                          <i className="ri-phone-line tw-mr-1"></i>
                                          Call
                                        </Link>
                                        <Link
                                          to={`/updateSubContractor/${subContractor.sub_contractor_id}`}
                                          className="tw-inline-flex tw-items-center tw-px-3 tw-py-1.5 tw-text-sm tw-border tw-border-primary tw-text-primary tw-rounded hover:tw-bg-primary hover:tw-text-white tw-transition-colors"
                                        >
                                          <i className="ri-edit-box-line tw-mr-1"></i>
                                          Edit
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          {/* end card body */}
                        </div>
                        {/*end card*/}
                      </div>
                      {/*end col*/}
                      <div className="col-xxl-9">
                        <div className="row">
                          <div className="col-xl-12">
                            <div className="card crm-widget">
                              <div className="card-body p-0">
                                <div className="row row-cols-xxl-5 row-cols-md-3 row-cols-1 g-0">
                                  <div className="col">
                                    <div className="py-4 px-3">
                                      <h5 className="text-muted text-uppercase fs-13">
                                        Open Tickets{" "}
                                        <i className="ri-arrow-up-circle-line text-danger fs-18 float-end align-middle"></i>
                                      </h5>
                                      <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">
                                          <i className=" ri-coupon-line display-6 text-muted cfs-22"></i>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                          <h2 className="mb-0 cfs-22">
                                            <span
                                              className="counter-value"
                                              data-target="197"
                                            >
                                              {tickets.length}
                                            </span>
                                          </h2>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col" hidden>
                                    <div className="mt-3 mt-md-0 py-4 px-3">
                                      <h5 className="text-muted text-uppercase fs-13">
                                        Projects Completed{" "}
                                        <i className="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i>
                                      </h5>
                                      <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">
                                          <i className=" ri-list-check-2 display-6 text-muted cfs-22"></i>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                          <h2 className="mb-0 cfs-22">
                                            <span
                                              className="counter-value"
                                              data-target="489.4"
                                            >
                                              10
                                            </span>
                                          </h2>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col" hidden>
                                    <div className="mt-3 mt-md-0 py-4 px-3">
                                      <h5 className="text-muted text-uppercase fs-13">
                                        Average Cost per Job{" "}
                                        <i className="ri-arrow-down-circle-line text-success fs-18 float-end align-middle"></i>
                                      </h5>
                                      <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">
                                          <i className="ri-price-tag-2-line display-6 text-muted cfs-22"></i>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                          <h2 className="mb-0 cfs-22">
                                            <span
                                              className="counter-value"
                                              data-target="32.89"
                                            >
                                              $
                                            </span>
                                            1,550.51
                                          </h2>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="mt-3 mt-lg-0 py-4 px-3">
                                      <h5 className="text-muted text-uppercase fs-13">
                                        Crew Size{" "}
                                        <i className="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i>
                                      </h5>
                                      <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">
                                          <i className="mdi mdi-account-group-outline display-6 text-muted cfs-22"></i>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                          <h2 className="mb-0 cfs-22">
                                            <span
                                              className="counter-value"
                                              data-target="1596.5"
                                            >
                                              3
                                            </span>
                                          </h2>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col" hidden>
                                    <div className="mt-3 mt-lg-0 py-4 px-3">
                                      <h5 className="text-muted text-uppercase fs-13">
                                        {/*The tenant gets a survery to see the quality of their interaction and the work done on the unit */}
                                        Overall Satisfaction{" "}
                                        <i className="ri-arrow-down-circle-line text-danger fs-18 float-end align-middle"></i>
                                      </h5>
                                      <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">
                                          <i className="ri-star-half-line display-6 text-muted cfs-22"></i>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                          <h2 className="mb-0 cfs-22">
                                            <span
                                              className="counter-value"
                                              data-target="2659"
                                            >
                                              4.5/5
                                            </span>
                                          </h2>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-12">
                            <div className="">
                              <div className="card">
                                <div className="card-body">
                                  <h5 className="card-title mb-3">About</h5>
                                  <p>{contractor.company_description}</p>

                                  <div className="row">
                                    {/*Contractor License */}
                                    <div className="col-4 col-md-4">
                                      <div className="d-flex mt-4">
                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                          <div className="avatar-title bg-light rounded-circle fs-16 text-primary material-shadow">
                                            <i className="mdi mdi-license"></i>
                                          </div>
                                        </div>
                                        <div className="flex-grow-1 overflow-hidden">
                                          <p className="mb-1">License :</p>
                                          <h6 className="text-truncate mb-0">
                                            {contractor.company_license}
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                    {/*end col*/}
                                    {/*Contractor Insurance */}
                                    <div className="col-4 col-md-4">
                                      <div className="d-flex mt-4">
                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                          <div className="avatar-title bg-light rounded-circle fs-16 text-primary material-shadow">
                                            <i className="mdi mdi-shield-account"></i>
                                          </div>
                                        </div>
                                        <div className="flex-grow-1 overflow-hidden">
                                          <p className="mb-1">Insurance :</p>
                                          <h6 className="text-truncate mb-0">
                                            {contractor.company_insurance}
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                    {/*end col*/}
                                    {/*Contractor Insurance Expiration */}
                                    <div className="col-4 col-md-4">
                                      <div className="d-flex mt-4">
                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                          <div className="avatar-title bg-light rounded-circle fs-16 text-primary material-shadow">
                                            <i className="mdi mdi-calendar"></i>
                                          </div>
                                        </div>
                                        <div className="flex-grow-1 overflow-hidden">
                                          <p className="mb-1">
                                            Insurance Expiration :
                                          </p>
                                          <h6 className="text-truncate mb-0">
                                            {new Date(
                                              contractor.company_insurance_expiration
                                            ).toLocaleString("en-US", {
                                              year: "numeric",
                                              month: "long",
                                              day: "numeric",
                                            })}
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                    {/*end col*/}
                                    {/*Contractor Rate */}
                                    <div className="col-4 col-md-4">
                                      <div className="d-flex mt-4">
                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                          <div className="avatar-title bg-light rounded-circle fs-16 text-primary material-shadow">
                                            <i className="mdi mdi-currency-usd"></i>
                                          </div>
                                        </div>
                                        <div className="flex-grow-1 overflow-hidden">
                                          <p className="mb-1">Rate</p>
                                          <h6 className="text-truncate mb-0">
                                            {"$ "}
                                            {contractor.payment_rate}
                                            {", "}
                                            {contractor.payment_rate_frequency}
                                          </h6>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-4 col-md-4">
                                      <div className="d-flex mt-4">
                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                          <div className="avatar-title bg-light rounded-circle fs-16 text-primary material-shadow">
                                            <i className="las la-money-check"></i>
                                          </div>
                                        </div>
                                        <div className="flex-grow-1 overflow-hidden">
                                          <p className="mb-1">
                                            Payment Method :
                                          </p>
                                          <h6 className="text-truncate mb-0">
                                            {contractor.payment_rate_method}
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                    {/*end col*/}
                                    <div className="col-4 col-md-4">
                                      <div className="d-flex mt-4">
                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                          <div className="avatar-title bg-light rounded-circle fs-16 text-primary material-shadow">
                                            <i className="ri-global-line"></i>
                                          </div>
                                        </div>
                                        <div className="flex-grow-1 overflow-hidden">
                                          <p className="mb-1">Website :</p>
                                          <a
                                            href={
                                              contractor.company_website.startsWith(
                                                "https"
                                              )
                                                ? contractor.company_website
                                                : `https://${contractor.company_website}`
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="fw-semibold"
                                          >
                                            {contractor.company_website}
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    {/*end col*/}
                                  </div>
                                  {/*end row*/}
                                </div>
                                {/*end card-body*/}
                              </div>
                              {/* end card */}

                              <div className="card">
                                <div className="card-header align-items-center d-flex">
                                  <h4 className="card-title mb-0  me-2">
                                    Tickets Assigned
                                  </h4>
                                  <div className="flex-shrink-0 ms-auto" hidden>
                                    <ul
                                      className="nav justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                      role="tablist"
                                    >
                                      <li className="nav-item">
                                        <a
                                          className="nav-link active"
                                          data-bs-toggle="tab"
                                          href="#today"
                                          role="tab"
                                        >
                                          Today
                                        </a>
                                      </li>
                                      <li className="nav-item">
                                        <a
                                          className="nav-link"
                                          data-bs-toggle="tab"
                                          href="#weekly"
                                          role="tab"
                                        >
                                          Weekly
                                        </a>
                                      </li>
                                      <li className="nav-item">
                                        <a
                                          className="nav-link"
                                          data-bs-toggle="tab"
                                          href="#monthly"
                                          role="tab"
                                        >
                                          Monthly
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Need lottie animation to let user know what should be here*/}
                              <div className="card-body mb-5">
                                {/*up to 4 cards per row */}
                                <div className="tasks-wrapper px-3 mx-n3">
                                  <div className="row">
                                    {tickets.map((ticket, index) => (
                                      <div
                                        className="col-md-4 col-lg-3 mb-4" // Adjusted this line for grid layout
                                        key={index}
                                      >
                                        <div className="card tasks-box">
                                          <div className="card-body">
                                            <div className="d-flex mb-2">
                                              <div className="flex-grow-1">
                                                <h6 className="fs-15 mb-0 text-truncate task-title">
                                                  <Link
                                                    to={`/ticket/${ticket.ticket_id}`}
                                                    className="text-muted fw-medium fs-14 flex-grow-1"
                                                  >
                                                    #{ticket.ticket_number}
                                                  </Link>
                                                </h6>
                                              </div>
                                            </div>
                                            <h6 className="fs-15 text-truncate task-title">
                                              <Link
                                                to={`/ticket/${ticket.ticket_id}`}
                                                className="text-body d-block"
                                              >
                                                {ticket.ticket_title}
                                              </Link>
                                            </h6>
                                            <p className="text-muted">
                                              {ticket &&
                                                ticket.ticket_description.substring(
                                                  0,
                                                  100
                                                ) + "..."}
                                            </p>
                                            <div className="d-flex align-items-center">
                                              <div className="flex-grow-1">
                                                <span className="badge bg-primary-subtle text-primary me-2">
                                                  {ticket.ticket_status}
                                                </span>
                                                <span className="badge bg-primary-subtle text-primary me-2">
                                                  {ticket.ticket_type}
                                                </span>
                                                <span className="badge bg-primary-subtle text-primary">
                                                  {
                                                    ticket?.priority_info
                                                      ?.priority
                                                  }
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          {/*end card-body*/}
                                          <div className="card-footer border-top-dashed">
                                            <div className="d-flex">
                                              <div className="flex-grow-1">
                                                <span className="text-muted">
                                                  <i className="ri-time-line align-bottom"></i>{" "}
                                                  {new Date(
                                                    ticket.updated_at
                                                  ).toLocaleString("en-US", {
                                                    year: "numeric",
                                                    month: "long",
                                                    day: "numeric",
                                                  })}
                                                </span>
                                              </div>
                                              <div className="flex-shrink-0">
                                                <ul className="link-inline mb-0">
                                                  <li className="list-inline-item">
                                                    <Link
                                                      to={`/ticket/${ticket.ticket_id}`}
                                                      className="text-muted"
                                                    >
                                                      <i className="ri-eye-line align-bottom"></i>{" "}
                                                      0
                                                    </Link>
                                                  </li>
                                                  <li className="list-inline-item">
                                                    <Link
                                                      to={`/ticket/${ticket.ticket_id}`}
                                                      className="text-muted"
                                                    >
                                                      <i className="ri-question-answer-line align-bottom"></i>{" "}
                                                      0
                                                    </Link>
                                                  </li>
                                                  <li className="list-inline-item">
                                                    <Link
                                                      to={`/ticket/${ticket.ticket_id}`}
                                                      className="text-muted"
                                                    >
                                                      <i className="ri-attachment-2 align-bottom"></i>{" "}
                                                      0
                                                    </Link>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                              {/* end card body */}
                            </div>
                            {/* end card */}
                          </div>
                          {/* end col */}
                        </div>
                        {/* end row */}

                        {/*To navigate to the expense detail screen */}
                        <div className="card " style={{ marginTop: "2%" }}>
                          <div className="card-body">
                            <h5 className="card-title">Payments Made</h5>
                            {/* Swiper */}
                            <div className="pt-3">
                              <ThemeProvider theme={theme}>
                                <Box sx={{ height: 400, width: "100%" }}>
                                  <DataGrid
                                    rows={paymentsMadeToContractor || []}
                                    getRowId={(row) => row.unit_expense_id || 0}
                                    columns={paymentsColumns}
                                    checkboxSelection={false}
                                    initialState={{
                                      pagination: {
                                        paginationModel: {
                                          pageSize: 5,
                                        },
                                      },
                                    }}
                                    pageSizeOptions={[5]}
                                    disableRowSelectionOnClick
                                    processRowUpdate={(
                                      updatedRow,
                                      originalRow
                                    ) => {
                                      console.log(updatedRow, originalRow);
                                    }}
                                    onProcessRowUpdateError={(
                                      error,
                                      updatedRow,
                                      originalRow
                                    ) =>
                                      console.log(
                                        error,
                                        updatedRow,
                                        originalRow
                                      )
                                    }
                                    onRowClick={(params) => {
                                      // Navigate to the ticket detail page
                                      // Assuming the route is '/ticket-detail/:ticketId'
                                      //navigate(`/ticket/${params.row.ticket_id}`);
                                      //TODO: show the expense detail in the future
                                    }}
                                    slots={{
                                      toolbar: GridToolbar,
                                      noResultsOverlay: NoRecordsFound,
                                    }}
                                    slotProps={{
                                      toolbar: {
                                        showQuickFilter: true,
                                      },
                                    }}
                                  />
                                </Box>
                              </ThemeProvider>
                            </div>
                          </div>
                          {/* end card body */}
                        </div>
                        {/* end card */}
                      </div>
                      {/*end col*/}
                    </div>
                    {/*end row*/}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="activities"
                    role="tabpanel"
                  >
                    <div className="card">
                      <div className="card-body">
                        <h5 className="card-title mb-3">Activities</h5>
                        <div className="acitivity-timeline">
                          <div className="acitivity-item d-flex">
                            <div className="flex-shrink-0">
                              <img
                                src={profileImg}
                                alt=""
                                className="avatar-xs rounded-circle acitivity-avatar material-shadow"
                              />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1">
                                Oliver Phillips{" "}
                                <span className="badge bg-primary-subtle text-primary align-middle">
                                  New
                                </span>
                              </h6>
                              <p className="text-muted mb-2">
                                We talked about a project on linkedin.
                              </p>
                              <small className="mb-0 text-muted">Today</small>
                            </div>
                          </div>
                          <div className="acitivity-item py-3 d-flex">
                            <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                              <div className="avatar-title bg-success-subtle text-success rounded-circle material-shadow">
                                N
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1">
                                Nancy Martino{" "}
                                <span className="badge bg-secondary-subtle text-secondary align-middle">
                                  In Progress
                                </span>
                              </h6>
                              <p className="text-muted mb-2">
                                <i className="ri-file-text-line align-middle ms-2"></i>{" "}
                                Create new project Buildng product
                              </p>
                              <div className="avatar-group mb-2">
                                <a
                                  href="javascript: void(0);"
                                  className="avatar-group-item material-shadow"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title=""
                                  data-bs-original-title="Christi"
                                >
                                  <img
                                    src="assets/images/users/avatar-4.jpg"
                                    alt=""
                                    className="rounded-circle avatar-xs"
                                  />
                                </a>
                                <a
                                  href="javascript: void(0);"
                                  className="avatar-group-item material-shadow"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title=""
                                  data-bs-original-title="Frank Hook"
                                >
                                  <img
                                    src="assets/images/users/avatar-3.jpg"
                                    alt=""
                                    className="rounded-circle avatar-xs"
                                  />
                                </a>
                                <a
                                  href="javascript: void(0);"
                                  className="avatar-group-item material-shadow"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title=""
                                  data-bs-original-title=" Ruby"
                                >
                                  <div className="avatar-xs">
                                    <div className="avatar-title rounded-circle bg-light text-primary material-shadow">
                                      R
                                    </div>
                                  </div>
                                </a>
                                <a
                                  href="javascript: void(0);"
                                  className="avatar-group-item material-shadow"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title=""
                                  data-bs-original-title="more"
                                >
                                  <div className="avatar-xs">
                                    <div className="avatar-title rounded-circle">
                                      2+
                                    </div>
                                  </div>
                                </a>
                              </div>
                              <small className="mb-0 text-muted">
                                Yesterday
                              </small>
                            </div>
                          </div>
                          <div className="acitivity-item py-3 d-flex">
                            <div className="flex-shrink-0">
                              <img
                                src="assets/images/users/avatar-2.jpg"
                                alt=""
                                className="avatar-xs rounded-circle acitivity-avatar material-shadow"
                              />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1">
                                Natasha Carey{" "}
                                <span className="badge bg-success-subtle text-success align-middle">
                                  Completed
                                </span>
                              </h6>
                              <p className="text-muted mb-2">
                                Adding a new event with attachments
                              </p>
                              <div className="row">
                                <div className="col-xxl-4">
                                  <div className="row border border-dashed gx-2 p-2 mb-2">
                                    <div className="col-4">
                                      <img
                                        src="assets/images/small/img-2.jpg"
                                        alt=""
                                        className="img-fluid rounded material-shadow"
                                      />
                                    </div>
                                    {/*end col*/}
                                    <div className="col-4">
                                      <img
                                        src="assets/images/small/img-3.jpg"
                                        alt=""
                                        className="img-fluid rounded material-shadow"
                                      />
                                    </div>
                                    {/*end col*/}
                                    <div className="col-4">
                                      <img
                                        src="assets/images/small/img-4.jpg"
                                        alt=""
                                        className="img-fluid rounded material-shadow"
                                      />
                                    </div>
                                    {/*end col*/}
                                  </div>
                                  {/*end row*/}
                                </div>
                              </div>
                              <small className="mb-0 text-muted">25 Nov</small>
                            </div>
                          </div>
                          <div className="acitivity-item py-3 d-flex">
                            <div className="flex-shrink-0">
                              <img
                                src="assets/images/users/avatar-6.jpg"
                                alt=""
                                className="avatar-xs rounded-circle acitivity-avatar material-shadow"
                              />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1">Bethany Johnson</h6>
                              <p className="text-muted mb-2">
                                added a new member to velzon dashboard
                              </p>
                              <small className="mb-0 text-muted">19 Nov</small>
                            </div>
                          </div>
                          <div className="acitivity-item py-3 d-flex">
                            <div className="flex-shrink-0">
                              <div className="avatar-xs acitivity-avatar">
                                <div className="avatar-title rounded-circle bg-danger-subtle text-danger material-shadow">
                                  <i className="ri-shopping-bag-line"></i>
                                </div>
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1">
                                Your order is placed{" "}
                                <span className="badge bg-danger-subtle text-danger align-middle ms-1">
                                  Out of Delivery
                                </span>
                              </h6>
                              <p className="text-muted mb-2">
                                These customers can rest assured their order has
                                been placed.
                              </p>
                              <small className="mb-0 text-muted">16 Nov</small>
                            </div>
                          </div>
                          <div className="acitivity-item py-3 d-flex">
                            <div className="flex-shrink-0">
                              <img
                                src="assets/images/users/avatar-7.jpg"
                                alt=""
                                className="avatar-xs rounded-circle acitivity-avatar material-shadow"
                              />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1">Lewis Pratt</h6>
                              <p className="text-muted mb-2">
                                They all have something to say beyond the words
                                on the page. They can come across as casual or
                                neutral, exotic or graphic.{" "}
                              </p>
                              <small className="mb-0 text-muted">22 Oct</small>
                            </div>
                          </div>
                          <div className="acitivity-item py-3 d-flex">
                            <div className="flex-shrink-0">
                              <div className="avatar-xs acitivity-avatar">
                                <div className="avatar-title rounded-circle bg-info-subtle text-info material-shadow">
                                  <i className="ri-line-chart-line"></i>
                                </div>
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1">Monthly sales report</h6>
                              <p className="text-muted mb-2">
                                <span className="text-danger">2 days left</span>{" "}
                                notification to submit the monthly sales report.{" "}
                                <a
                                  href="javascript:void(0);"
                                  className="link-warning text-decoration-underline"
                                >
                                  Reports Builder
                                </a>
                              </p>
                              <small className="mb-0 text-muted">15 Oct</small>
                            </div>
                          </div>
                          <div className="acitivity-item d-flex">
                            <div className="flex-shrink-0">
                              <img
                                src="assets/images/users/avatar-8.jpg"
                                alt=""
                                className="avatar-xs rounded-circle acitivity-avatar material-shadow"
                              />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1">
                                New ticket received{" "}
                                <span className="badge bg-success-subtle text-success align-middle">
                                  Completed
                                </span>
                              </h6>
                              <p className="text-muted mb-2">
                                User{" "}
                                <span className="text-secondary">Erica245</span>{" "}
                                submitted a ticket.
                              </p>
                              <small className="mb-0 text-muted">26 Aug</small>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*end card-body*/}
                    </div>
                    {/*end card*/}
                  </div>
                  {/*end tab-pane*/}
                  <div className="tab-pane fade" id="projects" role="tabpanel">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-xxl-3 col-sm-6">
                            <div className="card profile-project-card shadow-none profile-project-warning material-shadow">
                              <div className="card-body p-4">
                                <div className="d-flex">
                                  <div className="flex-grow-1 text-muted overflow-hidden">
                                    <h5 className="fs-14 text-truncate">
                                      <a href="#" className="text-body">
                                        Chat App Update
                                      </a>
                                    </h5>
                                    <p className="text-muted text-truncate mb-0">
                                      Last Update :{" "}
                                      <span className="fw-semibold text-body">
                                        2 year Ago
                                      </span>
                                    </p>
                                  </div>
                                  <div className="flex-shrink-0 ms-2">
                                    <div className="badge bg-warning-subtle text-warning fs-10">
                                      Inprogress
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex mt-4">
                                  <div className="flex-grow-1">
                                    <div className="d-flex align-items-center gap-2">
                                      <div>
                                        <h5 className="fs-12 text-muted mb-0">
                                          Members :
                                        </h5>
                                      </div>
                                      <div className="avatar-group">
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <img
                                              src={profileImg}
                                              alt=""
                                              className="rounded-circle img-fluid"
                                            />
                                          </div>
                                        </div>
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <img
                                              src="assets/images/users/avatar-3.jpg"
                                              alt=""
                                              className="rounded-circle img-fluid"
                                            />
                                          </div>
                                        </div>
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <div className="avatar-title rounded-circle bg-light text-primary">
                                              J
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* end card body */}
                            </div>
                            {/* end card */}
                          </div>
                          {/*end col*/}
                          <div className="col-xxl-3 col-sm-6">
                            <div className="card profile-project-card shadow-none profile-project-success material-shadow">
                              <div className="card-body p-4">
                                <div className="d-flex">
                                  <div className="flex-grow-1 text-muted overflow-hidden">
                                    <h5 className="fs-14 text-truncate">
                                      <a href="#" className="text-body">
                                        ABC Project Customization
                                      </a>
                                    </h5>
                                    <p className="text-muted text-truncate mb-0">
                                      Last Update :{" "}
                                      <span className="fw-semibold text-body">
                                        2 month Ago
                                      </span>
                                    </p>
                                  </div>
                                  <div className="flex-shrink-0 ms-2">
                                    <div className="badge bg-primary-subtle text-primary fs-10">
                                      {" "}
                                      Progress
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex mt-4">
                                  <div className="flex-grow-1">
                                    <div className="d-flex align-items-center gap-2">
                                      <div>
                                        <h5 className="fs-12 text-muted mb-0">
                                          Members :
                                        </h5>
                                      </div>
                                      <div className="avatar-group">
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <img
                                              src="assets/images/users/avatar-8.jpg"
                                              alt=""
                                              className="rounded-circle img-fluid"
                                            />
                                          </div>
                                        </div>
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <img
                                              src="assets/images/users/avatar-7.jpg"
                                              alt=""
                                              className="rounded-circle img-fluid"
                                            />
                                          </div>
                                        </div>
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <img
                                              src="assets/images/users/avatar-6.jpg"
                                              alt=""
                                              className="rounded-circle img-fluid"
                                            />
                                          </div>
                                        </div>
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <div className="avatar-title rounded-circle bg-primary">
                                              2+
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* end card body */}
                            </div>
                            {/* end card */}
                          </div>
                          {/*end col*/}
                          <div className="col-xxl-3 col-sm-6">
                            <div className="card profile-project-card shadow-none profile-project-info material-shadow">
                              <div className="card-body p-4">
                                <div className="d-flex">
                                  <div className="flex-grow-1 text-muted overflow-hidden">
                                    <h5 className="fs-14 text-truncate">
                                      <a href="#" className="text-body">
                                        Client - Frank Hook
                                      </a>
                                    </h5>
                                    <p className="text-muted text-truncate mb-0">
                                      Last Update :{" "}
                                      <span className="fw-semibold text-body">
                                        1 hr Ago
                                      </span>
                                    </p>
                                  </div>
                                  <div className="flex-shrink-0 ms-2">
                                    <div className="badge bg-info-subtle text-info fs-10">
                                      New
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex mt-4">
                                  <div className="flex-grow-1">
                                    <div className="d-flex align-items-center gap-2">
                                      <div>
                                        <h5 className="fs-12 text-muted mb-0">
                                          {" "}
                                          Members :
                                        </h5>
                                      </div>
                                      <div className="avatar-group">
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <img
                                              src="assets/images/users/avatar-4.jpg"
                                              alt=""
                                              className="rounded-circle img-fluid"
                                            />
                                          </div>
                                        </div>
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <div className="avatar-title rounded-circle bg-light text-primary">
                                              M
                                            </div>
                                          </div>
                                        </div>
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <img
                                              src="assets/images/users/avatar-3.jpg"
                                              alt=""
                                              className="rounded-circle img-fluid"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* end card body */}
                            </div>
                            {/* end card */}
                          </div>
                          {/*end col*/}
                          <div className="col-xxl-3 col-sm-6">
                            <div className="card profile-project-card shadow-none profile-project-primary material-shadow">
                              <div className="card-body p-4">
                                <div className="d-flex">
                                  <div className="flex-grow-1 text-muted overflow-hidden">
                                    <h5 className="fs-14 text-truncate">
                                      <a href="#" className="text-body">
                                        Velzon Project
                                      </a>
                                    </h5>
                                    <p className="text-muted text-truncate mb-0">
                                      Last Update :{" "}
                                      <span className="fw-semibold text-body">
                                        11 hr Ago
                                      </span>
                                    </p>
                                  </div>
                                  <div className="flex-shrink-0 ms-2">
                                    <div className="badge bg-success-subtle text-success fs-10">
                                      Completed
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex mt-4">
                                  <div className="flex-grow-1">
                                    <div className="d-flex align-items-center gap-2">
                                      <div>
                                        <h5 className="fs-12 text-muted mb-0">
                                          Members :
                                        </h5>
                                      </div>
                                      <div className="avatar-group">
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <img
                                              src="assets/images/users/avatar-7.jpg"
                                              alt=""
                                              className="rounded-circle img-fluid"
                                            />
                                          </div>
                                        </div>
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <img
                                              src="assets/images/users/avatar-5.jpg"
                                              alt=""
                                              className="rounded-circle img-fluid"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* end card body */}
                            </div>
                            {/* end card */}
                          </div>
                          {/*end col*/}
                          <div className="col-xxl-3 col-sm-6">
                            <div className="card profile-project-card shadow-none profile-project-danger material-shadow">
                              <div className="card-body p-4">
                                <div className="d-flex">
                                  <div className="flex-grow-1 text-muted overflow-hidden">
                                    <h5 className="fs-14 text-truncate">
                                      <a href="#" className="text-body">
                                        Brand Logo Design
                                      </a>
                                    </h5>
                                    <p className="text-muted text-truncate mb-0">
                                      Last Update :{" "}
                                      <span className="fw-semibold text-body">
                                        10 min Ago
                                      </span>
                                    </p>
                                  </div>
                                  <div className="flex-shrink-0 ms-2">
                                    <div className="badge bg-info-subtle text-info fs-10">
                                      New
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex mt-4">
                                  <div className="flex-grow-1">
                                    <div className="d-flex align-items-center gap-2">
                                      <div>
                                        <h5 className="fs-12 text-muted mb-0">
                                          Members :
                                        </h5>
                                      </div>
                                      <div className="avatar-group">
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <img
                                              src="assets/images/users/avatar-7.jpg"
                                              alt=""
                                              className="rounded-circle img-fluid"
                                            />
                                          </div>
                                        </div>
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <img
                                              src="assets/images/users/avatar-6.jpg"
                                              alt=""
                                              className="rounded-circle img-fluid"
                                            />
                                          </div>
                                        </div>
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <div className="avatar-title rounded-circle bg-light text-primary">
                                              E
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* end card body */}
                            </div>
                            {/* end card */}
                          </div>
                          {/*end col*/}
                          <div className="col-xxl-3 col-sm-6">
                            <div className="card profile-project-card shadow-none profile-project-primary material-shadow">
                              <div className="card-body p-4">
                                <div className="d-flex">
                                  <div className="flex-grow-1 text-muted overflow-hidden">
                                    <h5 className="fs-14 text-truncate">
                                      <a href="#" className="text-body">
                                        Chat App
                                      </a>
                                    </h5>
                                    <p className="text-muted text-truncate mb-0">
                                      Last Update :{" "}
                                      <span className="fw-semibold text-body">
                                        8 hr Ago
                                      </span>
                                    </p>
                                  </div>
                                  <div className="flex-shrink-0 ms-2">
                                    <div className="badge bg-warning-subtle text-warning fs-10">
                                      Inprogress
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex mt-4">
                                  <div className="flex-grow-1">
                                    <div className="d-flex align-items-center gap-2">
                                      <div>
                                        <h5 className="fs-12 text-muted mb-0">
                                          Members :
                                        </h5>
                                      </div>
                                      <div className="avatar-group">
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <div className="avatar-title rounded-circle bg-light text-primary">
                                              R
                                            </div>
                                          </div>
                                        </div>
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <img
                                              src="assets/images/users/avatar-3.jpg"
                                              alt=""
                                              className="rounded-circle img-fluid"
                                            />
                                          </div>
                                        </div>
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <img
                                              src="assets/images/users/avatar-8.jpg"
                                              alt=""
                                              className="rounded-circle img-fluid"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* end card body */}
                            </div>
                            {/* end card */}
                          </div>
                          {/*end col*/}
                          <div className="col-xxl-3 col-sm-6">
                            <div className="card profile-project-card shadow-none profile-project-warning material-shadow">
                              <div className="card-body p-4">
                                <div className="d-flex">
                                  <div className="flex-grow-1 text-muted overflow-hidden">
                                    <h5 className="fs-14 text-truncate">
                                      <a href="#" className="text-body">
                                        Project Update
                                      </a>
                                    </h5>
                                    <p className="text-muted text-truncate mb-0">
                                      Last Update :{" "}
                                      <span className="fw-semibold text-body">
                                        48 min Ago
                                      </span>
                                    </p>
                                  </div>
                                  <div className="flex-shrink-0 ms-2">
                                    <div className="badge bg-warning-subtle text-warning fs-10">
                                      Inprogress
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex mt-4">
                                  <div className="flex-grow-1">
                                    <div className="d-flex align-items-center gap-2">
                                      <div>
                                        <h5 className="fs-12 text-muted mb-0">
                                          Members :
                                        </h5>
                                      </div>
                                      <div className="avatar-group">
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <img
                                              src="assets/images/users/avatar-6.jpg"
                                              alt=""
                                              className="rounded-circle img-fluid"
                                            />
                                          </div>
                                        </div>
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <img
                                              src="assets/images/users/avatar-5.jpg"
                                              alt=""
                                              className="rounded-circle img-fluid"
                                            />
                                          </div>
                                        </div>
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <img
                                              src="assets/images/users/avatar-4.jpg"
                                              alt=""
                                              className="rounded-circle img-fluid"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* end card body */}
                            </div>
                            {/* end card */}
                          </div>
                          {/*end col*/}
                          <div className="col-xxl-3 col-sm-6">
                            <div className="card profile-project-card shadow-none profile-project-success material-shadow">
                              <div className="card-body p-4">
                                <div className="d-flex">
                                  <div className="flex-grow-1 text-muted overflow-hidden">
                                    <h5 className="fs-14 text-truncate">
                                      <a href="#" className="text-body">
                                        Client - Jennifer
                                      </a>
                                    </h5>
                                    <p className="text-muted text-truncate mb-0">
                                      Last Update :{" "}
                                      <span className="fw-semibold text-body">
                                        30 min Ago
                                      </span>
                                    </p>
                                  </div>
                                  <div className="flex-shrink-0 ms-2">
                                    <div className="badge bg-primary-subtle text-primary fs-10">
                                      Process
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex mt-4">
                                  <div className="flex-grow-1">
                                    <div className="d-flex align-items-center gap-2">
                                      <div>
                                        <h5 className="fs-12 text-muted mb-0">
                                          {" "}
                                          Members :
                                        </h5>
                                      </div>
                                      <div className="avatar-group">
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <img
                                              src={profileImg}
                                              alt=""
                                              className="rounded-circle img-fluid"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* end card body */}
                            </div>
                            {/* end card */}
                          </div>
                          {/*end col*/}
                          <div className="col-xxl-3 col-sm-6">
                            <div className="card profile-project-card shadow-none mb-xxl-0 profile-project-info material-shadow">
                              <div className="card-body p-4">
                                <div className="d-flex">
                                  <div className="flex-grow-1 text-muted overflow-hidden">
                                    <h5 className="fs-14 text-truncate">
                                      <a href="#" className="text-body">
                                        Bsuiness Template - UI/UX design
                                      </a>
                                    </h5>
                                    <p className="text-muted text-truncate mb-0">
                                      Last Update :{" "}
                                      <span className="fw-semibold text-body">
                                        7 month Ago
                                      </span>
                                    </p>
                                  </div>
                                  <div className="flex-shrink-0 ms-2">
                                    <div className="badge bg-success-subtle text-success fs-10">
                                      Completed
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex mt-4">
                                  <div className="flex-grow-1">
                                    <div className="d-flex align-items-center gap-2">
                                      <div>
                                        <h5 className="fs-12 text-muted mb-0">
                                          Members :
                                        </h5>
                                      </div>
                                      <div className="avatar-group">
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <img
                                              src="assets/images/users/avatar-2.jpg"
                                              alt=""
                                              className="rounded-circle img-fluid"
                                            />
                                          </div>
                                        </div>
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <img
                                              src="assets/images/users/avatar-3.jpg"
                                              alt=""
                                              className="rounded-circle img-fluid"
                                            />
                                          </div>
                                        </div>
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <img
                                              src="assets/images/users/avatar-4.jpg"
                                              alt=""
                                              className="rounded-circle img-fluid"
                                            />
                                          </div>
                                        </div>
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <div className="avatar-title rounded-circle bg-primary">
                                              2+
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* end card body */}
                            </div>
                            {/* end card */}
                          </div>
                          {/*end col*/}
                          <div className="col-xxl-3 col-sm-6">
                            <div className="card profile-project-card shadow-none mb-xxl-0  profile-project-success material-shadow">
                              <div className="card-body p-4">
                                <div className="d-flex">
                                  <div className="flex-grow-1 text-muted overflow-hidden">
                                    <h5 className="fs-14 text-truncate">
                                      <a href="#" className="text-body">
                                        Update Project
                                      </a>
                                    </h5>
                                    <p className="text-muted text-truncate mb-0">
                                      Last Update :{" "}
                                      <span className="fw-semibold text-body">
                                        1 month Ago
                                      </span>
                                    </p>
                                  </div>
                                  <div className="flex-shrink-0 ms-2">
                                    <div className="badge bg-info-subtle text-info fs-10">
                                      New
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex mt-4">
                                  <div className="flex-grow-1">
                                    <div className="d-flex align-items-center gap-2">
                                      <div>
                                        <h5 className="fs-12 text-muted mb-0">
                                          Members :
                                        </h5>
                                      </div>
                                      <div className="avatar-group">
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <img
                                              src="assets/images/users/avatar-7.jpg"
                                              alt=""
                                              className="rounded-circle img-fluid"
                                            />
                                          </div>
                                        </div>
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <div className="avatar-title rounded-circle bg-light text-primary">
                                              A
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* end card body */}
                            </div>
                            {/* end card */}
                          </div>
                          {/*end col*/}
                          <div className="col-xxl-3 col-sm-6">
                            <div className="card profile-project-card shadow-none mb-sm-0  profile-project-danger material-shadow">
                              <div className="card-body p-4">
                                <div className="d-flex">
                                  <div className="flex-grow-1 text-muted overflow-hidden">
                                    <h5 className="fs-14 text-truncate">
                                      <a href="#" className="text-body">
                                        Bank Management System
                                      </a>
                                    </h5>
                                    <p className="text-muted text-truncate mb-0">
                                      Last Update :{" "}
                                      <span className="fw-semibold text-body">
                                        10 month Ago
                                      </span>
                                    </p>
                                  </div>
                                  <div className="flex-shrink-0 ms-2">
                                    <div className="badge bg-success-subtle text-success fs-10">
                                      Completed
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex mt-4">
                                  <div className="flex-grow-1">
                                    <div className="d-flex align-items-center gap-2">
                                      <div>
                                        <h5 className="fs-12 text-muted mb-0">
                                          Members :
                                        </h5>
                                      </div>
                                      <div className="avatar-group">
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <img
                                              src="assets/images/users/avatar-7.jpg"
                                              alt=""
                                              className="rounded-circle img-fluid"
                                            />
                                          </div>
                                        </div>
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <img
                                              src="assets/images/users/avatar-6.jpg"
                                              alt=""
                                              className="rounded-circle img-fluid"
                                            />
                                          </div>
                                        </div>
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <img
                                              src="assets/images/users/avatar-5.jpg"
                                              alt=""
                                              className="rounded-circle img-fluid"
                                            />
                                          </div>
                                        </div>
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <div className="avatar-title rounded-circle bg-primary">
                                              2+
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* end card body */}
                            </div>
                            {/* end card */}
                          </div>
                          {/*end col*/}
                          <div className="col-xxl-3 col-sm-6">
                            <div className="card profile-project-card shadow-none mb-0  profile-project-primary material-shadow">
                              <div className="card-body p-4">
                                <div className="d-flex">
                                  <div className="flex-grow-1 text-muted overflow-hidden">
                                    <h5 className="fs-14 text-truncate">
                                      <a href="#" className="text-body">
                                        PSD to HTML Convert
                                      </a>
                                    </h5>
                                    <p className="text-muted text-truncate mb-0">
                                      Last Update :{" "}
                                      <span className="fw-semibold text-body">
                                        29 min Ago
                                      </span>
                                    </p>
                                  </div>
                                  <div className="flex-shrink-0 ms-2">
                                    <div className="badge bg-info-subtle text-info fs-10">
                                      New
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex mt-4">
                                  <div className="flex-grow-1">
                                    <div className="d-flex align-items-center gap-2">
                                      <div>
                                        <h5 className="fs-12 text-muted mb-0">
                                          Members :
                                        </h5>
                                      </div>
                                      <div className="avatar-group">
                                        <div className="avatar-group-item material-shadow">
                                          <div className="avatar-xs">
                                            <img
                                              src="assets/images/users/avatar-7.jpg"
                                              alt=""
                                              className="rounded-circle img-fluid"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* end card body */}
                            </div>
                            {/* end card */}
                          </div>
                          {/*end col*/}
                          <div className="col-lg-12">
                            <div className="mt-4">
                              <ul className="pagination pagination-separated justify-content-center mb-0">
                                <li className="page-item disabled">
                                  <a
                                    href="javascript:void(0);"
                                    className="page-link"
                                  >
                                    <i className="mdi mdi-chevron-left"></i>
                                  </a>
                                </li>
                                <li className="page-item active">
                                  <a
                                    href="javascript:void(0);"
                                    className="page-link"
                                  >
                                    1
                                  </a>
                                </li>
                                <li className="page-item">
                                  <a
                                    href="javascript:void(0);"
                                    className="page-link"
                                  >
                                    2
                                  </a>
                                </li>
                                <li className="page-item">
                                  <a
                                    href="javascript:void(0);"
                                    className="page-link"
                                  >
                                    3
                                  </a>
                                </li>
                                <li className="page-item">
                                  <a
                                    href="javascript:void(0);"
                                    className="page-link"
                                  >
                                    4
                                  </a>
                                </li>
                                <li className="page-item">
                                  <a
                                    href="javascript:void(0);"
                                    className="page-link"
                                  >
                                    5
                                  </a>
                                </li>
                                <li className="page-item">
                                  <a
                                    href="javascript:void(0);"
                                    className="page-link"
                                  >
                                    <i className="mdi mdi-chevron-right"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/*end row*/}
                      </div>
                      {/*end card-body*/}
                    </div>
                    {/*end card*/}
                  </div>
                  {/*end tab-pane*/}
                  <div className="tab-pane fade" id="documents" role="tabpanel">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex align-items-center mb-4">
                          <h5 className="card-title flex-grow-1 mb-0">
                            Documents
                          </h5>
                          <div className="flex-shrink-0">
                            <input
                              className="form-control d-none"
                              type="file"
                              id="formFile"
                            />
                            <label
                              htmlFor="formFile"
                              className="btn btn-danger"
                            >
                              <i className="ri-upload-2-fill me-1 align-bottom"></i>{" "}
                              Upload File
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="table-responsive">
                              <table className="table table-borderless align-middle mb-0">
                                <thead className="table-light">
                                  <tr>
                                    <th scope="col">File Name</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Size</th>
                                    <th scope="col">Upload Date</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div className="avatar-sm">
                                          <div className="avatar-title bg-primary-subtle text-primary rounded fs-20 material-shadow">
                                            <i className="ri-file-zip-fill"></i>
                                          </div>
                                        </div>
                                        <div className="ms-3 flex-grow-1">
                                          <h6 className="fs-15 mb-0">
                                            <a href="javascript:void(0)">
                                              Artboard-documents.zip
                                            </a>
                                          </h6>
                                        </div>
                                      </div>
                                    </td>
                                    <td>Zip File</td>
                                    <td>4.57 MB</td>
                                    <td>12 Dec 2021</td>
                                    <td>
                                      <div className="dropdown">
                                        <a
                                          href="javascript:void(0);"
                                          className="btn btn-light btn-icon"
                                          id="dropdownMenuLink15"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="true"
                                        >
                                          <i className="ri-equalizer-fill"></i>
                                        </a>
                                        <ul
                                          className="dropdown-menu dropdown-menu-end"
                                          aria-labelledby="dropdownMenuLink15"
                                        >
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              <i className="ri-eye-fill me-2 align-middle text-muted"></i>
                                              View
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              <i className="ri-download-2-fill me-2 align-middle text-muted"></i>
                                              Download
                                            </a>
                                          </li>
                                          <li className="dropdown-divider"></li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              <i className="ri-delete-bin-5-line me-2 align-middle text-muted"></i>
                                              Delete
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div className="avatar-sm">
                                          <div className="avatar-title bg-danger-subtle text-danger rounded fs-20 material-shadow">
                                            <i className="ri-file-pdf-fill"></i>
                                          </div>
                                        </div>
                                        <div className="ms-3 flex-grow-1">
                                          <h6 className="fs-15 mb-0">
                                            <a href="javascript:void(0);">
                                              Bank Management System
                                            </a>
                                          </h6>
                                        </div>
                                      </div>
                                    </td>
                                    <td>PDF File</td>
                                    <td>8.89 MB</td>
                                    <td>24 Nov 2021</td>
                                    <td>
                                      <div className="dropdown">
                                        <a
                                          href="javascript:void(0);"
                                          className="btn btn-light btn-icon"
                                          id="dropdownMenuLink3"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="true"
                                        >
                                          <i className="ri-equalizer-fill"></i>
                                        </a>
                                        <ul
                                          className="dropdown-menu dropdown-menu-end"
                                          aria-labelledby="dropdownMenuLink3"
                                        >
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              <i className="ri-eye-fill me-2 align-middle text-muted"></i>
                                              View
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              <i className="ri-download-2-fill me-2 align-middle text-muted"></i>
                                              Download
                                            </a>
                                          </li>
                                          <li className="dropdown-divider"></li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              <i className="ri-delete-bin-5-line me-2 align-middle text-muted"></i>
                                              Delete
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div className="avatar-sm">
                                          <div className="avatar-title bg-secondary-subtle text-secondary rounded fs-20 material-shadow">
                                            <i className="ri-video-line"></i>
                                          </div>
                                        </div>
                                        <div className="ms-3 flex-grow-1">
                                          <h6 className="fs-15 mb-0">
                                            <a href="javascript:void(0);">
                                              Tour-video.mp4
                                            </a>
                                          </h6>
                                        </div>
                                      </div>
                                    </td>
                                    <td>MP4 File</td>
                                    <td>14.62 MB</td>
                                    <td>19 Nov 2021</td>
                                    <td>
                                      <div className="dropdown">
                                        <a
                                          href="javascript:void(0);"
                                          className="btn btn-light btn-icon"
                                          id="dropdownMenuLink4"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="true"
                                        >
                                          <i className="ri-equalizer-fill"></i>
                                        </a>
                                        <ul
                                          className="dropdown-menu dropdown-menu-end"
                                          aria-labelledby="dropdownMenuLink4"
                                        >
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              <i className="ri-eye-fill me-2 align-middle text-muted"></i>
                                              View
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              <i className="ri-download-2-fill me-2 align-middle text-muted"></i>
                                              Download
                                            </a>
                                          </li>
                                          <li className="dropdown-divider"></li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              <i className="ri-delete-bin-5-line me-2 align-middle text-muted"></i>
                                              Delete
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div className="avatar-sm">
                                          <div className="avatar-title bg-success-subtle text-success rounded fs-20 material-shadow">
                                            <i className="ri-file-excel-fill"></i>
                                          </div>
                                        </div>
                                        <div className="ms-3 flex-grow-1">
                                          <h6 className="fs-15 mb-0">
                                            <a href="javascript:void(0);">
                                              Account-statement.xsl
                                            </a>
                                          </h6>
                                        </div>
                                      </div>
                                    </td>
                                    <td>XSL File</td>
                                    <td>2.38 KB</td>
                                    <td>14 Nov 2021</td>
                                    <td>
                                      <div className="dropdown">
                                        <a
                                          href="javascript:void(0);"
                                          className="btn btn-light btn-icon"
                                          id="dropdownMenuLink5"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="true"
                                        >
                                          <i className="ri-equalizer-fill"></i>
                                        </a>
                                        <ul
                                          className="dropdown-menu dropdown-menu-end"
                                          aria-labelledby="dropdownMenuLink5"
                                        >
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              <i className="ri-eye-fill me-2 align-middle text-muted"></i>
                                              View
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              <i className="ri-download-2-fill me-2 align-middle text-muted"></i>
                                              Download
                                            </a>
                                          </li>
                                          <li className="dropdown-divider"></li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              <i className="ri-delete-bin-5-line me-2 align-middle text-muted"></i>
                                              Delete
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div className="avatar-sm">
                                          <div className="avatar-title bg-info-subtle text-info rounded fs-20 material-shadow">
                                            <i className="ri-folder-line"></i>
                                          </div>
                                        </div>
                                        <div className="ms-3 flex-grow-1">
                                          <h6 className="fs-15 mb-0">
                                            <a href="javascript:void(0);">
                                              Project Screenshots Collection
                                            </a>
                                          </h6>
                                        </div>
                                      </div>
                                    </td>
                                    <td>Floder File</td>
                                    <td>87.24 MB</td>
                                    <td>08 Nov 2021</td>
                                    <td>
                                      <div className="dropdown">
                                        <a
                                          href="javascript:void(0);"
                                          className="btn btn-light btn-icon"
                                          id="dropdownMenuLink6"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="true"
                                        >
                                          <i className="ri-equalizer-fill"></i>
                                        </a>
                                        <ul
                                          className="dropdown-menu dropdown-menu-end"
                                          aria-labelledby="dropdownMenuLink6"
                                        >
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              <i className="ri-eye-fill me-2 align-middle"></i>
                                              View
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              <i className="ri-download-2-fill me-2 align-middle"></i>
                                              Download
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              <i className="ri-delete-bin-5-line me-2 align-middle"></i>
                                              Delete
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div className="avatar-sm">
                                          <div className="avatar-title bg-danger-subtle text-danger rounded fs-20 material-shadow">
                                            <i className="ri-image-2-fill"></i>
                                          </div>
                                        </div>
                                        <div className="ms-3 flex-grow-1">
                                          <h6 className="fs-15 mb-0">
                                            <a href="javascript:void(0);">
                                              Velzon-logo.png
                                            </a>
                                          </h6>
                                        </div>
                                      </div>
                                    </td>
                                    <td>PNG File</td>
                                    <td>879 KB</td>
                                    <td>02 Nov 2021</td>
                                    <td>
                                      <div className="dropdown">
                                        <a
                                          href="javascript:void(0);"
                                          className="btn btn-light btn-icon"
                                          id="dropdownMenuLink7"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="true"
                                        >
                                          <i className="ri-equalizer-fill"></i>
                                        </a>
                                        <ul
                                          className="dropdown-menu dropdown-menu-end"
                                          aria-labelledby="dropdownMenuLink7"
                                        >
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              <i className="ri-eye-fill me-2 align-middle"></i>
                                              View
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              <i className="ri-download-2-fill me-2 align-middle"></i>
                                              Download
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              <i className="ri-delete-bin-5-line me-2 align-middle"></i>
                                              Delete
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="text-center mt-3">
                              <a
                                href="javascript:void(0);"
                                className="text-success"
                              >
                                <i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>{" "}
                                Load more{" "}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*end tab-pane*/}
                </div>
                {/*end tab-content*/}
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/* container-fluid */}
      </div>
      {/* End Page-content */}
    </React.Fragment>
  );
};

export default ContractorProfileDetail;
