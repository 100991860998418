import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PageTitleBox from "../../../../reusable/title/pageTitleBox";
import LoaderView from "../../../../reusable/loading/loaderView";
import Joi from "joi-browser";
//uuid
import { v4 as uuidv4 } from "uuid";
import config from "../../../../../constants/config";
import { getRentalFeePackTemplates } from "../../../../../services/templates/fees/rentalFeePackService";
import { devLog } from "../../../../../services/utils/logger";
import FeePackListTable from "../../../../reusable/tables/fees/FeePackListTable";
import { useThemeClasses } from "../../../../../services/utils/hooks/useThemeClasses";
//this is creating the template the copy is done when a new contract or advertisement is created
const AllRentalFeePacks = ({ isDarkMode }) => {
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const [isLoading, setIsLoading] = useState(false);
  const [rentalFeePacks, setRentalFeePacks] = useState([
    {
      rental_fee_pack_template_id: "",
      fee_pack_name: "",
      fee_pack_description: "",
      require_security_deposit: false,
      require_last_month_rent: false,
      require_pet_deposit: false,
      require_pet_rent: false,
      collect_prorated_rent: false,
      automatically_increase_rent: false,
      rent_increase_percentage: 0,
      rent_increase_period: "",
      rent_paid_through_gurenter: false,
      require_guarantor: false,
      bank_account_id: "",
      bank_id: "",
      bank_name: "",
      bank_account_name: "",
      bank_account_number: "",
      bank_routing_number: "",
      is_bank_verified: false,
      created_at: Date(),
      created_by: "",
      user_id: "",
      user_defined_charges: [
        {
          charge_name: "",
          charge_amount: 0,
        },
      ],
      currency_id: "",
      currency_name: "",
    },
  ]);

  const fetchAllRentalFeePacks = async () => {
    setIsLoading(true);
    getRentalFeePackTemplates().then((result) => {
      setIsLoading(false);
      devLog("All Rental Fee Packs", result);
      setRentalFeePacks(result);
    });
  };

  useEffect(() => {
    try {
      fetchAllRentalFeePacks();
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <React.Fragment>
      {isLoading && <LoaderView />}
      <div className={`${getThemeClasses()}`}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle={"All Rental Fee Packs"}
            previousPageTitle=""
          />

          {/*
 <div>
            AllRentalFeePacks
            <Link to={`/createRentalFeePack`}>Create New Rental Fee Pack</Link>
          </div>
*/}

          <FeePackListTable
            rentalFeePacks={rentalFeePacks}
            isDarkMode={isDarkMode}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AllRentalFeePacks;
