import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoaderView from "../../../../reusable/loading/loaderView";
import PageTitleBox from "../../../../reusable/title/pageTitleBox";
import user_profile_img from "../../../../../styletheme/images/users/avatar-10.jpg";
import { getAllRevenuesForPropertyUnitYear } from "../../../../../services/propertyUnits/revenueAndExpenses/revenue/propertyUnitRevenueManagement";
import { getAllExpensesForPropertyUnitYear } from "../../../../../services/propertyUnits/revenueAndExpenses/expenses/propertyUnitExpenseManagement";
import { getExpenseCategories } from "../../../../../services/systemDefined/categories/expenses/sysExpenseCategoryManagement";
import { getRevenueCategories } from "../../../../../services/systemDefined/categories/revenue/sysRevenueCategoryManagement";
import { getTransactions } from "../../../../../services/bank/bankManager";
import { Modal, Badge, Button } from "react-bootstrap";
import newDocumentImg from "../../../../../styletheme/images/new-document.png";

import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { formatCurrency } from "../../../../../services/utils/currencyUtils";
import ShimmerEffect from "../../../../reusable/loading/ShimmerEffect";
import SyncTransactionsForm from "./sync/SyncTransactionsForm";
import BankAccountIssuesResolver from "./sync/BankAccountIssuesResolver";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useThemeClasses } from "../../../../../services/utils/hooks/useThemeClasses";
//TODO: need to give the ability to set the category of the transaction and import them into the database after i do the basic tickets
const UnitPropertyTransactions = ({ isDarkMode }) => {
  const { propertyUnitId } = useParams();
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(true);
  const pageTitle = "Unit Transactions";

  const navigate = useNavigate();

  //revenue transactions
  const [revenueTransactions, setRevenueTransactions] = useState([
    {
      revenue_category_id: "",
      created_at: "",
      is_active: true,
      revenue_category: "",
      updated_at: "",
    },
  ]);

  //expenses transactions
  const [expensesTransactions, setExpensesTransactions] = useState([
    {
      expense_category_id: "",
      created_at: "",
      expense_category: "",
      is_active: true,
      updated_at: "",
    },
  ]);

  //combined transactions
  const [transactions, setTransactions] = useState([
    {
      is_revenue_or_expense: "",
      unit_revenue_expense_id: "",
      property_unit_id: "",
      category_id: 0,
      amount: 0,
      date: new Date(),
      is_active: true,
      is_received_from_tenant: false,
      payment_date: new Date(),
      is_capital_revenue: false,
      title: "",
      description: "",
      receipt_link: "",
      day: new Date().getDate(),
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      created_at: new Date(),
      updated_at: new Date(),
      transaction_receipt_image_uploads: [
        {
          base64_data: "",
          url: "",
          file_name: "",
          file_size: 0,
          file_size_formatted: "",
          file_type: "",
          upload_time_stamp: "",
          unique_file_name: "",
        },
      ],
    },
  ]);
  const transactionColumns = [
    {
      field: "date",
      headerName: "Date",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name">
            {new Date(params.row.date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </div>
        </div>
      ),
    },
    {
      field: "logo_url",
      headerName: "",
      width: 90,
      renderCell: (params) => (
        <img
          src={
            params.row.logo_url ??
            (params.row.transaction_receipt_image_uploads?.length > 0
              ? params.row.transaction_receipt_image_uploads[0].url
              : newDocumentImg) ??
            newDocumentImg
          }
          style={{ width: "80%", height: "80%" }}
          alt="transaction"
        />
      ),
    },
    {
      field: "account",
      headerName: "Account",
      width: 150,
      editable: true,
      //placeholder
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name">Plaid Checking</div>
        </div>
      ),
    },
    {
      field: "title",
      headerName: "Title",
      width: 250,
      editable: true,
      //placeholder
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name">{params.row.title}</div>
        </div>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      width: 350,
      editable: true,

      //placeholder
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name">{params.row.description}</div>
        </div>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      width: 110,
      editable: true,
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name">
            {/* Format the amount, two decimal places etc*/}

            {formatCurrency(params.row.amount)}
          </div>
        </div>
      ),
    },
    {
      field: "category",
      headerName: "Category",
      description: "Category of the transaction",
      sortable: false,
      width: 300,
      editable: true,
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name">
            {findCategoryName(
              params.row.category_id,
              params.row.is_revenue_or_expense
            )}
          </div>
        </div>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      width: 110,
      editable: true,
      renderCell: (params) => {
        return (
          <span className="badge bg-primary-subtle text-primary">
            {params.row.is_revenue_or_expense}
          </span>
        );
      },
    },
  ];

  //plaid transactions

  const [plaidTransactions, setPlaidTransactions] = useState([]);

  //temp
  const columns = [
    {
      field: "logo_url",
      headerName: "",
      width: 90,
      renderCell: (params) => (
        <img
          src={params.row.logo_url ?? newDocumentImg}
          style={{ width: "80%", height: "80%" }}
          alt="transaction"
        />
      ),
    },
    {
      field: "account",
      headerName: "Account",
      width: 150,
      editable: true,
      //placeholder
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name">Plaid Checking</div>
        </div>
      ),
    },
    {
      field: "title",
      headerName: "Title",
      width: 250,
      editable: true,
      //placeholder
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name">{params.row.name}</div>
        </div>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      width: 110,
      editable: true,
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name">
            {currencySymbols[params.row.iso_currency_code]}
            {params.row.amount} {params.row.iso_currency_code}
          </div>
        </div>
      ),
    },
    {
      field: "category",
      headerName: "Category",
      description: "Category of the transaction",
      sortable: false,
      width: 300,
      editable: true,
    },
  ];

  const fetchAllRevenueTransactionsForCurrentYear = async () => {
    //fetch all the revenue transactions for the current year
    const revenueTransactions = await getAllRevenuesForPropertyUnitYear(
      propertyUnitId
    );

    //temp
    // Add 'is_revenue_or_expense' property to each transaction
    const revenueTransactionsWithRevenueOrExpense = revenueTransactions.map(
      (transaction) => ({
        ...transaction,
        is_revenue_or_expense: "revenue",
      })
    );

    console.log("revenueTransactions");
    console.log(revenueTransactionsWithRevenueOrExpense);
    setRevenueTransactions(revenueTransactionsWithRevenueOrExpense);

    return revenueTransactionsWithRevenueOrExpense; // Return the modified transactions
  };
  const fetchAllExpensesTransactionsForCurrentYear = async () => {
    //fetch all the expenses transactions for the current year
    const expensesTransactions = await getAllExpensesForPropertyUnitYear(
      propertyUnitId
    );

    // Add 'is_revenue_or_expense' property to each transaction
    const expensesTransactionsWithRevenueOrExpense = expensesTransactions.map(
      (transaction) => ({
        ...transaction,
        is_revenue_or_expense: "expense",
      })
    );

    console.log("expensesTransactions");
    console.log(expensesTransactionsWithRevenueOrExpense);
    setExpensesTransactions(expensesTransactionsWithRevenueOrExpense);

    return expensesTransactionsWithRevenueOrExpense; // Return the modified transactions
  };

  const fetchAllTransactions = async () => {
    const [revenueTransactions, expensesTransactions] = await Promise.all([
      fetchAllRevenueTransactionsForCurrentYear(),
      fetchAllExpensesTransactionsForCurrentYear(),
    ]);

    const transactions = [...revenueTransactions, ...expensesTransactions].map(
      (transaction) => {
        if (transaction.is_revenue_or_expense === "revenue") {
          return {
            is_revenue_or_expense: transaction.is_revenue_or_expense,
            unit_revenue_expense_id: transaction.unit_revenue_id,
            property_unit_id: transaction.property_unit_id,
            category_id: transaction.revenue_category_id,
            amount: transaction.revenue_amount,
            date: new Date(transaction.revenue_date),
            is_active: transaction.is_active,
            is_received_from_tenant: transaction.is_received_from_tenant,
            payment_date: new Date(transaction.payment_date),
            is_capital_revenue: transaction.is_capital_revenue,
            title: transaction.revenue_title,
            description: transaction.revenue_description,
            receipt_link: transaction.receipt_link,
            day: new Date(transaction.revenue_date).getDate(),
            month: new Date(transaction.revenue_date).getMonth() + 1,
            year: new Date(transaction.revenue_date).getFullYear(),
            created_at: new Date(transaction.created_at),
            updated_at: new Date(transaction.updated_at),
            transaction_receipt_image_uploads:
              transaction.transaction_receipt_image_uploads,
          };
        } else {
          return {
            is_revenue_or_expense: transaction.is_revenue_or_expense,
            unit_revenue_expense_id: transaction.unit_expense_id,
            property_unit_id: transaction.property_unit_id,
            category_id: transaction.expense_category_id,
            amount: transaction.expense_amount,
            date: new Date(transaction.expense_date),
            is_active: transaction.is_active,
            is_paid_by_tenant: transaction.is_paid_by_tenant,
            is_paid_by_landlord: transaction.is_paid_by_landlord,
            payment_date: new Date(transaction.payment_date),
            is_capital_expense: transaction.is_capital_expense,
            title: transaction.expense_title,
            description: transaction.expense_description,
            receipt_link: transaction.receipt_link,
            day: new Date(transaction.expense_date).getDate(),
            month: new Date(transaction.expense_date).getMonth() + 1,
            year: new Date(transaction.expense_date).getFullYear(),
            created_at: new Date(transaction.created_at),
            updated_at: new Date(transaction.updated_at),
            transaction_receipt_image_uploads:
              transaction.transaction_receipt_image_uploads,
          };
        }
      }
    );

    setTransactions(transactions);
    console.log("transactions");
    console.log(transactions);
  };

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? "dark" : "light",
    },
  });

  //get expense categories
  const [sysExpenseCategories, setSysExpenseCategories] = useState([]);

  const [sysRevenueCategories, setSysRevenueCategories] = useState([]);
  const fetchExpenseCategories = async () => {
    //get the expense categories

    const sysExpenseCategories = await getExpenseCategories();

    if (sysExpenseCategories) {
      setSysExpenseCategories(sysExpenseCategories);

      console.log(sysExpenseCategories);
    }
  };

  //get revenue categories
  const fetchRevenueCategories = async () => {
    //get the revenue categories
    const sysRevenueCategories = await getRevenueCategories();

    if (sysRevenueCategories) {
      setSysRevenueCategories(sysRevenueCategories);

      console.log(sysRevenueCategories);
    }
  };

  //find the name of the category based on the id
  const findCategoryName = (categoryId, isRevenue) => {
    if (isRevenue === "revenue") {
      const category = sysRevenueCategories.find(
        (category) => category.revenue_category_id === categoryId
      );
      return category ? category.revenue_category : "";
    } else {
      const category = sysExpenseCategories.find(
        (category) => category.expense_category_id === categoryId
      );
      return category ? category.expense_category : "";
    }
  };

  const [needsReauthentication, setNeedsReauthentication] = useState(false);

  const getPlaidTransactions = async () => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setFullYear(endDate.getFullYear() - 1);

    const formatDate = (date) => {
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    };

    try {
      const response = await getTransactions(
        propertyUnitId,
        formatDate(startDate),
        formatDate(endDate)
      );

      if (response.message === "Some items require attention") {
        setNeedsReauthentication(true);
        setPlaidTransactions([]);
      } else {
        setPlaidTransactions(response);
        setNeedsReauthentication(false);
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      toast.error("Failed to fetch transactions. Please try again.");
    }
  };

  const handleReauthenticationComplete = (transactions) => {
    setNeedsReauthentication(false);
    setPlaidTransactions(transactions);
  };

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);

  const currencySymbols = {
    USD: "$",
    EUR: "€",
    JPY: "¥",
    GBP: "£",
    AUD: "A$",
    CAD: "C$",
    CHF: "CHF",
    CNY: "¥",
    SEK: "kr",
    NZD: "NZ$",
  };

  //the table controls
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
  };

  const updatePlaidTransactionRow = async (updatedRow, originalRow) => {
    console.log(updatedRow, originalRow);

    //update the transaction

    const updatedTransactions = transactions.map((transaction) => {
      if (transaction.transaction_id === updatedRow.transaction_id) {
        return {
          ...transaction,
          category_id: updatedRow.category_id,
        };
      } else {
        return transaction;
      }
    });

    setTransactions(updatedTransactions);
  };

  const handleShowModal = async () => {
    await getPlaidTransactions();
    setShowModal(true);
  };

  const handleReauthenticate = () => {
    setNeedsReauthentication(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsLoadingTransactions(true);
      try {
        await Promise.all([
          fetchAllRevenueTransactionsForCurrentYear(),
          fetchAllExpensesTransactionsForCurrentYear(),
          fetchExpenseCategories(),
          fetchRevenueCategories(),
        ]);
        await fetchAllTransactions();
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch Plaid transactions. Please try again.");
      } finally {
        setIsLoadingTransactions(false);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [propertyUnitId]);

  return (
    <React.Fragment>
      {/*Loader */}
      {isLoading && <LoaderView />}

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="modal-fullscreen"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {needsReauthentication
              ? "Re-authenticate Bank Accounts"
              : "Categorize Plaid Transactions"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {needsReauthentication ? (
            <BankAccountIssuesResolver
              onResolve={handleReauthenticationComplete}
              propertyUnitId={propertyUnitId}
            />
          ) : (
            <SyncTransactionsForm
              plaidTransactions={plaidTransactions}
              propertyUnitId={propertyUnitId}
              onClose={handleCloseModal}
              expenseCategories={sysExpenseCategories}
              revenueCategories={sysRevenueCategories}
              onReauthenticate={handleReauthenticate}
            />
          )}
        </Modal.Body>
      </Modal>

      <div className={`${getThemeClasses()}`}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageTitle="Property Unit"
            previousPageLink={`/propertyunit/${propertyUnitId}`}
          />

          <div className="row">
            <div className="col-lg-12">
              <div className="card" id="leadsList">
                <div className="card-header border-0">
                  <div className="row g-4 align-items-center">
                    <div className="col-sm-3">
                      <div hidden className="search-box">
                        <input
                          type="text"
                          className="form-control search"
                          placeholder="Search for..."
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </div>
                    <div className="col-sm-auto ms-auto">
                      <div className="hstack gap-2">
                        <Link
                          to={`/propertyUnitBankAccounts/${propertyUnitId}`}
                          className="btn btn-info add-btn"
                          data-bs-toggle="modal"
                          id="create-btn"
                          data-bs-target="#showModal"
                        >
                          <i className="ri-bank-line align-bottom me-1"></i>{" "}
                          Bank Accounts
                        </Link>
                        {/* When you press it it shows a table with ability to bulk or individually assign the unit and category and resolve to save to the
                        database as each is categorized*/}
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target=".exampleModalFullscreen"
                          onClick={handleShowModal}
                        >
                          <span className="badge bg-success">
                            {plaidTransactions &&
                              plaidTransactions.length > 0 &&
                              plaidTransactions[0].name &&
                              plaidTransactions.length}
                          </span>{" "}
                          Sync Transactions
                        </button>

                        <button
                          className="btn btn-soft-danger"
                          id="remove-actions"
                          onClick="deleteMultiple()"
                        >
                          <i className="ri-delete-bin-2-line"></i>
                        </button>

                        <Link
                          to={`/newUnitTransaction/${propertyUnitId}`}
                          className="btn btn-success add-btn"
                          data-bs-toggle="modal"
                          id="create-btn"
                          data-bs-target="#showModal"
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Transaction
                        </Link>
                        <span className="dropdown" hidden>
                          <button
                            className="btn btn-soft-info btn-icon fs-14"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="ri-settings-4-line"></i>
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <a className="dropdown-item" href="#">
                                Copy
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                Move to pipline
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                Add to exceptions
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                Switch to common form view
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                Reset form view to default
                              </a>
                            </li>
                          </ul>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div>
                    <div>
                      <ThemeProvider theme={theme}>
                        <Box sx={{ height: 650, width: "100%" }}>
                          {isLoadingTransactions ? (
                            <div className="d-flex flex-column gap-3">
                              <ShimmerEffect type="line" height="40px" />
                              {[...Array(10)].map((_, index) => (
                                <ShimmerEffect
                                  key={index}
                                  type="line"
                                  height="52px"
                                  width="100%"
                                  darkMode={isDarkMode}
                                />
                              ))}
                            </div>
                          ) : (
                            <DataGrid
                              rows={transactions || []}
                              getRowId={(row) =>
                                row.unit_revenue_expense_id || 0
                              }
                              columns={transactionColumns}
                              initialState={{
                                pagination: {
                                  paginationModel: {
                                    pageSize: 10,
                                  },
                                },
                              }}
                              slots={{ toolbar: GridToolbar }}
                              slotProps={{
                                toolbar: {
                                  showQuickFilter: true,
                                },
                              }}
                              pageSizeOptions={[5]}
                              checkboxSelection
                              disableRowSelectionOnClick
                              processRowUpdate={(updatedRow, originalRow) => {
                                console.log(updatedRow, originalRow);
                                //update the transaction
                              }}
                              onRowClick={(row) => {
                                if (
                                  row.row.is_revenue_or_expense === "expense"
                                ) {
                                  navigate(
                                    `/expenseDetail/${row.row.unit_revenue_expense_id}`
                                  );
                                } else {
                                  navigate(
                                    `/revenueDetail/${row.row.unit_revenue_expense_id}`
                                  );
                                }
                              }}
                              onProcessRowUpdateError={(
                                error,
                                updatedRow,
                                originalRow
                              ) => console.log(error, updatedRow, originalRow)}
                            />
                          )}
                        </Box>
                      </ThemeProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
      </div>
    </React.Fragment>
  );
};

export default UnitPropertyTransactions;
