import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PageTitleBox from "../../../../reusable/title/pageTitleBox";
import LoaderView from "../../../../reusable/loading/loaderView";
import Joi from "joi-browser";
//uuid
import { v4 as uuidv4 } from "uuid";
import config from "../../../../../constants/config";
import Input from "../../../../reusable/form/Input";
import MultiLineInput from "../../../../reusable/form/multilineinput";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import { getBankAccounts } from "../../../../../services/bank/bankManager";
import { selectCustomStyle } from "../../../../../styletheme/custom/selectStyle";
import { createRentalFeePack } from "../../../../../services/templates/fees/rentalFeePackService";
import { useNavigate } from "react-router-dom";
import { useThemeClasses } from "../../../../../services/utils/hooks/useThemeClasses";
import { fetchAllCurrencies } from "services/systemDefined/currency/sysCurrencyManagement";
import { devLog } from "services/utils/logger";

const CreateRentalFeePack = ({ isDarkMode }) => {
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [manuallyAddBankAccount, setManuallyAddBankAccount] = useState(false);
  const [feePackTemplateFormData, setFeePackTemplateFormData] = useState({
    rental_fee_pack_template_id: uuidv4(),
    fee_pack_name: "",
    fee_pack_description: "",
    require_security_deposit: false,
    require_last_month_rent: false,
    require_pet_deposit: false,
    require_pet_rent: false,
    collect_prorated_rent: false,
    automatically_increase_rent: false,
    rent_increase_percentage: 0,
    rent_increase_period: "annually",
    rent_paid_through_gurenter: false,
    require_guarantor: false,
    bank_account_id: "",
    bank_id: "",
    bank_name: "",
    bank_account_name: "",
    bank_account_number: "",
    bank_routing_number: "",
    is_bank_verified: false,
    created_at: Date.now(),
    created_by:
      localStorage.getItem(config.first_name) +
        " " +
        localStorage.getItem(config.last_name) || "",
    user_id: localStorage.getItem(config.user_id) || "",
    user_defined_charges: [
      {
        charge_name: "",
        charge_amount: 0,
      },
    ],
    currency_id: "",
    currency_name: "",
  });

  //Currency
  //currencyOptions
  const [currencyOptions, setCurrencyOptions] = useState([
    { value: "", label: "", data: {} },
  ]);
  const [selectedCurrencyOption, setSelectedCurrencyOption] = useState(null);

  const handleCurrencyChange = (option) => {
    //if (!option) return;
    setSelectedCurrencyOption(option);
    feePackTemplateFormData.currency_id = option.value;
    feePackTemplateFormData.currency_name = option.label;
  };

  const getCurrencyOptions = async () => {
    const response = await fetchAllCurrencies();
    const options = response.map((currency) => ({
      value: currency.currency_id,
      label: currency.currency_name,
      data: currency,
    }));
    setCurrencyOptions(options);

    // Set selectedCurrencyOption to the option where currency_code is "USD"
    const usdOption = options.find(
      (option) => option.data.currency_code === "USD"
    );
    if (usdOption) {
      setSelectedCurrencyOption(usdOption);
      setFeePackTemplateFormData((prevState) => ({
        ...prevState,
        currency_id: usdOption.value,
        currency_name: usdOption.label,
      }));
    }
  };

  const [errors, setErrors] = useState({});
  const feePackSchema = Joi.object({
    fee_pack_name: Joi.string().required().label("Fee Pack Name"),
    fee_pack_description: Joi.string()
      .optional()
      .allow("")
      .label("Fee Pack Description"),
    require_security_deposit: Joi.boolean()
      .optional()
      .label("Require Security Deposit"),
    require_last_month_rent: Joi.boolean()
      .optional()
      .label("Require Last Month Rent"),
    require_pet_deposit: Joi.boolean().optional().label("Require Pet Deposit"),
    require_pet_rent: Joi.boolean().optional().label("Require Pet Rent"),
    collect_prorated_rent: Joi.boolean()
      .optional()
      .label("Collect Prorated Rent"),
    automatically_increase_rent: Joi.boolean()
      .optional()
      .label("Automatically Increase Rent"),
    rent_increase_percentage: Joi.number()
      .optional()
      .label("Rent Increase Percentage"),
    rent_increase_period: Joi.string().optional().label("Rent Increase Period"),
    rent_paid_through_gurenter: Joi.boolean()
      .optional()
      .label("Rent Paid Through Gurenter"),
    require_guarantor: Joi.boolean().optional().label("Require Guarantor"),
    bank_name: Joi.string().optional().label("Bank Name"),
    bank_account_name: Joi.string().optional().label("Bank Account Name"),
    bank_account_number: Joi.string().optional().label("Bank Account Number"),
    bank_routing_number: Joi.string().optional().label("Bank Routing Number"),
    is_bank_verified: Joi.boolean().optional().label("Is Bank Verified"),
    created_at: Joi.date().optional().label("Created At"),
    created_by: Joi.string().optional().label("Created By"),
    user_id: Joi.string().optional().label("User ID"),
    user_defined_charges: Joi.array().optional().label("User Defined Charges"),
    currency_id: Joi.string().optional().label("Currency ID"),
    currency_name: Joi.string().optional().label("Currency Name"),
  });

  const [rentIncreasePeriodOptions, setRentIncreasePeriodOptions] = useState([
    { value: "annually", label: "Annually" },
    { value: "monthly", label: "Monthly" },
    { value: "quarterly", label: "Quarterly" },
  ]);

  const [
    selectedRentIncreasePeriodOption,
    setSelectedRentIncreasePeriodOption,
  ] = useState({
    value: "annually",
    label: "Annually",
  });

  const [bankAccountOptions, setBankAccountOptions] = useState([
    {
      value: "",
      label: "",
      data: {},
    },
  ]);
  const [selectedBankAccount, setSelectedBankAccount] = useState({
    value: "",
    label: "",
    data: {},
  });

  const [bankAccounts, setBankAccounts] = useState([
    {
      account_id: "",
      account_mask: "",
      account_name: "",
      account_subtype: "",
      account_type: "",
      available_balance: 0,
      bank_account_id: "",
      bank_id: "",
      created_at: "",
      currency_id: "",
      current_balance: 0,
      is_default_account: false,
      updated_at: "",
      routing_number: "",
      routing_numbers: [""],
      logo: "",
      bank_name: "",
      institution_id: "",
      url: "",
    },
  ]);

  //get all bank accounts for this unit
  const fetchBankAccounts = async () => {
    const bankAccounts = await getBankAccounts();

    console.log("Bank Accounts", bankAccounts);

    if (bankAccounts?.length > 0) {
      setBankAccounts(bankAccounts);

      // Map the bank accounts with null checks
      const bankAccountOptions = bankAccounts.map((bankAccount) => {
        const accountName = bankAccount?.account_name || "Unknown Account";
        const bankName = bankAccount?.bank_name || "Unknown Bank";

        return {
          value: bankAccount.bank_account_id || "",
          label: `${accountName} - ${bankName}`,
          data: bankAccount,
        };
      });

      setBankAccountOptions(bankAccountOptions);
    }
  };

  const handleBankAccountChange = (selectedOption) => {
    console.log("handleBankAccountChange");
    console.log(selectedOption);
    setSelectedBankAccount(selectedOption);

    if (selectedOption?.__isNew__) {
      toast.info("Creating New Bank Account.");
      setFeePackTemplateFormData((prevState) => ({
        ...prevState,
        bank_name: selectedOption?.label || "",
      }));
      setManuallyAddBankAccount(true);
      return;
    }

    setManuallyAddBankAccount(false);

    console.log("selectedOption", selectedOption);
    // Map the bank account data correctly
    setFeePackTemplateFormData((prevState) => ({
      ...prevState,
      bank_name: selectedOption?.data?.bank_name || "",
      bank_account_name: selectedOption?.data?.account_name || "",
      bank_account_number: selectedOption?.data?.account_mask || "",
      bank_routing_number: selectedOption?.data?.routing_number || "",
      bank_id: selectedOption?.data?.bank_id || "",
      bank_account_id: selectedOption?.data?.bank_account_id || "",
      is_bank_verified: true, // Since this is coming from Plaid, we can assume it's verified
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    devLog("feePackTemplateFormData", feePackTemplateFormData);
    const result = await createRentalFeePack(feePackTemplateFormData);

    if (result) {
      toast.success("Rental Fee Pack Created Successfully");
      navigate("/allRentalFeePacks");
    } else {
      toast.error("Failed to Create Rental Fee Pack");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let parsedValue = value;

    // Handle numeric fields
    if (
      name === "rent_increase_percentage" ||
      (name === "charge_amount" && value !== "")
    ) {
      parsedValue = parseFloat(value) || 0;
    }

    setFeePackTemplateFormData({
      ...feePackTemplateFormData,
      [name]: parsedValue,
    });
  };

  const handleToggleChange = (e) => {
    const { name } = e.target;
    setFeePackTemplateFormData((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  const validateField = (fieldName, value) => {
    const fieldSchema = { [fieldName]: feePackSchema[fieldName] };
    const objToValidate = { [fieldName]: value };

    try {
      Joi.validate(objToValidate, fieldSchema, { abortEarly: false });
      setErrors((prev) => ({
        ...prev,
        [fieldName]: null,
      }));
    } catch (ex) {
      setErrors((prev) => ({
        ...prev,
        [fieldName]: ex.details[0].message,
      }));
    }
  };

  useEffect(() => {
    fetchBankAccounts();
    getCurrencyOptions();
  }, []);

  return (
    <React.Fragment>
      {isLoading && <LoaderView />}
      <div className={`${getThemeClasses()}`}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle={"Create Rental Fee Pack"}
            previousPageTitle=""
          />

          <div className="row">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="live-preview">
                        <div className="row gy-4">
                          <div className="col-xxl-3 col-md-6">
                            <div>
                              <Input
                                name="fee_pack_name"
                                label="Fee Pack Name"
                                value={feePackTemplateFormData.fee_pack_name}
                                onChange={handleChange}
                                error={errors.fee_pack_name}
                              />
                            </div>
                          </div>
                          <div className="col-xxl-3 col-md-6">
                            <div>
                              <MultiLineInput
                                name="fee_pack_description"
                                label="Fee Pack Description"
                                value={
                                  feePackTemplateFormData.fee_pack_description
                                }
                                onChange={handleChange}
                                error={errors.fee_pack_description}
                              />
                            </div>
                          </div>
                          {/* require_security_deposit */}
                          <div className="col-xl-1 col-md-4">
                            <div className="d-flex flex-column align-items-center">
                              <label
                                className="mb-2"
                                htmlFor="require_security_deposit"
                              >
                                Require Security Deposit
                              </label>
                              <div className="form-check form-switch">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="require_security_deposit"
                                  name="require_security_deposit"
                                  checked={
                                    feePackTemplateFormData.require_security_deposit
                                  }
                                  onChange={handleToggleChange}
                                />
                              </div>
                            </div>
                          </div>
                          {/* require_last_month_rent */}
                          <div className="col-xxl-1 col-md-4">
                            <div className="d-flex flex-column align-items-center">
                              <label
                                className="mb-2"
                                htmlFor="require_last_month_rent"
                              >
                                Require Last Month Rent
                              </label>
                              <div className="form-check form-switch">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="require_last_month_rent"
                                  name="require_last_month_rent"
                                  checked={
                                    feePackTemplateFormData.require_last_month_rent
                                  }
                                  onChange={handleToggleChange}
                                />
                              </div>
                            </div>
                          </div>
                          {/* require_pet_deposit */}
                          <div className="col-xxl-1 col-md-4">
                            <div className="d-flex flex-column align-items-center">
                              <label
                                className="mb-2"
                                htmlFor="require_pet_deposit"
                              >
                                Require Pet Deposit
                              </label>
                              <div className="form-check form-switch">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="require_pet_deposit"
                                  name="require_pet_deposit"
                                  checked={
                                    feePackTemplateFormData.require_pet_deposit
                                  }
                                  onChange={handleToggleChange}
                                />
                              </div>
                            </div>
                          </div>
                          {/* require_pet_rent */}
                          <div className="col-xxl-1 col-md-4">
                            <div className="d-flex flex-column align-items-center">
                              <label
                                className="mb-2"
                                htmlFor="require_pet_rent"
                              >
                                Require Pet Rent
                              </label>
                              <div className="form-check form-switch">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="require_pet_rent"
                                  name="require_pet_rent"
                                  checked={
                                    feePackTemplateFormData.require_pet_rent
                                  }
                                  onChange={handleToggleChange}
                                />
                              </div>
                            </div>
                          </div>
                          {/* collect_prorated_rent  (Need to make reusabled component*/}
                          <div className="col-xxl-1 col-md-4">
                            <div className="d-flex flex-column align-items-center">
                              <label
                                className="mb-2"
                                htmlFor="collect_prorated_rent"
                              >
                                Collect Prorated Rent
                              </label>
                              <div className="form-check form-switch">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="collect_prorated_rent"
                                  name="collect_prorated_rent"
                                  checked={
                                    feePackTemplateFormData.collect_prorated_rent
                                  }
                                  onChange={handleToggleChange}
                                />
                              </div>
                            </div>
                          </div>

                          {/* automatically_increase_rent */}
                          <div className="col-xxl-1 col-md-4">
                            <div className="d-flex flex-column align-items-center">
                              <label
                                className="mb-2"
                                htmlFor="automatically_increase_rent"
                              >
                                Automatically Increase Rent
                              </label>
                              <div className="form-check form-switch">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="automatically_increase_rent"
                                  name="automatically_increase_rent"
                                  checked={
                                    feePackTemplateFormData.automatically_increase_rent
                                  }
                                  onChange={handleToggleChange}
                                />
                              </div>
                            </div>
                          </div>
                          {/* rent_increase_percentage */}
                          {feePackTemplateFormData.automatically_increase_rent && (
                            <>
                              <div className="col-xxl-3 col-md-6">
                                <div>
                                  <Input
                                    name="rent_increase_percentage"
                                    label="Rent Increase Percentage"
                                    type="number"
                                    value={
                                      feePackTemplateFormData.rent_increase_percentage
                                    }
                                    onChange={handleChange}
                                    error={errors.rent_increase_percentage}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          {/* rent_increase_period  dropdown*/}
                          <div className="col-xxl-3 col-md-6">
                            <div>
                              <label htmlFor="rent_increase_period">
                                Rent Increase Period
                              </label>
                              <Select
                                name="rent_increase_period"
                                label="Rent Increase Period"
                                options={rentIncreasePeriodOptions}
                                value={selectedRentIncreasePeriodOption}
                                onChange={(option) =>
                                  setSelectedRentIncreasePeriodOption(option)
                                }
                                error={errors.rent_increase_period}
                              />
                            </div>
                          </div>
                          {/* currency_id */}
                          <div className="col-xxl-3 col-md-6">
                            <div>
                              <label htmlFor="currency_id">Currency</label>
                              <Select
                                name="currency_id"
                                options={currencyOptions}
                                value={selectedCurrencyOption}
                                onChange={handleCurrencyChange}
                              />
                            </div>
                          </div>
                          {/* rent_paid_through_gurenter*/}
                          <div className="col-xxl-1 col-md-6">
                            <div>
                              <label htmlFor="rent_paid_through_gurenter">
                                Rent Paid Through Gurenter
                              </label>
                              <div className="form-check form-switch">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="rent_paid_through_gurenter"
                                  name="rent_paid_through_gurenter"
                                  checked={
                                    feePackTemplateFormData.rent_paid_through_gurenter
                                  }
                                  onChange={handleToggleChange}
                                />
                              </div>
                            </div>
                          </div>
                          {/*if rent_paid_through_gurenter then need to enter bank details
                          TODO: automatically add bank name, account name, account number, routing number using the Plaid API
                          */}
                          {feePackTemplateFormData.rent_paid_through_gurenter && (
                            <>
                              {/* Bank Account*/}
                              <div className="col-xxl-3 col-md-6">
                                <div>
                                  <label>Bank Account</label>
                                  <CreatableSelect
                                    name="bank_id"
                                    options={bankAccountOptions}
                                    value={selectedBankAccount} // find the corresponding object in the options array
                                    onChange={handleBankAccountChange}
                                    placeholder="Select Bank Account"
                                    styles={selectCustomStyle(isDarkMode)}
                                  />
                                </div>
                              </div>
                              {manuallyAddBankAccount && (
                                <>
                                  <div className="col-xxl-3 col-md-6">
                                    <div>
                                      <Input
                                        name="bank_name"
                                        label="Bank Name"
                                        value={
                                          feePackTemplateFormData.bank_name
                                        }
                                        onChange={handleChange}
                                        error={errors.bank_name}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-xxl-3 col-md-6">
                                    <div>
                                      <Input
                                        name="bank_account_name"
                                        label="Bank Account Name"
                                        value={
                                          feePackTemplateFormData.bank_account_name
                                        }
                                        onChange={handleChange}
                                        error={errors.bank_account_name}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-xxl-3 col-md-6">
                                    <div>
                                      <Input
                                        name="bank_account_number"
                                        label="Bank Account Number"
                                        value={
                                          feePackTemplateFormData.bank_account_number
                                        }
                                        onChange={handleChange}
                                        error={errors.bank_account_number}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-xxl-3 col-md-6">
                                    <div>
                                      <Input
                                        name="bank_routing_number"
                                        label="Bank Routing Number"
                                        value={
                                          feePackTemplateFormData.bank_routing_number
                                        }
                                        onChange={handleChange}
                                        error={errors.bank_routing_number}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                          {/* require_guarantor */}
                          <div className="col-xxl-1 col-md-6">
                            <div>
                              <label htmlFor="require_guarantor">
                                Require Guarantor
                              </label>
                              <div className="form-check form-switch">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="require_guarantor"
                                  name="require_guarantor"
                                  checked={
                                    feePackTemplateFormData.require_guarantor
                                  }
                                  onChange={handleToggleChange}
                                />
                              </div>
                            </div>
                          </div>

                          {/* user_defined_charges */}
                          <div className="col-12">
                            <div>
                              <h5>User Defined Charges</h5>
                              {feePackTemplateFormData.user_defined_charges.map(
                                (charge, index) => (
                                  <div
                                    key={index}
                                    className="row mb-3 align-items-end"
                                  >
                                    <div className="col-xxl-2 col-md-2">
                                      <Input
                                        name="charge_name"
                                        label="Charge Name"
                                        value={charge.charge_name}
                                        onChange={(e) => {
                                          const updatedCharges = [
                                            ...feePackTemplateFormData.user_defined_charges,
                                          ];
                                          updatedCharges[index].charge_name =
                                            e.target.value;
                                          setFeePackTemplateFormData({
                                            ...feePackTemplateFormData,
                                            user_defined_charges:
                                              updatedCharges,
                                          });
                                        }}
                                      />
                                    </div>
                                    <div className="col-xxl-2 col-md-2">
                                      <Input
                                        name="charge_amount"
                                        label="Charge Amount"
                                        type="number"
                                        value={charge.charge_amount}
                                        onChange={(e) => {
                                          const updatedCharges = [
                                            ...feePackTemplateFormData.user_defined_charges,
                                          ];
                                          updatedCharges[index].charge_amount =
                                            parseFloat(e.target.value) || 0;
                                          setFeePackTemplateFormData({
                                            ...feePackTemplateFormData,
                                            user_defined_charges:
                                              updatedCharges,
                                          });
                                        }}
                                      />
                                    </div>
                                    <div className="col-xxl-1 col-md-1">
                                      <button
                                        type="button"
                                        className="btn btn-danger w-100"
                                        onClick={() => {
                                          const updatedCharges =
                                            feePackTemplateFormData.user_defined_charges.filter(
                                              (_, i) => i !== index
                                            );
                                          setFeePackTemplateFormData({
                                            ...feePackTemplateFormData,
                                            user_defined_charges:
                                              updatedCharges,
                                          });
                                        }}
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                )
                              )}
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                  setFeePackTemplateFormData({
                                    ...feePackTemplateFormData,
                                    user_defined_charges: [
                                      ...feePackTemplateFormData.user_defined_charges,
                                      { charge_name: "", charge_amount: 0 },
                                    ],
                                  });
                                }}
                              >
                                Add New Charge
                              </button>
                            </div>
                          </div>
                          {/* button */}
                          <div className="col-12">
                            <div className="d-flex justify-content-between">
                              <button
                                type="button"
                                className="btn btn-outline-secondary"
                                onClick={() => navigate("/allRentalFeePacks")}
                              >
                                Cancel
                              </button>
                              <button type="submit" className="btn btn-success">
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateRentalFeePack;
