import { themeColors, themeTransitions } from "../colors";

export const useThemeClasses = (isDarkMode: boolean) => {
  const getThemeClasses = (includeTransitions = true) => {
    const mode = isDarkMode ? "dark" : "light";
    const colors = [
      themeColors[mode].background,
      themeColors[mode].border,
      themeColors[mode].text,
    ].join(" ");

    const transitions = includeTransitions
      ? `${themeTransitions.default} ${themeTransitions.hover}`
      : "";

    return `${colors} ${transitions}`.trim();
  };

  return { getThemeClasses };
};
