import React from "react";
import { StripeConnectState } from "../../types/stripe-connect";

interface StatusDisplayProps {
  status: StripeConnectState;
  onRetry?: () => void;
  isDarkMode?: boolean;
}

const StatusDisplay: React.FC<StatusDisplayProps> = ({
  status,
  onRetry,
  isDarkMode = false,
}) => {
  const getStatusColor = () => {
    switch (status?.status) {
      case "active":
        return "tw-text-green-500";
      case "failed":
        return "tw-text-red-500";
      default:
        return isDarkMode ? "tw-text-gray-300" : "tw-text-gray-700";
    }
  };

  const getStatusIcon = () => {
    switch (status?.status) {
      case "active":
        return (
          <svg
            className="tw-w-6 tw-h-6 tw-text-green-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
        );
      case "failed":
        return (
          <svg
            className="tw-w-6 tw-h-6 tw-text-red-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        );
      default:
        return (
          <svg
            className="tw-w-6 tw-h-6 tw-text-yellow-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        );
    }
  };

  const getDisplayStatus = () => {
    const statusText = status?.status || "pending";
    return statusText.charAt(0).toUpperCase() + statusText.slice(1);
  };

  return (
    <div
      className={`tw-p-6 tw-rounded-lg tw-border ${
        isDarkMode
          ? "tw-border-gray-700 tw-bg-gray-800"
          : "tw-border-gray-200 tw-bg-white"
      }`}
    >
      <div className="tw-flex tw-items-center tw-gap-4 tw-mb-4">
        {getStatusIcon()}
        <h3 className={`tw-text-lg tw-font-medium ${getStatusColor()}`}>
          {getDisplayStatus()}
        </h3>
      </div>

      {status?.requirements && status.requirements.length > 0 && (
        <div className="tw-mb-4">
          <h4
            className={`tw-text-sm tw-font-medium tw-mb-2 ${
              isDarkMode ? "tw-text-gray-300" : "tw-text-gray-700"
            }`}
          >
            Required Information:
          </h4>
          <ul className="tw-list-disc tw-list-inside tw-space-y-1">
            {status.requirements.map((req, index) => (
              <li
                key={index}
                className={`tw-text-sm ${
                  isDarkMode ? "tw-text-gray-400" : "tw-text-gray-600"
                }`}
              >
                {req}
              </li>
            ))}
          </ul>
        </div>
      )}

      {status?.error && (
        <div className="tw-mb-4">
          <div className="tw-text-red-500 tw-text-sm">{status.error}</div>
          {onRetry && (
            <button
              onClick={onRetry}
              className={`
                tw-mt-2 tw-px-4 tw-py-2 tw-rounded-md tw-text-sm tw-font-medium
                ${
                  isDarkMode
                    ? "tw-bg-gray-700 hover:tw-bg-gray-600 tw-text-white"
                    : "tw-bg-gray-200 hover:tw-bg-gray-300 tw-text-gray-700"
                }
              `}
            >
              Try Again
            </button>
          )}
        </div>
      )}

      {status?.isLoading && (
        <div className="tw-flex tw-items-center tw-gap-2">
          <svg
            className="tw-animate-spin tw-h-5 tw-w-5 tw-text-blue-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="tw-opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="tw-opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <span
            className={`tw-text-sm ${
              isDarkMode ? "tw-text-gray-300" : "tw-text-gray-700"
            }`}
          >
            Loading...
          </span>
        </div>
      )}
    </div>
  );
};

export default StatusDisplay;
