import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import LoaderView from "../../../../../../reusable/loading/loaderView";
import PageTitleBox from "../../../../../../reusable/title/pageTitleBox";
import { formatDistanceToNow, set } from "date-fns";
import FilePreview from "../../../../../tickets/detail/sub/filePreview";
import { toast } from "react-toastify";

import { getBankAccountDetailsById } from "../../../../../../../services/bank/bankManager";
import { getPropertyUnitById } from "../../../../../../../services/property/retrieve/propertyRetrievalService";
import MultiImageUploader from "../../../../../../reusable/uploader/multiImageUploader";
import FilePDFImagePreview from "../../../../../tickets/detail/sub/filePdfImagePreview";
import { Modal, Badge, Button } from "react-bootstrap";
import {
  deleteRevenueAttachment,
  getUnitRevenueById,
  uploadAttachmentToUnitRevenue,
} from "../../../../../../../services/propertyUnits/revenueAndExpenses/revenue/propertyUnitRevenueManagement";
import { getRevenueCategory } from "../../../../../../../services/systemDefined/categories/revenue/sysRevenueCategoryManagement";
import { useThemeClasses } from "../../../../../../../services/utils/hooks/useThemeClasses";
//TODO: add the ability to add attachments to the revenue and delete it as well as edit the revenue then do the same for revenue
const RevenueDetail = ({ isDarkMode }) => {
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const { unitRevenueId } = useParams();
  const [propertyUnitId, setPropertyUnitId] = useState(""); //need to get it from the ticket
  const [isLoading, setIsLoading] = useState(false);
  const pageTitle = "Revenue Detail";

  //revenue transactions
  const [revenueTransaction, setRevenueTransaction] = useState({
    revenue_category_id: "",
    property_unit_id: "",
    category_id: 0,
    amount: 0,
    date: new Date(),
    is_active: true,
    is_received_from_tenant: false,
    payment_date: new Date(),
    is_capital_revenue: false,
    revnue_title: "",
    revenue_description: "",
    receipt_link: "",
    day: new Date().getDate(),
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    created_at: new Date(),
    updated_at: new Date(),
    transaction_receipt_image_uploads: [
      {
        base64_data: "",
        url: "",
        file_name: "",
        file_size: 0,
        file_size_formatted: "",
        file_type: "",
        upload_time_stamp: "",
        unique_file_name: "",
      },
    ],
  });

  const [bankAccountDetails, setBankAccountDetails] = useState({
    account_id: "",
    account_name: "",
    account_mask: "",
    account_number: null,
    account_subtype: "",
    account_type: "",
    available_balance: 0,
    bank_account_id: "",
    bank_country: null,
    bank_id: "",
    bank_name: "",
    created_at: "",
    currency_id: "",
    current_balance: 0,
    institution_id: "",
    is_default_account: false,
    logo: "",
    routing_number: "",
    routing_numbers: [],
    updated_at: "",
    url: "",
  });

  const [propertyUnit, setPropertyUnit] = useState({
    property_unit_id: "",
    property_id: "",
    unit_name: "",
    market_rent_amount: 0.0,
    number_of_bathrooms: 0,
    number_of_bedrooms: 0,
    square_footage: 0,
    is_rented: false,
    unit_image_urls: [
      {
        imageUrl: "",
      },
    ],
  });

  const [revenueCategory, setRevenueCategory] = useState({
    created_at: "",
    revenue_category: "",
    revenue_category_id: "",
    is_active: true,
    updated_at: "",
  });

  //may put in own file later
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const [errorsForEdit, setErrorsForEdit] = useState({});

  //attachment modal
  const [showAddAttachmentModal, setShowAddAttachmentModal] = useState(false);
  const handleCloseAddAttachmentModal = () => setShowAddAttachmentModal(false);
  //attachments section start
  const [base64Data, setBase64Data] = useState([]);
  //images to upload for the advertisement
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imagesToUpload, setImagesToUpload] = useState([]);
  //attachments section end
  //preview modal
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const handleClosePreviewModal = () => setShowPreviewModal(false);
  const [itemToPreview, setItemToPreview] = useState({
    file_name: "",
    file_size: 0,
    file_size_formatted: "",
    file_type: "",
    url: "",
  });

  //confirm delete of the attachment
  const [showConfirm, setShowConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState({
    file_name: "",
    file_size: 0,
    file_size_formatted: "",
    file_type: "",
    url: "",
  });

  const fetchRevenueData = async () => {
    setIsLoading(true);

    //get the revenue data
    const revenueData = await getUnitRevenueById(unitRevenueId);

    if (revenueData) {
      console.log("Revenue Data");
      console.log(revenueData);
      setRevenueTransaction(revenueData);

      //set the property unit id
      setPropertyUnitId(revenueData.property_unit_id);

      //fetch the bank account details
      fetchBankAccountDetails(revenueData.bank_account_id);

      //fetch the property unit details
      fetchPropertyUnitProfile(revenueData.property_unit_id);

      //fetch the revenue category details
      fetchRevenueCategoryDetails(revenueData.revenue_category_id);
    } else {
      //show error message
      toast.error("Error fetching expense data");
    }
    setIsLoading(false);
  };

  const fetchBankAccountDetails = async (_bank_account_id) => {
    const bankAccountDetails = await getBankAccountDetailsById(
      _bank_account_id
    );

    if (bankAccountDetails) {
      console.log("Bank Account Details");
      console.log(bankAccountDetails);

      setBankAccountDetails(bankAccountDetails);
    } else {
      //show error message
      console.error("Error fetching bank account details");
    }
  };

  //get the unit details
  const fetchPropertyUnitProfile = async (_property_unit_id) => {
    const propertyUnit = await getPropertyUnitById(_property_unit_id);

    if (propertyUnit) {
      setPropertyUnit(propertyUnit);
    }
  };

  //need to get details on the expense_category_id
  const fetchRevenueCategoryDetails = async (_revenue_category_id) => {
    //get the revenue category details
    const revenueCategoryDetails = await getRevenueCategory(
      _revenue_category_id
    );

    if (revenueCategoryDetails) {
      console.log("Revenue Category Details");
      console.log(revenueCategoryDetails);
      setRevenueCategory(revenueCategoryDetails);
    } else {
      //show error message
      console.error("Error fetching revenue category details");
    }
  };

  //attachment functions
  const handleDelete = async () => {
    // Perform the delete action here
    console.log("Item to delete " + itemToDelete.unique_file_name);

    if (!itemToDelete.unique_file_name) {
      toast.error("Attachment delete failed");
      return;
    }
    //change this
    const payload = {
      unit_revenue_id: unitRevenueId,
      unique_file_name: itemToDelete.unique_file_name,
    };

    //enable soon

    const response = await deleteRevenueAttachment(payload);

    if (response) {
      if (response.error) {
        toast.error(response.error);
        return;
      }
      toast.success("Attachment deleted successfully");
      //reload the ticket
      setIsLoading(true);
      fetchRevenueData();
    } else {
      toast.error("Attachment delete failed");
    }

    //close the modal
    setShowConfirm(false);
    handleClosePreviewModal();
  };

  //uploading attachments
  const handleAttachmentUpload = async () => {
    setIsLoading(true);
    const attachmentsToUpload = {
      unit_revenue_id: unitRevenueId,
      revenue_media_uploads: imagesToUpload,
    };

    console.log(attachmentsToUpload);

    //upload the attachments
    const response = await uploadAttachmentToUnitRevenue(attachmentsToUpload);

    if (response) {
      setIsLoading(false);
      if (response.error) {
        toast.error(response.error);
        return;
      }
      toast.success("Attachments uploaded successfully");

      //close the modal
      setShowAddAttachmentModal(false);
    }

    //clear the attachments
    setBase64Data([]);
    setSelectedFiles([]);
    setImagesToUpload([]);

    //reload the ticket
    setIsLoading(true);

    fetchRevenueData();
  };

  const cancelAttachmentUpload = () => {
    //clear the attachments
    setBase64Data([]);
    setSelectedFiles([]);
    setImagesToUpload([]);
    //close the modal
    setShowAddAttachmentModal(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchRevenueData();
  }, []);

  return (
    <React.Fragment>
      <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setItemToDelete({
                file_name: "",
                file_size: 0,
                file_size_formatted: "",
                file_type: "",
                url: "",
              });
              setShowConfirm(false);
            }}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showPreviewModal} onHide={handleClosePreviewModal}>
        <Modal.Header closeButton>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FilePDFImagePreview
            itemToPreview={itemToPreview}
            setItemToDelete={setItemToDelete}
            setShowConfirm={setShowConfirm}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowPreviewModal(false);
              setItemToPreview({
                file_name: "",
                file_size: 0,
                file_size_formatted: "",
                file_type: "",
                url: "",
              });
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAddAttachmentModal}
        onHide={() => {
          cancelAttachmentUpload();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Adding New Attachment(s)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row gy-4">
            <div className="col-xxl-12 col-md-12">
              <div>
                <label className="form-label">Images and Attachments</label>
                <MultiImageUploader
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  base64Data={base64Data}
                  setBase64Data={setBase64Data}
                  imagesToUpload={imagesToUpload}
                  setImagesToUpload={setImagesToUpload}
                  uploadBtnText="Confirm Selection"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              cancelAttachmentUpload();
            }}
          >
            Close
          </Button>
          <Button
            variant="success"
            disabled={imagesToUpload.length < 1}
            onClick={() => {
              handleAttachmentUpload();
            }}
          >
            Upload
          </Button>
        </Modal.Footer>
      </Modal>

      {isLoading && <LoaderView />}
      <div className={`${getThemeClasses()}`}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageTitle="Unit Transactions"
            previousPageLink={`/unitTransactions/${propertyUnitId}`}
          />

          <div className="row">
            <div className="col-xxl-9">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="card-title fw-semibold mb-0">
                      {revenueTransaction.revenue_title}
                    </h6>
                  </div>
                  <div className="card-body p-4">
                    <h6 className="fw-semibold text-uppercase mb-3">
                      Description
                    </h6>
                    <p className="text-muted">
                      {revenueTransaction.revenue_description}
                    </p>
                  </div>
                  {/*end card-body*/}
                </div>
                {/*end card*/}
              </div>
            </div>
            {/*end col*/}
            <div className="col-xxl-3">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="card-title fw-semibold mb-0">
                      Revenue Details
                    </h6>

                    <Link
                      hidden
                      to={`/editRevenueDetail/${revenueTransaction.unit_revenue_id}`}
                      className="btn btn-info btn-sm mt-2"
                    >
                      <i className="ri-pencil-line"></i>
                      Edit
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive table-card">
                    <table className="table table-borderless align-middle mb-0">
                      <tbody>
                        <tr>
                          <td className="fw-medium">Bank</td>
                          <td>
                            <span id="t-no">
                              {revenueTransaction.bank_name}
                            </span>{" "}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-medium">Account</td>
                          <td id="t-client">
                            {bankAccountDetails.account_name}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-medium">Unit</td>
                          <td>{propertyUnit.unit_name}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">Category</td>
                          <td>{revenueCategory.revenue_category}</td>
                        </tr>
                        {/* revenue_date*/}
                        <tr>
                          <td className="fw-medium">Revenue Date</td>
                          <td id="t-date">
                            {new Date(
                              revenueTransaction.revenue_date
                            ).toLocaleString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-medium">Created Date</td>
                          <td id="c-date">
                            {new Date(
                              revenueTransaction.created_at
                            ).toLocaleString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                          </td>
                        </tr>
                        <tr hidden>
                          <td className="fw-medium">Last Updated</td>
                          <td id="d-date">
                            {" "}
                            {new Date(
                              revenueTransaction.updated_at
                            ).toLocaleString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-medium">Last Activity</td>
                          <td>
                            {revenueTransaction?.updated_at &&
                              formatDistanceToNow(
                                new Date(revenueTransaction.updated_at)
                              )}{" "}
                            ago
                          </td>
                        </tr>
                        <tr hidden>
                          <td className="fw-medium">Tags</td>
                          <td className="hstack text-wrap gap-1">
                            <span className="badge bg-primary-subtle text-primary">
                              Admin
                            </span>
                            <span className="badge bg-primary-subtle text-primary">
                              UI
                            </span>
                            <span className="badge bg-primary-subtle text-primary">
                              Dashboard
                            </span>
                            <span className="badge bg-primary-subtle text-primary">
                              Design
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/*end card-body*/}
              </div>
              {/*end card*/}
              {/* All the attachments in the ticket shows here to download but the image or video appear in the comments*/}
              <div className="card">
                <div className="card-header">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="card-title fw-semibold mb-0">
                      File Attachments
                    </h6>

                    <Link
                      className="btn btn-info btn-sm mt-2"
                      data-bs-toggle="modal"
                      data-bs-target="#attachFileModal"
                      onClick={() => {
                        setShowAddAttachmentModal(true);
                      }}
                    >
                      <i className="ri-attachment-line"></i>
                      Add{" "}
                      {/*This will just be linked to the ticket but doesnt show */}
                    </Link>
                  </div>
                </div>
                <div
                  className="card-body"
                  style={{ maxHeight: "230px", overflowY: "auto" }}
                >
                  {revenueTransaction.transaction_receipt_image_uploads &&
                    revenueTransaction.transaction_receipt_image_uploads
                      .length > 0 &&
                    revenueTransaction.transaction_receipt_image_uploads.map(
                      (attachment, index) => (
                        <div
                          key={index}
                          className="d-flex align-items-center border border-dashed p-2 rounded mb-2"
                        >
                          <FilePreview
                            file_name={attachment.file_name}
                            file_type={attachment.file_type}
                            url={attachment.url}
                          />

                          <div className="flex-grow-1 ms-3">
                            <h6 className="mb-1">
                              <Link
                                onClick={() => {
                                  setItemToPreview(attachment);
                                  setShowPreviewModal(true);
                                }}
                              >
                                {attachment.file_name
                                  ? attachment.file_name.length > 35
                                    ? `${attachment.file_name.substring(
                                        0,
                                        35
                                      )}...`
                                    : attachment.file_name
                                  : "No File Name"}
                              </Link>
                            </h6>
                            <small className="text-muted">
                              {attachment.file_size
                                ? attachment.file_size_formatted
                                : "No Size"}
                            </small>
                          </div>
                          <div className="hstack gap-3 fs-16">
                            {/*TODO: next and the click on image (Modal) View Button */}
                            <Link
                              onClick={() => {
                                setItemToPreview(attachment);
                                setShowPreviewModal(true);
                              }}
                              className="text-muted"
                            >
                              <i className="ri-eye-line"></i>
                            </Link>
                            {/*Download Button */}
                            <a
                              href={attachment.url}
                              download={attachment.file_name}
                              target="_blank"
                              className="text-muted"
                            >
                              <i className="ri-download-2-line"></i>
                            </a>
                            {/*Delete Button */}
                            <Link
                              onClick={() => {
                                setItemToDelete(attachment);
                                setShowConfirm(true);
                              }}
                              className="text-muted"
                            >
                              <i className="ri-delete-bin-line"></i>
                            </Link>
                          </div>
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
            {/*end col*/}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RevenueDetail;
