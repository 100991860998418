import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PaymentReceipt,
  PaymentReceiptFilter,
  PaymentMethod,
  PaymentStatus,
} from "../../../types/payment/payment-receipt";
import { searchPaymentReceipts } from "../../../services/payment/paymentReceiptService";
import { formatCurrency } from "../../../services/utils/currencyUtils";
import { devLog } from "../../../services/utils/logger";
import { useThemeClasses } from "../../../services/utils/hooks/useThemeClasses";
import ShimmerEffect from "../../reusable/loading/ShimmerEffect";
import PageTitleBox from "../../reusable/title/pageTitleBox";
import {
  DataGrid,
  GridColDef,
  GridValueFormatter,
  GridValueGetter,
  GridRenderCellParams,
  GridFilterModel,
} from "@mui/x-data-grid";
import {
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { formatDate } from "services/utils/dateUtils";
import CloseIcon from "@mui/icons-material/Close";
import { createTheme, ThemeProvider, Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
//TODO: need to copy this on the tenant portal for them to see their receipts

interface PaymentReceiptsListProps {
  isDarkMode?: boolean;
}

const PaymentReceiptsList: React.FC<PaymentReceiptsListProps> = ({
  isDarkMode = false,
}) => {
  const navigate = useNavigate();
  const { propertyUnitId } = useParams<{ propertyUnitId: string }>();
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const [isLoading, setIsLoading] = useState(true);
  const [allReceipts, setAllReceipts] = useState<PaymentReceipt[]>([]);
  const [filteredReceipts, setFilteredReceipts] = useState<PaymentReceipt[]>(
    []
  );
  const [filters, setFilters] = useState<PaymentReceiptFilter>({
    propertyUnitId,
  });
  const [selectedPdfUrl, setSelectedPdfUrl] = useState<string | null>(null);

  const columns: GridColDef[] = [
    {
      field: "receipt_number",
      headerName: "Receipt #",
      width: 200,
      renderCell: (params) => (
        <strong
          className="text-primary cursor-pointer"
          onClick={() => handleReceiptClick(params.row.receipt_id)}
        >
          {params.value}
        </strong>
      ),
    },
    {
      field: "receipt_date",
      headerName: "Date",
      width: 120,
      renderCell: (params) => <strong>{formatDate(params.value)}</strong>,
    },
    {
      field: "tenant_id",
      headerName: "Tenant",
      width: 250,
      renderCell: (params: GridRenderCellParams<PaymentReceipt>) => (
        <div>
          <div>{params.row.tenant_name || "Unknown"}</div>
          <div className="text-muted small">{params.row.tenant_id}</div>
        </div>
      ),
    },
    {
      field: "total_amount",
      headerName: "Amount",
      width: 120,
      renderCell: (params) => (
        <strong>{formatCurrency(params.value, params.row?.currency)}</strong>
      ),
    },
    {
      field: "payment_status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        const getStatusClass = (status: PaymentStatus): string => {
          switch (status) {
            case PaymentStatus.COMPLETED:
              return "completed-status";
            case PaymentStatus.PENDING:
              return "pending-status";
            case PaymentStatus.FAILED:
              return "failed-status";
            case PaymentStatus.VOIDED:
              return "voided-status";
            default:
              return "";
          }
        };

        return (
          <span
            className={`badge rounded-pill ${getStatusClass(params.value)}`}
          >
            {params.value}
          </span>
        );
      },
    },
    {
      field: "payment_method",
      headerName: "Method",
      width: 120,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <div className="d-flex gap-2 align-items-center h-100">
          <button
            className="btn btn-sm btn-primary"
            onClick={() => handleReceiptClick(params.row.receipt_id)}
          >
            View
          </button>
          {params.row.pdf_url && (
            <button
              className="btn btn-sm btn-secondary"
              onClick={() => handleOpenPdf(params.row.pdf_url)}
            >
              PDF
            </button>
          )}
        </div>
      ),
    },
  ];

  const getStatusBadgeClass = (status: PaymentStatus): string => {
    switch (status) {
      case PaymentStatus.COMPLETED:
        return "bg-success";
      case PaymentStatus.PENDING:
        return "bg-warning";
      case PaymentStatus.FAILED:
        return "bg-danger";
      case PaymentStatus.VOIDED:
        return "bg-secondary";
      default:
        return "bg-primary";
    }
  };

  const fetchReceipts = async () => {
    try {
      setIsLoading(true);
      const fetchedReceipts = await searchPaymentReceipts({ propertyUnitId });
      setAllReceipts(fetchedReceipts);
      setFilteredReceipts(fetchedReceipts);
      devLog("Fetched receipts:", fetchedReceipts);
    } catch (error) {
      devLog("Error fetching receipts:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReceipts();
  }, [propertyUnitId]);

  useEffect(() => {
    let filtered = [...allReceipts];

    // Apply date filters
    if (filters.startDate) {
      filtered = filtered.filter(
        (receipt) =>
          new Date(receipt.receipt_date) >= new Date(filters.startDate!)
      );
    }
    if (filters.endDate) {
      filtered = filtered.filter(
        (receipt) =>
          new Date(receipt.receipt_date) <= new Date(filters.endDate!)
      );
    }

    // Apply payment status filter
    if (filters.paymentStatus) {
      filtered = filtered.filter(
        (receipt) => receipt.payment_status === filters.paymentStatus
      );
    }

    // Apply payment method filter
    if (filters.paymentMethod) {
      filtered = filtered.filter(
        (receipt) => receipt.payment_method === filters.paymentMethod
      );
    }

    setFilteredReceipts(filtered);
  }, [filters, allReceipts]);

  const handleReceiptClick = (receiptId: string) => {
    navigate(`/paymentReceipts/${propertyUnitId}/view/${receiptId}`);
  };

  const handleFilterChange = (name: keyof PaymentReceiptFilter, value: any) => {
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClosePdfModal = () => {
    setSelectedPdfUrl(null);
  };

  const handleOpenPdf = (pdfUrl: string | undefined) => {
    if (pdfUrl) {
      setSelectedPdfUrl(pdfUrl);
    }
  };

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? "dark" : "light",
      background: {
        default: isDarkMode ? "#1a1a1a" : "#ffffff",
        paper: isDarkMode ? "#2d2d2d" : "#ffffff",
      },
      text: {
        primary: isDarkMode ? "#ffffff" : "#000000",
        secondary: isDarkMode
          ? "rgba(255, 255, 255, 0.7)"
          : "rgba(0, 0, 0, 0.6)",
      },
    },
  });

  const tableStyles: SxProps<Theme> = {
    border: "none",
    backgroundColor: "transparent",
    "& .MuiDataGrid-cell": {
      borderColor: isDarkMode
        ? "rgba(255, 255, 255, 0.1)"
        : "rgba(0, 0, 0, 0.1)",
      color: theme.palette.text.primary,
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: isDarkMode
        ? "rgba(255, 255, 255, 0.05)"
        : "rgba(0, 0, 0, 0.02)",
      borderColor: isDarkMode
        ? "rgba(255, 255, 255, 0.1)"
        : "rgba(0, 0, 0, 0.1)",
      color: theme.palette.text.primary,
    },
    "& .MuiDataGrid-row": {
      "&:hover": {
        backgroundColor: isDarkMode
          ? "rgba(255, 255, 255, 0.04)"
          : "rgba(0, 0, 0, 0.04)",
      },
    },
    "& .completed-status": {
      backgroundColor: isDarkMode
        ? "rgba(46, 125, 50, 0.5)"
        : "rgba(46, 125, 50, 0.1)",
      color: isDarkMode ? "#98ee99" : "#1b5e20",
    },
    "& .pending-status": {
      backgroundColor: isDarkMode
        ? "rgba(237, 108, 2, 0.5)"
        : "rgba(237, 108, 2, 0.1)",
      color: isDarkMode ? "#ffbd45" : "#e65100",
    },
    "& .failed-status": {
      backgroundColor: isDarkMode
        ? "rgba(211, 47, 47, 0.5)"
        : "rgba(211, 47, 47, 0.1)",
      color: isDarkMode ? "#ff8a80" : "#c62828",
    },
    "& .voided-status": {
      backgroundColor: isDarkMode
        ? "rgba(97, 97, 97, 0.5)"
        : "rgba(97, 97, 97, 0.1)",
      color: isDarkMode ? "#e0e0e0" : "#424242",
    },
    "& .MuiDataGrid-footerContainer": {
      borderColor: isDarkMode
        ? "rgba(255, 255, 255, 0.1)"
        : "rgba(0, 0, 0, 0.1)",
    },
    "& .MuiTablePagination-root": {
      color: theme.palette.text.primary,
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={getThemeClasses()}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle="Payment Receipts"
            previousPageTitle="Property Unit"
            previousPageLink={
              propertyUnitId ? `/propertyunit/${propertyUnitId}` : `/dashboard`
            }
          />

          <Card
            className="shadow-sm"
            sx={{
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
              "& .text-muted": {
                color: `${theme.palette.text.secondary} !important`,
              },
            }}
          >
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <div></div>
                <Button
                  variant="contained"
                  color={isDarkMode ? "inherit" : "primary"}
                  onClick={() =>
                    navigate(`/paymentReceipts/create/${propertyUnitId}`)
                  }
                  sx={{
                    backgroundColor: isDarkMode
                      ? "rgba(255, 255, 255, 0.1)"
                      : undefined,
                    "&:hover": {
                      backgroundColor: isDarkMode
                        ? "rgba(255, 255, 255, 0.2)"
                        : undefined,
                    },
                  }}
                >
                  Create Receipt
                </Button>
              </div>

              <div className="row mb-4">
                <div className="col-md-3">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Start Date"
                      value={
                        filters.startDate ? new Date(filters.startDate) : null
                      }
                      onChange={(date) =>
                        handleFilterChange(
                          "startDate",
                          date?.toISOString().split("T")[0]
                        )
                      }
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          sx: { marginBottom: 1 },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <div className="col-md-3">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="End Date"
                      value={filters.endDate ? new Date(filters.endDate) : null}
                      onChange={(date) =>
                        handleFilterChange(
                          "endDate",
                          date?.toISOString().split("T")[0]
                        )
                      }
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          sx: { marginBottom: 1 },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <div className="col-md-3">
                  <FormControl fullWidth size="small">
                    <InputLabel>Payment Status</InputLabel>
                    <Select
                      value={filters.paymentStatus || ""}
                      onChange={(e) =>
                        handleFilterChange("paymentStatus", e.target.value)
                      }
                    >
                      <MenuItem value="">All</MenuItem>
                      {Object.values(PaymentStatus).map((status) => (
                        <MenuItem key={status} value={status}>
                          {status}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-md-3">
                  <FormControl fullWidth size="small">
                    <InputLabel>Payment Method</InputLabel>
                    <Select
                      value={filters.paymentMethod || ""}
                      onChange={(e) =>
                        handleFilterChange("paymentMethod", e.target.value)
                      }
                    >
                      <MenuItem value="">All</MenuItem>
                      {Object.values(PaymentMethod).map((method) => (
                        <MenuItem key={method} value={method}>
                          {method}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>

              {isLoading ? (
                <div className="row">
                  {[...Array(5)].map((_, index) => (
                    <div key={index} className="col-12 mb-3">
                      <ShimmerEffect
                        type="list-item"
                        height="60px"
                        width="100%"
                        darkMode={isDarkMode}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <DataGrid
                  rows={filteredReceipts}
                  columns={columns}
                  getRowId={(row) => row.receipt_id}
                  autoHeight
                  sx={tableStyles}
                  pagination
                  pageSizeOptions={[5, 10, 25, 50]}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                />
              )}
            </div>
          </Card>
        </div>
      </div>
      <Dialog
        open={!!selectedPdfUrl}
        onClose={handleClosePdfModal}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent
          className="p-0 position-relative"
          style={{ height: "80vh" }}
        >
          <IconButton
            onClick={handleClosePdfModal}
            className="position-absolute top-0 end-0 m-2 z-10"
            style={{ backgroundColor: "rgba(255,255,255,0.8)" }}
          >
            <CloseIcon />
          </IconButton>
          {selectedPdfUrl && (
            <iframe
              src={selectedPdfUrl}
              style={{ width: "100%", height: "100%", border: "none" }}
              title="Receipt PDF"
            />
          )}
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default PaymentReceiptsList;
