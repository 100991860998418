import React, { useEffect, useState } from "react";
import InputTS from "../../reusable/form/InputTS";
import { UserProfile, defaultUserProfile } from "../../../types/UserProfile";
import { getCurrentUserProfileByEmail } from "../../../services/authentication/auth";
import Joi from "joi-browser";
import { useStripeConnect } from "../../../hooks/useStripeConnect";
import KYCBanner from "../../stripe-connect/KYCBanner";
import StatusDisplay from "../../stripe-connect/StatusDisplay";
import config from "../../../constants/config.json";
import LoaderView from "components/reusable/loading/loaderView";

interface UserProfileFormProps {
  isDarkMode: boolean;
  onSubmit: (formData: Partial<UserProfile>) => void;
}

// Define validation schema type to match form fields
type ValidationSchema = {
  [K in keyof Omit<UserProfile, "user_id">]: Joi.Schema;
};

const UserProfileForm: React.FC<UserProfileFormProps> = ({
  isDarkMode,
  onSubmit,
}) => {
  // Get both IDs from localStorage
  const userId = localStorage.getItem(config.user_id) || "";
  const stripeAccountId =
    localStorage.getItem(config.stripe_connect_account_id) || "";

  const [formData, setFormData] = useState<UserProfile>({
    ...defaultUserProfile,
    user_id: userId, // Use regular user ID for form data
  });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Use Stripe Connect Account ID for Stripe integration
  const { state: stripeState, checkStatus } = useStripeConnect(stripeAccountId);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userData = await getCurrentUserProfileByEmail();
        setFormData((prevData) => ({
          ...prevData,
          ...userData,
          user_id: userId, // Use regular user ID
        }));
      } catch (error) {
        console.error("Error fetching user profile:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserProfile();
  }, [userId]);

  useEffect(() => {
    checkStatus();
  }, [checkStatus]);

  const schema: ValidationSchema = {
    phone_number: Joi.string().allow(""),
    address_line1: Joi.string().allow(""),
    address_line2: Joi.string().allow(""),
    city: Joi.string().allow(""),
    state: Joi.string().allow(""),
    postal_code: Joi.string().allow(""),
    country: Joi.string().allow(""),
    business_name: Joi.string().allow(""),
    business_tax_id: Joi.string().allow(""),
    business_website: Joi.string().allow(""),
    dob_day: Joi.string().allow(""),
    dob_month: Joi.string().allow(""),
    dob_year: Joi.string().allow(""),
    ssn_last_4: Joi.string().allow(""),
    bank_account_holder_name: Joi.string().allow(""),
    bank_account_holder_type: Joi.string().valid("", "individual", "company"),
    bank_routing_number: Joi.string().allow(""),
    bank_account_number: Joi.string().allow(""),
  };

  const validateProperty = (
    name: keyof Omit<UserProfile, "user_id">,
    value: string
  ) => {
    const obj = { [name]: value };
    const propertySchema = { [name]: schema[name] };
    const { error } = Joi.validate(obj, propertySchema);
    return error ? error.details[0].message : null;
  };

  const handleChange = (
    input:
      | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
      | { name: string; value: string }
  ) => {
    const name = "target" in input ? input.target.name : input.name;
    if (name === "user_id") return; // Prevent user_id from being changed

    const value = "target" in input ? input.target.value : input.value;

    const errorMessage = validateProperty(
      name as keyof Omit<UserProfile, "user_id">,
      value
    );
    const newErrors = { ...errors };
    if (errorMessage) newErrors[name] = errorMessage;
    else delete newErrors[name];

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors(newErrors);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const changedFields = Object.entries(formData).reduce(
      (acc, [key, value]) => {
        if (value !== defaultUserProfile[key as keyof UserProfile]) {
          acc[key as keyof UserProfile] = value;
        }
        return acc;
      },
      {} as Partial<UserProfile>
    );

    onSubmit(changedFields);
  };

  if (isLoading) {
    return <LoaderView />;
  }

  return (
    <form onSubmit={handleSubmit} className="tw-max-w-5xl tw-mx-auto">
      {/* Add KYC Banner at the top */}
      {stripeState.status !== "active" && (
        <div className="tw-mb-8">
          <KYCBanner
            userId={userId}
            stripeAccountId={stripeAccountId}
            isDarkMode={isDarkMode}
            state={stripeState}
            onComplete={checkStatus}
          />
        </div>
      )}

      {/* Add KYC Status Section */}
      <div className="tw-mb-8">
        <h4
          className={`tw-text-lg tw-font-medium tw-mb-6 tw-border-b ${
            isDarkMode
              ? "tw-border-gray-700 tw-text-white"
              : "tw-border-gray-200 tw-text-gray-900"
          } tw-pb-2`}
        >
          Account Verification Status
        </h4>
        <StatusDisplay
          status={stripeState}
          onRetry={checkStatus}
          isDarkMode={isDarkMode}
        />
      </div>

      {/* Personal Information Section */}
      <div className="tw-mb-8">
        <h4
          className={`tw-text-lg tw-font-medium tw-mb-6 tw-border-b ${
            isDarkMode
              ? "tw-border-gray-700 tw-text-white"
              : "tw-border-gray-200 tw-text-gray-900"
          } tw-pb-2`}
        >
          Personal Information
        </h4>
        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6">
          <div>
            <InputTS
              name="phone_number"
              label="Phone Number"
              type="tel"
              value={formData.phone_number}
              onChange={handleChange}
              error={errors.phone_number}
            />
          </div>
        </div>
      </div>

      {/* Address Section */}
      <div className="tw-mb-8">
        <h4
          className={`tw-text-lg tw-font-medium tw-mb-6 tw-border-b ${
            isDarkMode
              ? "tw-border-gray-700 tw-text-white"
              : "tw-border-gray-200 tw-text-gray-900"
          } tw-pb-2`}
        >
          Address
        </h4>
        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6">
          <div className="md:tw-col-span-2">
            <InputTS
              name="address_line1"
              label="Address Line 1"
              type="text"
              value={formData.address_line1}
              onChange={handleChange}
              error={errors.address_line1}
            />
          </div>
          <div className="md:tw-col-span-2">
            <InputTS
              name="address_line2"
              label="Address Line 2"
              type="text"
              value={formData.address_line2}
              onChange={handleChange}
              error={errors.address_line2}
            />
          </div>
          <div>
            <InputTS
              name="city"
              label="City"
              type="text"
              value={formData.city}
              onChange={handleChange}
              error={errors.city}
            />
          </div>
          <div>
            <InputTS
              name="state"
              label="State"
              type="text"
              value={formData.state}
              onChange={handleChange}
              error={errors.state}
            />
          </div>
          <div>
            <InputTS
              name="postal_code"
              label="Postal Code"
              type="text"
              value={formData.postal_code}
              onChange={handleChange}
              error={errors.postal_code}
            />
          </div>
          <div>
            <InputTS
              name="country"
              label="Country"
              type="text"
              value={formData.country}
              onChange={handleChange}
              error={errors.country}
            />
          </div>
        </div>
      </div>

      {/* Business Information Section */}
      <div className="tw-mb-8">
        <h4
          className={`tw-text-lg tw-font-medium tw-mb-6 tw-border-b ${
            isDarkMode
              ? "tw-border-gray-700 tw-text-white"
              : "tw-border-gray-200 tw-text-gray-900"
          } tw-pb-2`}
        >
          Business Information
        </h4>
        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6">
          <div>
            <InputTS
              name="business_name"
              label="Business Name"
              type="text"
              value={formData.business_name}
              onChange={handleChange}
              error={errors.business_name}
            />
          </div>
          <div>
            <InputTS
              name="business_tax_id"
              label="Business Tax ID"
              type="text"
              value={formData.business_tax_id}
              onChange={handleChange}
              error={errors.business_tax_id}
            />
          </div>
          <div className="md:tw-col-span-2">
            <InputTS
              name="business_website"
              label="Business Website"
              type="url"
              value={formData.business_website}
              onChange={handleChange}
              error={errors.business_website}
            />
          </div>
        </div>
      </div>

      {/* Identity Verification Section */}
      <div className="tw-mb-8">
        <h4
          className={`tw-text-lg tw-font-medium tw-mb-6 tw-border-b ${
            isDarkMode
              ? "tw-border-gray-700 tw-text-white"
              : "tw-border-gray-200 tw-text-gray-900"
          } tw-pb-2`}
        >
          Identity Verification
        </h4>
        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-6">
          <div>
            <InputTS
              name="dob_month"
              label="Month of Birth"
              type="number"
              min="1"
              max="12"
              value={formData.dob_month}
              onChange={handleChange}
              error={errors.dob_month}
            />
          </div>
          <div>
            <InputTS
              name="dob_day"
              label="Day of Birth"
              type="number"
              min="1"
              max="31"
              value={formData.dob_day}
              onChange={handleChange}
              error={errors.dob_day}
            />
          </div>
          <div>
            <InputTS
              name="dob_year"
              label="Year of Birth"
              type="number"
              min="1900"
              max={new Date().getFullYear()}
              value={formData.dob_year}
              onChange={handleChange}
              error={errors.dob_year}
            />
          </div>
          <div>
            <InputTS
              name="ssn_last_4"
              label="Last 4 digits of SSN"
              type="password"
              value={formData.ssn_last_4}
              onChange={handleChange}
              error={errors.ssn_last_4}
            />
          </div>
        </div>
      </div>

      {/* Bank Account Information Section */}
      <div className="tw-mb-8">
        <h4
          className={`tw-text-lg tw-font-medium tw-mb-6 tw-border-b ${
            isDarkMode
              ? "tw-border-gray-700 tw-text-white"
              : "tw-border-gray-200 tw-text-gray-900"
          } tw-pb-2`}
        >
          Bank Account Information
        </h4>
        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6">
          <div>
            <InputTS
              name="bank_account_holder_name"
              label="Account Holder Name"
              type="text"
              value={formData.bank_account_holder_name}
              onChange={handleChange}
              error={errors.bank_account_holder_name}
            />
          </div>
          <div>
            <label
              className={`tw-block tw-text-sm tw-font-medium ${
                isDarkMode ? "tw-text-gray-300" : "tw-text-gray-700"
              } tw-mb-1`}
            >
              Account Holder Type
            </label>
            <select
              name="bank_account_holder_type"
              value={formData.bank_account_holder_type}
              onChange={(e) => handleChange(e)}
              className={`tw-block tw-w-full tw-rounded-md tw-border tw-border-gray-300 tw-py-2 tw-px-3 tw-shadow-sm focus:tw-border-primary focus:tw-ring focus:tw-ring-primary focus:tw-ring-opacity-50 sm:tw-text-sm ${
                isDarkMode
                  ? "tw-bg-gray-700 tw-text-white tw-border-gray-600"
                  : "tw-bg-white tw-text-gray-900"
              }`}
            >
              <option value="">Select Type</option>
              <option value="individual">Individual</option>
              <option value="company">Company</option>
            </select>
            {errors.bank_account_holder_type && (
              <div className="tw-text-red-500 tw-text-sm tw-mt-1">
                {errors.bank_account_holder_type}
              </div>
            )}
          </div>
          <div>
            <InputTS
              name="bank_routing_number"
              label="Routing Number"
              type="password"
              value={formData.bank_routing_number}
              onChange={handleChange}
              error={errors.bank_routing_number}
            />
          </div>
          <div>
            <InputTS
              name="bank_account_number"
              label="Account Number"
              type="password"
              value={formData.bank_account_number}
              onChange={handleChange}
              error={errors.bank_account_number}
            />
          </div>
        </div>
      </div>

      <div className="tw-mt-8 tw-flex tw-justify-end tw-space-x-3 tw-border-t tw-pt-6">
        <button
          type="submit"
          className="tw-px-4 tw-py-2 tw-bg-primary tw-text-white tw-rounded-md tw-text-sm tw-font-medium hover:tw-bg-primary-dark"
        >
          Save Changes
        </button>
      </div>
    </form>
  );
};

export default UserProfileForm;
