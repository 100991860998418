import React, { useEffect, useState } from "react";
import Joi from "joi-browser";
import Select from "react-select";
import Moment from "react-moment";
import { Link, useParams } from "react-router-dom";
import { getPropertyUnitById } from "../../../../services/property/retrieve/propertyRetrievalService";
//import loader from "../../../../styletheme/assets/img/svgicons/loader.svg";
import HeaderLabelBreadCrumb from "../../../reusable/label/headerLabelBreadCrumb";
import LoaderView from "../../../reusable/loading/loaderView";
import PageTitleBox from "../../../reusable/title/pageTitleBox";
import default_img from "../../../../styletheme/images/buildings/house.png";
import default_img1 from "../../../../styletheme/images/small/img-1.jpg";
import default_img2 from "../../../../styletheme/images/small/img-2.jpg";
import default_img3 from "../../../../styletheme/images/small/img-3.jpg";
import { fetchAllCurrencies } from "../../../../services/systemDefined/currency/sysCurrencyManagement";
import Carousel from "react-bootstrap/Carousel";
//temporarily using default images
import default_avatar_img from "../../../../styletheme/images/users/avatar-4.jpg";
import default_avatar_img1 from "../../../../styletheme/images/users/avatar-5.jpg";
import default_avatar_img2 from "../../../../styletheme/images/users/avatar-6.jpg";
import default_avatar_img3 from "../../../../styletheme/images/users/avatar-7.jpg";
import default_avatar_img4 from "../../../../styletheme/images/users/avatar-8.jpg";
import avatar_1 from "../../../../styletheme/images/users/avatar-1.jpg";
import avatar_2 from "../../../../styletheme/images/users/avatar-2.jpg";
import { Modal, Badge, Button } from "react-bootstrap";
import { fetchAllStatuses } from "../../../../services/systemDefined/status/sysStatusManagemetn";
import { fetchAllPriorities } from "../../../../services/systemDefined/priority/sysPriorityManagement";
import {
  addTasksToTaskGroup,
  completeTask,
  createUserTasks,
  getPropertyUnitTasks,
} from "../../../../services/task/userTaskManagement";
//animation
import Lottie from "react-lottie";
import empty_list_animation from "../../../reusable/animations/lottie/empty_people_list.json";
import Input from "../../../reusable/form/Input";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import config from "../../../../constants/config.json";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";
import {
  createPropertyUnitAudit,
  getAuditByPropertyUnitIdForLast7Days,
} from "../../../../services/propertyUnits/audit/propertyUnitAuditManagement";
import {
  createTaskReminder,
  getReminderByUserIdAndPropertyUnitId,
  getReminderManagerFromServer,
  getTaskRemindersByReminderIdAndUserId,
  getTaskRemindersByTaskReminderId,
} from "../../../../services/reminder/reminderManagement";
import ShimmerEffect from "../../../reusable/loading/ShimmerEffect";
import { selectCustomStyle } from "../../../../styletheme/custom/selectStyle";
import { useThemeClasses } from "services/utils/hooks/useThemeClasses";
//TODO: Expenses page with the ability to add transactions and also import transactions (Plaid API integration)
//can bulk categorize transactions or go with what was given
//Form to add a transaction (Income (Revenue) and Expense)
//force sync button to import transactions (revenue and expenses) from the bank
//should have pagination and search and filters
const PropertyUnitDetail = ({ isDarkMode }) => {
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  /*
    Property unit detail at the top
    the tenants in the unit if rented
    the expenses for this unit
    generate receipt
    */
  const [isLoading, setIsLoading] = useState();
  const { propertyUnitId } = useParams();
  const pageTitle = "Property Unit Detail";

  const [propertyId, setPropertyId] = useState("");

  //TODO: need to enable adding images to the property unit
  const [propertyUnit, setPropertyUnit] = useState({
    property_unit_id: "",
    property_id: "",
    unit_name: "",
    market_rent_amount: 0.0,
    number_of_bathrooms: 0,
    number_of_bedrooms: 0,
    square_footage: 0,
    is_rented: false,
    property_unit_image_urls: [
      {
        base64_data: "",
        file_name: "",
        unique_file_name: "",
        file_size: 0,
        file_size_formatted: "",
        file_type: "",
        upload_time_stamp: Date.now(),
        url: "",
      },
    ],
  });

  //currencyOptions
  const [currencyOptions, setCurrencyOptions] = useState([
    { value: "", label: "", data: {} },
  ]);
  const [selectedCurrencyOption, setSelectedCurrencyOption] = useState(null);

  //system defined status options
  const [statusOptions, setStatusOptions] = useState([
    { value: "", label: "", data: {} },
  ]);
  const [selectedStatusOption, setSelectedStatusOption] = useState(null);

  const getSystemDefinedStatusOptions = async () => {
    //set statusOptions
    const response = await fetchAllStatuses();
    //set statusOptions
    const options = response.map((status) => ({
      value: status.status_id,
      label: status.status,
      data: status,
    }));
    setStatusOptions(options);

    console.log(options);

    // Set selectedStatusOption to the option where status is "New"
    const newStatusOption = options.find(
      (option) => option.data.status === "New"
    );
    if (newStatusOption) {
      setSelectedStatusOption(newStatusOption);
    }
  };

  const handleStatusChange = (selectedOption) => {
    setSelectedStatusOption(selectedOption);
  };
  //priority
  const [priorityOptions, setPriorityOptions] = useState([
    { value: "", label: "", data: {} },
  ]);
  const [selectedPriorityOption, setSelectedPriorityOption] = useState(null);
  const handlePriorityChange = (selectedOption) => {
    setSelectedPriorityOption(selectedOption);
  };

  const getSystemDefinedPriorityOptions = async () => {
    //set priorityOptions
    const response = await fetchAllPriorities();
    //set priorityOptions
    const options = response.map((priority) => ({
      value: priority.priority_id,
      label: priority.priority,
      data: priority,
    }));
    setPriorityOptions(options);

    console.log(options);

    // Set selectedPriorityOption to the option where priority is "High"
    const highPriorityOption = options.find(
      (option) => option.data.priority === "Medium"
    );
    if (highPriorityOption) {
      setSelectedPriorityOption(highPriorityOption);
    }
  };

  //currency
  const getCurrencyOptions = async () => {
    //set currencyOptions
    const response = await fetchAllCurrencies();
    //set currencyOptions
    const options = response.map((currency) => ({
      value: currency.currency_id,
      label: currency.currency_name,
      data: currency,
    }));
    setCurrencyOptions(options);

    // Set selectedCurrencyOption to the option where currency_code is "USD"
    const usdOption = options.find(
      (option) => option.data.currency_code === "USD"
    );
    if (usdOption) {
      setSelectedCurrencyOption(usdOption);
    }
  };

  //add task to the current task group , if no tasks exist for the property unit, create a new task group and tasks
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => {
    eraseTaskForm();
    setShowModal(false);
  };
  const [errorsForAdd, setErrorsForAdd] = useState({});

  //unit tasks
  const [unitTasks, setUnitTasks] = useState([
    {
      task_group_id: "",
      property_unit_id: "",
      created_by: null,
      created_at: "",
      updated_at: "",
      is_deleted: false,
      tasks: [
        {
          task_id: "",
          task_name: "",
          task_description: "",
          status_id: "",
          priority_id: "",
          task_due_date: "",
          is_done: false,
          created_by: "",
          task_group_id: "",
          is_deleted: false,
        },
      ],
    },
  ]);

  const [latestUnitTasks, setLatestUnitTasks] = useState({
    task_group_id: "",
    property_unit_id: "",
    created_by: null,
    created_at: "",
    updated_at: "",
    is_deleted: false,
    tasks: [
      {
        task_id: "",
        task_name: "",
        task_description: "",
        status_id: "",
        status: {
          created_at: "",
          is_active: true,
          status: "",
          status_id: "",
          updated_at: "",
        },
        priority_id: "",
        priority: {
          created_at: "",
          is_active: true,
          priority: "",
          priority_id: "",
          updated_at: "",
        },
        task_due_date: "",
        is_done: false,
        created_by: "",
        task_group_id: "",
        is_deleted: false,
      },
    ],
  });

  const emptyTaskLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: empty_list_animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const getUnitTasks = async () => {
    //set unitTasks
    const response = await getPropertyUnitTasks(propertyUnitId);
    console.log("unit tasks");
    console.log(response);
    setUnitTasks(response);

    //set the latest unit tasks
    if (response.length > 0) {
      setLatestUnitTasks(response[0]);
    }

    //TODO: add the Lottie animation next
  };

  //form data and schema
  const [taskData, setTaskData] = useState({
    task_name: "",
    task_description: "",
    status_id: "",
    priority_id: "",
    task_due_date: new Date(),
    is_done: false,
    created_by: "",
  });

  const taskSchema = {
    task_name: Joi.string().required().label("Task Name"),
    task_description: Joi.string().required().label("Task Description"),
    status_id: Joi.string().optional().allow("").label("Status"),
    priority_id: Joi.string().optional().allow("").label("Priority"),
    task_due_date: Joi.date().required().label("Due Date"),
    is_done: Joi.boolean().required().label("Is Done"),
    created_by: Joi.string().optional().allow("").label("Created By"),
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(taskData, taskSchema, options);

    const errors = {};

    if (!error && Object.keys(errors).length === 0) return null;

    // Check if error is not null before trying to access error.details
    if (error) {
      for (let item of error.details) errors[item.path[0]] = item.message;
    }

    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaLocal = { [name]: taskSchema[name] };
    const { error } = Joi.validate(obj, schemaLocal);

    return error ? error.details[0].message : null;
  };

  const [errors, setErrors] = useState({});
  //handle change task_due_date

  const handleTaskChange = (input) => {
    const currentErrors = { ...errors };
    let errorMessage;
    let data = { ...taskData };

    if (input && input.currentTarget) {
      const { name, value, checked, type } = input.currentTarget;
      errorMessage = validateProperty({ name, value });

      if (type === "checkbox") {
        data[name] = checked;
      } else {
        data[name] = value;
      }
    } else if (input && input.value) {
      errorMessage = validateProperty(input);
      data[input.name] = input.value;
    }

    if (errorMessage) {
      currentErrors[input.name] = errorMessage;
    } else {
      delete currentErrors[input.name];
    }

    setTaskData(data);
    setErrors(currentErrors);
  };
  const handleAddTask = async () => {
    const errors = validate();
    setErrorsForAdd(errors || {});

    if (errors) return;

    //alert('add task to server');
    //set the status
    const status_id = selectedStatusOption?.value || "";
    const priority_id = selectedPriorityOption?.value || "";
    const propertyUnitId = propertyUnit.property_unit_id;
    //created by will change when multi-user support is added
    const user_id = localStorage.getItem(config.user_id);
    const first_name = localStorage.getItem(config.first_name);
    const last_name = localStorage.getItem(config.last_name);
    const created_by = `${first_name} ${last_name}`;

    //set the task data
    taskData.task_id = uuidv4();
    taskData.status_id = status_id;
    taskData.priority_id = priority_id;
    taskData.created_by = created_by;

    console.log(taskData);

    console.log("Property Unit Id");
    console.log(propertyUnitId);

    //TODO: assiging users in the future
    //for now just the person who creates it is assigned
    //it is expecting a list of task objects []
    const response = await addTasksToTaskGroup(
      user_id,
      created_by,
      propertyUnitId,
      [taskData]
    );
    console.log("Task Response");
    console.log(response);

    if (response) {
      //close the modal
      handleCloseModal();

      //refresh the tasks
      getUnitTasks();

      //log the property unit audit
      logPropertyUnitAudit({
        audit_type: "Task",
        action_name: "Task Created",
        description: `Task ${taskData.task_name} has been created.`,
        icon_name: "ri-list-check-2",
        icon_color: "green",
      });

      //create a task reminder if has a due date
      if (taskData.task_due_date) {
        postTaskReminder(taskData);
      }
    } else {
      //show the error message

      toast.error("Error creating task");
    }
  };

  const [isLoadingUpcoming, setIsLoadingUpcoming] = useState(true);
  const [isLoadingTasks, setIsLoadingTasks] = useState(true);
  const [isLoadingRecent, setIsLoadingRecent] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingUpcoming(true);
        setIsLoadingTasks(true);
        setIsLoadingRecent(true);

        await getCurrentPropertyProfile();
        await getUnitTasks();
        await getCurrencyOptions();
        await getPropertyUnitAudit(propertyUnitId);
        await getReminderManager();
        await getSystemDefinedStatusOptions();
        await getSystemDefinedPriorityOptions();

        setIsLoadingUpcoming(false);
        setIsLoadingTasks(false);
        setIsLoadingRecent(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error appropriately
      }
    };

    fetchData();
  }, []);

  const getCurrentPropertyProfile = async () => {
    const propertyUnit = await getPropertyUnitById(propertyUnitId);
    setPropertyUnit(propertyUnit);

    console.log(propertyUnit);
    if (propertyUnit) {
      setPropertyId(propertyUnit.property_id);
    }
  };

  const eraseTaskForm = () => {
    setTaskData({
      task_name: "",
      task_description: "",
      status_id: "",
      priority_id: "",
      task_due_date: new Date(),
      is_done: false,
      created_by: "",
    });

    setSelectedStatusOption(null);
    setSelectedPriorityOption(null);
  };

  //task is done checkbox
  const handleCheckboxChange = async (event, _unitTask) => {
    console.log(_unitTask);
    const { name, checked } = event.currentTarget;
    const updatedTaskData = { ...taskData, [name]: checked };
    setTaskData(updatedTaskData);

    // Update the task in the list immediately for UI feedback
    const updatedTasks = latestUnitTasks.tasks.map((task) =>
      task.task_id === _unitTask.task_id ? { ...task, is_done: checked } : task
    );
    setLatestUnitTasks({ ...latestUnitTasks, tasks: updatedTasks });

    console.log(updatedTaskData.is_done);
    console.log(_unitTask.task_id);

    // Use _unitTask.task_id directly for accurate task identification
    if (updatedTaskData.is_done) {
      console.log("Task is done");
      const response = await completeTask(_unitTask.task_id, true);
      console.log(response);

      if (response) {
        getUnitTasks();

        //log the property unit audit
        logPropertyUnitAudit({
          audit_type: "Task",
          action_name: "Task Completed",
          description: `Task ${_unitTask.task_name} has been completed.`,
        });
      }
    } else {
      console.log("Task is not done");
      const response = await completeTask(_unitTask.task_id, false);
      console.log(response);

      if (response) {
        getUnitTasks();

        //log the property unit audit
        logPropertyUnitAudit({
          audit_type: "Task",
          action_name: "Task Uncompleted",
          description: `Task ${_unitTask.task_name} has been uncompleted.`,
        });
      }
    }
  };

  //testing the property unit audit
  const logPropertyUnitAudit = async ({
    audit_type,
    action_name,
    description,
    icon_name = "ri-edit-box-line",
    icon_color = "blue",
  }) => {
    const first_name = localStorage.getItem(config.first_name);
    const last_name = localStorage.getItem(config.last_name);
    const created_by = `${first_name} ${last_name}`;
    const audit = {
      property_unit_audit_id: uuidv4(),
      property_unit_id: propertyUnit.property_unit_id,
      audit_date: new Date(),
      audit_type: audit_type,
      description: description,
      icon_details: {
        icon_name: icon_name,
        icon_color: icon_color,
      },
      action_name: action_name,
      created_by: created_by,
    };

    const response = await createPropertyUnitAudit(audit);

    console.log(response);

    //get the audit trail for the property unit
    getPropertyUnitAudit(propertyUnit.property_unit_id);
  };

  //get the audit trail for the property unit
  const getPropertyUnitAudit = async (_property_unit_id) => {
    console.log("Property Unit Id for Audit");
    console.log(_property_unit_id);
    const recentActivities = await getAuditByPropertyUnitIdForLast7Days(
      _property_unit_id
    );

    console.log("Property Unit Audit");
    console.log(recentActivities);
    if (recentActivities) {
      // Then set it with the new response
      setRecentActivities(recentActivities);

      console.log(recentActivities);
    }
  };
  //placeholder to build faster
  //Do the audit trail feature and edit property unit feature (in quick actions)
  const [recentActivities, setRecentActivities] = useState([
    {
      activity_id: "",
      activity: "",
      created_at: new Date().toLocaleString(),
      created_by: "",
      icon_details: {
        icon_name: "",
        icon_color: "",
      },
      title: "",
      description: "",
      audit_date: new Date().toLocaleString(),
    },
  ]);
  function getAvatarClass(iconColor) {
    switch (iconColor) {
      case "red":
        return "bg-danger-subtle text-danger";
      case "blue":
        return "bg-primary-subtle text-primary";
      // Add more cases as needed
      default:
        return "bg-success-subtle text-success"; // Default case
    }
  }

  //TODO next
  //if the task has a due date, then it needs to be in the upcoming activities to track
  //when a task is completed check to make sure it is removed from the upcoming activities to prevent unnecessary clutter and notifications
  //dont show the completed upcoming activities
  const [upcomingActivities, setUpcomingActivities] = useState([
    {
      task_reminder_id: "",
      reminder_id: "",
      task_id: "",
      due_date: new Date().toLocaleString(),
      remind_how_many_days_before: 1,
      remind_on: new Date().toLocaleString(),
      reminder_status: "",
      reminder_type: "",
      remind_title: "",
      remind_description: "",
      remind_to: "",
      created_by: "",
      created_at: new Date().toLocaleString(),
      updated_at: new Date().toLocaleString(),
      user_id: "",
      is_completed: false,
      assigned_users: [
        {
          user_id: "1",
          first_name: "Demo",
          last_name: "User",
          image_url: default_avatar_img,
        },
        {
          user_id: "2",
          first_name: "Demo",
          last_name: "User",
          image_url: default_avatar_img1,
        },
        {
          user_id: "3",
          first_name: "Demo",
          last_name: "User",
          image_url: default_avatar_img2,
        },
        {
          user_id: "4",
          first_name: "Demo",
          last_name: "User",
          image_url: default_avatar_img3,
        },
      ],
    },
  ]);

  //create a task reminder
  const postTaskReminder = async (task) => {
    const reminder = {
      task_reminder_id: uuidv4(),
      reminder_id: reminderId,
      task_id: task.task_id,
      due_date: task.task_due_date.toISOString(),
      remind_how_many_days_before: 1, //eventually let user set this
      remind_on: task.task_due_date.toISOString(),
      reminder_status: "Active",

      reminder_type: "Task",
      remind_title: task.task_name,
      remind_description: task.task_description,
      remind_to: task.created_by,
      created_by: task.created_by,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      user_id: localStorage.getItem(config.user_id),
      is_completed: false,
    };

    console.log(reminder);

    const response = await createTaskReminder(reminder);
    console.log(response);

    if (response) {
      //update the reminders
      getUpcomingActivities(reminderId);
    }
  };

  const [reminderId, setReminderId] = useState(""); //to be persisted
  const [reminderManager, setReminderManager] = useState({
    reminder_id: "",
    user_id: "",
    property_unit_id: "",
    entity_id: "",
    entity_type: "",
    reminder_date: new Date(),
    reminder_type: "",
    reminder_status: "",
  });

  const getReminderManager = async () => {
    const response = await getReminderManagerFromServer(propertyUnitId);
    console.log("Reminder Manager");
    console.log(response);
    if (response) {
      setReminderManager(response);
      setReminderId(response.reminder_id);

      //get the upcoming activities
      getUpcomingActivities(response.reminder_id);
    }
  };

  //get the upcoming activities
  const getUpcomingActivities = async (reminder_id) => {
    //set upcomingActivities
    const response = await getTaskRemindersByReminderIdAndUserId(reminder_id);
    console.log("Upcoming Activities");
    console.log(response);
    setUpcomingActivities(response);
  };

  return (
    <React.Fragment>
      {/*Loader */}
      {isLoading && <LoaderView />}

      {/* Modal to exist */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div id="task-error-msg" className="alert alert-danger py-2"></div>
            <form autoComplete="off" action="" id="creattask-form">
              <input type="hidden" id="taskid-input" className="form-control" />
              <div className="mb-3">
                <Input
                  name="task_name"
                  label="Task Name"
                  value={taskData.task_name}
                  onChange={handleTaskChange}
                  error={errors.task_name}
                />
              </div>
              {/*Implement when the invite feature is added to Gurenter */}
              <div className="mb-3 position-relative" hidden>
                <label htmlFor="task-assign-input" className="form-label">
                  Assigned To
                </label>

                <div
                  className="avatar-group justify-content-center"
                  id="assignee-member"
                ></div>
                <div className="select-element">
                  <button
                    className="btn btn-light w-100 d-flex justify-content-between"
                    type="button"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                  >
                    <span>
                      Assigned To
                      <b id="total-assignee" className="mx-1">
                        0
                      </b>
                      Members
                    </span>{" "}
                    <i className="mdi mdi-chevron-down"></i>
                  </button>
                  <div className="dropdown-menu w-100">
                    <div data-simplebar style={{ maxHeight: "141px" }}>
                      <ul className="list-unstyled mb-0">
                        <li>
                          <a
                            className="dropdown-item d-flex align-items-center"
                            href="#"
                          >
                            <div className="avatar-xxs flex-shrink-0 me-2">
                              <img
                                src="assets/images/users/avatar-2.jpg"
                                alt=""
                                className="img-fluid rounded-circle"
                              />
                            </div>
                            <div className="flex-grow-1">James Forbes</div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-4 mb-3">
                <div className="col-lg-6">
                  <label htmlFor="task-status" className="form-label">
                    Status
                  </label>
                  <Select
                    styles={selectCustomStyle(isDarkMode)}
                    value={selectedStatusOption}
                    onChange={handleStatusChange}
                    options={statusOptions}
                  />
                </div>
                {/*end col*/}
                <div className="col-lg-6">
                  <label htmlFor="priority-field" className="form-label">
                    Priority
                  </label>
                  <Select
                    styles={selectCustomStyle(isDarkMode)}
                    value={selectedPriorityOption}
                    onChange={handlePriorityChange}
                    options={priorityOptions}
                  />
                </div>
                {/*end col*/}
              </div>
              <div className="mb-3">
                <label htmlFor="task_due_date" style={{ display: "block" }}>
                  Due Date
                </label>
                <DatePicker
                  name="task_due_date"
                  style={{ width: "100%" }}
                  className="form-control"
                  selected={new Date(taskData.task_due_date)}
                  onChange={(date) =>
                    handleTaskChange({
                      name: "task_due_date",
                      value: date,
                    })
                  }
                  showTimeSelect
                  timeFormat="HH:mm"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="task-description" className="form-label">
                  Task Description
                </label>
                <textarea
                  className="form-control"
                  id="task-description"
                  rows="3"
                  name="task_description"
                  value={taskData.task_description}
                  onChange={handleTaskChange}
                ></textarea>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-12 d-flex justify-content-end gap-2">
              <Button
                variant="secondary"
                onClick={() => {
                  handleCloseModal();
                }}
              >
                Close
              </Button>
              <Button
                variant="success"
                onClick={() => {
                  handleAddTask();
                }}
              >
                Add Task
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <div className={`${getThemeClasses()}`}>
        <div className="container-fluid">
          {/* start page title */}
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageTitle="Property"
            previousPageLink={`/property/${propertyId}`}
          />
          {/* end page title */}
          <div className="col-12">
            <div className="row">
              <div className="col-xxl-2">
                <div className="d-flex flex-column h-100">
                  <div className="row h-100">
                    <div className="col-12">
                      {/* start card */}
                      <div className="card">
                        <Carousel
                          className="card-img-top img-fluid tw-h-[290px]"
                          interval={null}
                          indicators={
                            propertyUnit.property_unit_image_urls?.length > 1
                          }
                        >
                          {propertyUnit.property_unit_image_urls &&
                          propertyUnit.property_unit_image_urls?.length > 0 ? (
                            propertyUnit.property_unit_image_urls.map(
                              (image, index) => (
                                <Carousel.Item key={index}>
                                  <img
                                    className="d-block w-100"
                                    src={image.url || default_img}
                                    alt={image.file_name}
                                    style={{
                                      height: "290px",
                                      objectFit: "cover",
                                    }}
                                  />
                                </Carousel.Item>
                              )
                            )
                          ) : (
                            <Carousel.Item>
                              <img
                                className="d-block w-100"
                                src={default_img}
                                style={{
                                  height: "290px",
                                  objectFit: "cover",
                                }}
                                alt="Default Unit Image"
                              />
                            </Carousel.Item>
                          )}
                        </Carousel>
                        <div className="card-body">
                          <div className="">
                            <h4 className="card-title mb-0 fs-4 fw-medium">
                              {propertyUnit.unit_name}
                            </h4>

                            <div className="d-flex mb-2 align-items-center">
                              <div className="col-2 text-start">
                                <h5 className="mb-1">
                                  <i className="bx bx-bed"></i>{" "}
                                  {propertyUnit.number_of_bedrooms}
                                </h5>
                              </div>
                              <div className="col-2 text-start">
                                <h5 className="mb-1">
                                  <i className="bx bx-bath"></i>{" "}
                                  {propertyUnit.number_of_bathrooms}
                                </h5>
                              </div>
                              <div className="col-3 text-start">
                                <h5 className="mb-1">
                                  <i className="bx bx-ruler"></i>{" "}
                                  {propertyUnit.square_footage}
                                </h5>
                              </div>
                              <div className="col-6 text-start">
                                <h5 className="mb-1">
                                  <i className="bx bx-money"></i>{" "}
                                  {new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency:
                                      selectedCurrencyOption?.data
                                        ?.currency_code || "USD",
                                  }).format(
                                    propertyUnit.market_rent_amount
                                  )}{" "}
                                  {selectedCurrencyOption?.data
                                    ?.currency_code || "USD"}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end col*/}
                  </div>
                  {/* end row*/}
                </div>
              </div>
              {/* end col*/}

              <div className="col-xl-10">
                <div className="row">
                  <div className="col-xxl-4">
                    <div className="card card-height-100">
                      <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                          Upcoming Activities
                        </h4>
                        <div className="flex-shrink-0">
                          <div className="dropdown card-header-dropdown">
                            <a
                              className="text-reset dropdown-btn"
                              href="#"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <span className="text-muted fs-18">
                                <i className="mdi mdi-dots-vertical"></i>
                              </span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-end">
                              <a className="dropdown-item" href="#">
                                Edit
                              </a>
                              <a className="dropdown-item" href="#">
                                Remove
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* end card header */}
                      <div className="card-body pt-0">
                        {isLoadingUpcoming ? (
                          <ShimmerEffect
                            type="card"
                            height="364px"
                            darkMode={isDarkMode}
                          />
                        ) : (
                          <ul
                            className="list-group list-group-flush border-dashed py-2"
                            style={{ maxHeight: "364px", overflowY: "auto" }}
                          >
                            {/* end */}

                            {upcomingActivities.length > 0 ? (
                              upcomingActivities.map((activity, index) => (
                                <li
                                  key={index}
                                  className="list-group-item ps-2 card card-body border "
                                >
                                  <div className="row align-items-center g-3">
                                    <div className="col-auto">
                                      <div className="avatar-sm p-1 py-2 h-auto bg-light rounded-3 material-shadow">
                                        <div className="text-center">
                                          <h5 className="mb-0">
                                            {new Date(
                                              activity.due_date
                                            ).getDate()}
                                          </h5>
                                          <div className="text-muted">
                                            {" "}
                                            {new Date(
                                              activity.due_date
                                            ).toLocaleDateString("en-US", {
                                              weekday: "short",
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-muted mt-0 mb-1 fs-13">
                                        {Intl.DateTimeFormat("en", {
                                          hour: "numeric",
                                          minute: "numeric",
                                        }).format(new Date(activity.due_date))}
                                      </h5>

                                      {/* task name*/}
                                      <h5 className="mb-1">
                                        {activity.remind_title}
                                      </h5>

                                      {/* task description*/}
                                      <p className="mb-0">
                                        {activity.remind_description}
                                      </p>
                                    </div>
                                    <div className="col-sm-auto" hidden>
                                      <div className="avatar-group">
                                        <div className="avatar-group-item material-shadow">
                                          <a
                                            href="#"
                                            onClick={(event) => {
                                              event.preventDefault();
                                            }}
                                            className="d-inline-block"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title=""
                                            data-bs-original-title="Frida Bang"
                                          >
                                            <img
                                              src={default_avatar_img}
                                              alt=""
                                              className="rounded-circle avatar-xxs"
                                            />
                                          </a>
                                        </div>
                                        <div className="avatar-group-item material-shadow">
                                          <a
                                            href="#"
                                            onClick={(event) => {
                                              event.preventDefault();
                                            }}
                                            className="d-inline-block"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title=""
                                            data-bs-original-title="Malou Silva"
                                          >
                                            <img
                                              src={default_avatar_img1}
                                              alt=""
                                              className="rounded-circle avatar-xxs"
                                            />
                                          </a>
                                        </div>
                                        <div className="avatar-group-item material-shadow">
                                          <a
                                            href="#"
                                            onClick={(event) => {
                                              event.preventDefault();
                                            }}
                                            className="d-inline-block"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title=""
                                            data-bs-original-title="Simon Schmidt"
                                          >
                                            <img
                                              src={default_avatar_img2}
                                              alt=""
                                              className="rounded-circle avatar-xxs"
                                            />
                                          </a>
                                        </div>
                                        <div className="avatar-group-item material-shadow">
                                          <a
                                            href="#"
                                            onClick={(event) => {
                                              event.preventDefault();
                                            }}
                                            className="d-inline-block"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title=""
                                            data-bs-original-title="Tosh Jessen"
                                          >
                                            <img
                                              src={default_avatar_img3}
                                              alt=""
                                              className="rounded-circle avatar-xxs"
                                            />
                                          </a>
                                        </div>
                                        <div className="avatar-group-item material-shadow">
                                          <a
                                            href="#"
                                            onClick={(event) => {
                                              event.preventDefault();
                                            }}
                                          >
                                            <div className="avatar-xxs">
                                              <span className="avatar-title rounded-circle bg-primary text-white">
                                                3
                                              </span>
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* end row */}
                                </li>
                              ))
                            ) : (
                              <div className="text-center">
                                <Lottie
                                  options={emptyTaskLottieOptions}
                                  height={200}
                                  width={300}
                                />
                                <p className="text-muted">
                                  No Upcoming activities
                                </p>
                              </div>
                            )}

                            {/* end */}
                          </ul>
                        )}
                        {/* end */}
                        <div
                          hidden
                          className="align-items-center mt-2 row text-center text-sm-start"
                        >
                          <div className="col-sm">
                            <div className="text-muted">
                              Showing<span className="fw-semibold"> 4</span> of{" "}
                              <span className="fw-semibold">125</span> Results
                            </div>
                          </div>
                          <div className="col-sm-auto">
                            <ul className="pagination pagination-separated pagination-sm justify-content-center justify-content-sm-start mb-0">
                              <li className="page-item disabled">
                                <a href="#" className="page-link">
                                  ←
                                </a>
                              </li>
                              <li className="page-item">
                                <a href="#" className="page-link">
                                  1
                                </a>
                              </li>
                              <li className="page-item active">
                                <a href="#" className="page-link">
                                  2
                                </a>
                              </li>
                              <li className="page-item">
                                <a href="#" className="page-link">
                                  3
                                </a>
                              </li>
                              <li className="page-item">
                                <a href="#" className="page-link">
                                  →
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      {/* end card body */}
                    </div>
                    {/* end card */}
                  </div>{" "}
                  {/* end col*/}
                  <div className="col-xl-4">
                    <div className="card card-height-100">
                      <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                          My Tasks
                        </h4>
                        <div className="flex-shrink-0">
                          <div className="dropdown card-header-dropdown">
                            <a
                              className="text-reset dropdown-btn"
                              href="#"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              hidden
                            >
                              <span className="text-muted">
                                <i className="ri-file-copy-2-line align-middle me-1 fs-15"></i>
                                Copy{" "}
                                {/* Open option to duplicate to other units and portfolios*/}
                              </span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-end">
                              <a className="dropdown-item" href="#">
                                Edit
                              </a>
                              <a className="dropdown-item" href="#">
                                Remove
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*end card header */}

                      <div className="card-body p-0">
                        <div className="align-items-center p-3 justify-content-between d-flex">
                          {latestUnitTasks &&
                            latestUnitTasks.tasks.length > 0 &&
                            latestUnitTasks.tasks[0].task_name && (
                              <div className="p-0 mt-auto">
                                <a
                                  href="#"
                                  onClick={(event) => {
                                    event.preventDefault();
                                  }}
                                  className="text-muted text-decoration-underline"
                                >
                                  Show more...
                                </a>
                              </div>
                            )}
                          <button
                            type="button"
                            className="btn btn-sm btn-success"
                            onClick={() => {
                              setShowModal(true);
                            }}
                          >
                            <i className="ri-add-line align-middle me-1"></i>{" "}
                            Add Task
                          </button>
                        </div>
                        {/*end card header */}
                        <div className="card-body p-0">
                          {isLoadingTasks ? (
                            <ShimmerEffect
                              type="card"
                              height="290px"
                              darkMode={isDarkMode}
                            />
                          ) : (
                            <div
                              style={{ maxHeight: "290px", overflowY: "auto" }}
                              className="p-3"
                            >
                              {latestUnitTasks &&
                              latestUnitTasks.tasks.length > 0 &&
                              latestUnitTasks.tasks[0].task_name ? (
                                <ul className="list-group list-group-flush border-dashed">
                                  {latestUnitTasks &&
                                    latestUnitTasks.tasks.map(
                                      (unitTask, index) => (
                                        <li
                                          className="list-group-item ps-2 card card-body border"
                                          key={index}
                                          style={
                                            unitTask.is_done
                                              ? {
                                                  textDecoration:
                                                    "line-through",
                                                }
                                              : {}
                                          }
                                        >
                                          <div className="d-flex align-items-start">
                                            <div className="form-check ps-0 flex-sharink-0">
                                              <input
                                                type="checkbox"
                                                className="form-check-input ms-0"
                                                id="task_one"
                                                name="is_done"
                                                checked={unitTask.is_done}
                                                onChange={(e) =>
                                                  handleCheckboxChange(
                                                    e,
                                                    unitTask
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="flex-grow-1">
                                              <label
                                                className="form-check-label mb-0 ps-2"
                                                htmlFor="task_one"
                                                style={
                                                  unitTask.is_done
                                                    ? {
                                                        textDecoration:
                                                          "line-through",
                                                      }
                                                    : {}
                                                }
                                              >
                                                {unitTask.task_name}
                                              </label>

                                              <p className="text-muted fs-12 mb-0 ps-2">
                                                {unitTask.task_description
                                                  .length > 150
                                                  ? `${unitTask.task_description.slice(
                                                      0,
                                                      150
                                                    )}...`
                                                  : unitTask.task_description}
                                              </p>
                                            </div>
                                            <div className="flex-shrink-0 ms-2">
                                              <p className="text-muted fs-12 mb-0">
                                                {new Date(
                                                  unitTask.task_due_date
                                                ).toLocaleDateString("en-US", {
                                                  day: "numeric",
                                                  month: "short",
                                                })}
                                              </p>
                                            </div>
                                          </div>
                                        </li>
                                      )
                                    )}
                                </ul>
                              ) : (
                                <div className="text-center">
                                  <Lottie
                                    options={emptyTaskLottieOptions}
                                    height={200}
                                    width={300}
                                  />

                                  <p className="text-muted">
                                    No tasks available
                                  </p>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      {/*end card body */}
                    </div>
                    {/*end card */}
                  </div>
                  {/*end col */}
                  <div className="col-xl-4">
                    <div className="card card-height-100">
                      <div className="card-header border-bottom-dashed align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                          Recent Activity
                        </h4>

                        <div className="flex-shrink-0">
                          <button
                            type="button"
                            className="btn btn-soft-primary btn-sm"
                          >
                            View All Activity
                          </button>
                        </div>
                      </div>
                      {/* end cardheader */}
                      <div className="card-body p-0">
                        {isLoadingRecent ? (
                          <ShimmerEffect
                            type="card"
                            height="344px"
                            darkMode={isDarkMode}
                          />
                        ) : (
                          <div
                            style={{ maxHeight: "344px", overflowY: "auto" }}
                            className="p-3"
                          >
                            {recentActivities && recentActivities.length > 0 ? (
                              <div className="acitivity-timeline acitivity-main ">
                                {recentActivities.map((activity, index) => (
                                  <div
                                    key={index}
                                    className="acitivity-item d-flex mb-3 "
                                  >
                                    <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                                      <div
                                        className={`avatar-title ${getAvatarClass(
                                          activity?.icon_details?.icon_color
                                        )} rounded-circle material-shadow`}
                                      >
                                        <i
                                          className={
                                            activity?.icon_details?.icon_name
                                          }
                                        ></i>
                                      </div>
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                      <h6 className="mb-1">{activity.title}</h6>
                                      <p className="text-muted mb-1">
                                        {activity.description}
                                      </p>
                                      <small className="mb-0 text-muted">
                                        {new Date(
                                          activity.audit_date
                                        ).toLocaleDateString("en-US", {
                                          day: "2-digit", // Use '2-digit' for day to ensure it's always two digits
                                          month: "short", // 'short' for abbreviated month name
                                          year: "numeric", // 'numeric' for the full year
                                        })}
                                      </small>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div className="text-center">
                                <Lottie
                                  options={emptyTaskLottieOptions}
                                  height={200}
                                  width={300}
                                />
                                <p className="text-muted">
                                  No recent activities
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      {/* end card body */}
                    </div>
                    {/* end card */}
                  </div>
                  {/* end col */}
                </div>
              </div>
              {/* end col */}
            </div>
            {/* end row*/}
          </div>

          {/* Widget Section */}

          {/* It needs to be implemented */}
          <div className="col-xl-12" hidden>
            <div className="row">
              <div className="col-md-2">
                {/* Users card start*/}
                <div className="card card-animate">
                  <div className="mt-3 mt-lg-0 py-4 px-3">
                    <h5 className="text-muted text-uppercase fs-13">
                      Outstanding Balance{" "}
                      <i className="ri-arrow-up-circle-line text-danger fs-18 float-end align-middle"></i>
                    </h5>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <i className=" las la-money-bill display-6 text-muted"></i>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h4 className="mb-0">
                          $
                          <span className="counter-value" data-target="1596.5">
                            2,500.00 USD
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Users card end*/}
              </div>
              {/* end col*/}
              <div className="col-md-2">
                {" "}
                {/* Users card start*/}
                <div className="card card-animate">
                  <div className="mt-3 mt-lg-0 py-4 px-3">
                    <h5 className="text-muted text-uppercase fs-13">
                      Rent Due Next{" "}
                    </h5>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <i className="las la-calendar display-6 text-muted"></i>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h4 className="mb-0">07/03/2024</h4>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Users card end*/}
              </div>
              {/* end col*/}
              <div className="col-md-2">
                {" "}
                {/* Users card start*/}
                <div className="card card-animate">
                  <div className="mt-3 mt-lg-0 py-4 px-3">
                    <h5 className="text-muted text-uppercase fs-13">
                      Deposit Held{" "}
                    </h5>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <i className=" las la-coins display-6 text-muted"></i>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h4 className="mb-0">
                          $
                          <span className="counter-value" data-target="1596.5">
                            2,500.00 USD
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Users card end*/}
              </div>
              {/* end col*/}
              <div className="col-md-2">
                {" "}
                {/* Users card start*/}
                <div className="card card-animate">
                  <div className="mt-3 mt-lg-0 py-4 px-3">
                    <h5 className="text-muted text-uppercase fs-13">
                      Open Requests{" "}
                      <i className="ri-arrow-up-circle-line text-danger fs-18 float-end align-middle"></i>
                    </h5>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <i className=" las la-ticket-alt display-6 text-muted"></i>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h4 className="mb-0">
                          <span className="counter-value" data-target="1596.5">
                            5
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Users card end*/}
              </div>
              {/* end col*/}
              <div className="col-md-2">
                {" "}
                {/* Users card start*/}
                <div className="card card-animate">
                  <div className="mt-3 mt-lg-0 py-4 px-3">
                    <h5 className="text-muted text-uppercase fs-13">
                      Lease Status{" "}
                    </h5>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <i
                          className={`las la-${
                            propertyUnit.is_rented ? "check-" : ""
                          }circle display-6 ${
                            propertyUnit.is_rented
                              ? "text-success"
                              : "text-muted"
                          }`}
                        ></i>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h4 className="mb-0">
                          {propertyUnit.is_rented ? (
                            <>
                              $
                              <span
                                className="counter-value"
                                data-target="1596.5"
                              >
                                0
                              </span>
                            </>
                          ) : (
                            "Not Rented"
                          )}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Users card end*/}
              </div>
              {/* end col*/}
              <div className="col-md-2">
                {/* Users card start*/}
                <div className="card card-animate">
                  <div className="mt-3 mt-lg-0 py-4 px-3">
                    <h5 className="text-muted text-uppercase fs-13">
                      Fixed Period{" "}
                    </h5>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <i className="las la-calendar display-6 text-muted"></i>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h4 className="mb-0">06/03/2024 to 06/03/2025</h4>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Users card end*/}
              </div>
              {/* end col*/}
            </div>
          </div>
          {/* end col */}

          {/* Lease Management Buttons*/}

          {propertyUnit.is_rented && (
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1">
                            <h5 className="mb-0">Lease Management</h5>

                            <p className="text-muted">
                              Quickly manage the lease for this property unit
                            </p>

                            <div className="d-flex gap-2">
                              {/* Add new unit*/}
                              <button
                                type="button"
                                className="btn btn-outline-dark btn-md"
                              >
                                <i className="ri-arrow-go-forward-line align-bottom"></i>
                                Renew Lease
                              </button>

                              {/* Edit Property*/}
                              <button
                                type="button"
                                className="btn btn-outline-danger btn-md"
                              >
                                <i className="ri-close-line align-bottom"></i>{" "}
                                Not Renewing
                              </button>

                              {/*EndLease*/}
                              <button
                                type="button"
                                className="btn btn-outline-warning btn-md"
                              >
                                <i className="ri-file-text-line align-bottom"></i>{" "}
                                End Lease
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* end row */}

          {/* Quick Actions Buttons (If Rented) Must be implemented*/}
          {propertyUnit.is_rented && (
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1">
                            <h5 className="mb-0">Quick Actions</h5>
                            <p className="text-muted">
                              Quick actions to help you manage your property
                            </p>

                            <div className="tw-flex tw-flex-wrap tw-gap-2">
                              <Link
                                to={`/paymentReceipts/${propertyUnitId}`}
                                type="button"
                                className="btn btn-outline-success btn-md tw-whitespace-nowrap tw-mb-2"
                              >
                                <i className="las la-money-check align-bottom"></i>{" "}
                                Receipt
                              </Link>

                              <button
                                type="button"
                                className="btn btn-outline-info btn-md tw-whitespace-nowrap tw-mb-2"
                              >
                                <i className="las la-user-friends align-bottom"></i>{" "}
                                Current Tenants
                              </button>

                              <Link
                                to={`/editUnit/${propertyUnitId}`}
                                className="btn btn-outline-secondary btn-md tw-whitespace-nowrap tw-mb-2"
                              >
                                <i className="ri-edit-line align-bottom"></i>{" "}
                                Edit Unit
                              </Link>

                              <Link
                                to={`/allUnitTickets/${propertyUnitId}`}
                                className="btn btn-outline-primary btn-md tw-whitespace-nowrap tw-mb-2"
                              >
                                <i className="ri-ticket-2-line align-bottom"></i>{" "}
                                Ticket Requests
                              </Link>

                              <Link
                                to={`/unitTransactions/${propertyUnitId}`}
                                className="btn btn-outline-secondary btn-md tw-whitespace-nowrap tw-mb-2"
                              >
                                <i className="las la-money-bill align-bottom"></i>{" "}
                                Transactions
                              </Link>

                              <button
                                type="button"
                                className="btn btn-outline-danger btn-md tw-whitespace-nowrap tw-mb-2"
                                hidden
                              >
                                <i className="las la-money-bill-wave-alt align-bottom"></i>{" "}
                                Expenses
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!propertyUnit.is_rented && (
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1">
                            <h5 className="mb-0">Quick Actions</h5>
                            <p className="text-muted">
                              Quick actions to help you manage your property
                            </p>

                            <div className="tw-flex tw-flex-wrap tw-gap-2">
                              <Link
                                to={`/createExistingTenant/${propertyUnitId}`}
                                className="btn btn-outline-info btn-md tw-whitespace-nowrap tw-mb-2"
                                title="Invite a tenant manually, this will bypass the tenant screening"
                              >
                                <i className="ri-user-add-line align-bottom"></i>{" "}
                                Invite Tenant Manually
                              </Link>

                              <Link
                                to={`/newAdvertisement/${propertyUnitId}`}
                                className="btn btn-outline-info btn-md tw-whitespace-nowrap tw-mb-2"
                              >
                                <i className="mdi mdi-note-plus-outline"></i>{" "}
                                Create Advertisement
                              </Link>

                              <Link
                                to={`/myAdvertisements/${propertyUnitId}`}
                                className="btn btn-outline-primary btn-md tw-whitespace-nowrap tw-mb-2"
                              >
                                <i className="mdi mdi-advertisements align-bottom"></i>{" "}
                                Advertisements
                              </Link>

                              <Link
                                to={`/screeningPacks`}
                                className="btn btn-outline-primary btn-md tw-whitespace-nowrap tw-mb-2"
                              >
                                <i className="ri-user-search-line align-bottom"></i>{" "}
                                Screening Packs
                              </Link>

                              <Link
                                hidden
                                to={`/pendingExistingTenantRequest/${propertyUnitId}`}
                                className="btn btn-outline-primary btn-md tw-whitespace-nowrap tw-mb-2"
                              >
                                <span hidden className="badge bg-info ms-1">
                                  2
                                </span>{" "}
                                Pending Invites
                              </Link>

                              <button
                                type="button"
                                className="btn btn-outline-info btn-md tw-whitespace-nowrap tw-mb-2"
                              >
                                <i className="ri-team-line align-bottom"></i>{" "}
                                Interviews
                              </button>

                              <Link
                                to={`/editUnit/${propertyUnitId}`}
                                className="btn btn-outline-secondary btn-md tw-whitespace-nowrap tw-mb-2"
                              >
                                <i className="ri-edit-line align-bottom"></i>{" "}
                                Edit Unit
                              </Link>

                              <Link
                                to={`/allUnitTickets/${propertyUnitId}`}
                                className="btn btn-outline-primary btn-md tw-whitespace-nowrap tw-mb-2"
                              >
                                <i className="ri-ticket-2-line align-bottom"></i>{" "}
                                Ticket Requests
                              </Link>

                              <Link
                                to={`/unitTransactions/${propertyUnitId}`}
                                className="btn btn-outline-secondary btn-md tw-whitespace-nowrap tw-mb-2"
                              >
                                <i className="las la-money-bill"></i>{" "}
                                Transactions
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* end row */}
        </div>
        {/* container-fluid */}
      </div>
      {/* End Page-content */}
    </React.Fragment>
  );
};

export default PropertyUnitDetail;
