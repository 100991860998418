import React, { useState } from "react";

import { DocumentIcon } from "../../../ui/Icons";
import { SignatureModal } from "../SignatureModal";

const signatureStatuses = {
  signed: "tw-text-green-700 tw-bg-green-50 tw-ring-green-600/20",
  pending: "tw-text-yellow-700 tw-bg-yellow-50 tw-ring-yellow-600/20",
  required: "tw-text-blue-700 tw-bg-blue-50 tw-ring-blue-600/20",
};

interface PersonProfile {
  email_address: string;
  first_name: string;
  last_name: string;
}

interface SignatureStatus {
  user_id: string;
  email_address: string;
  is_signed: boolean;
  signed_at?: string;
  name: string;
}

interface RentalContractSignatureWidgetProps {
  contractId: string;
  unitName: string;
  startDate: string;
  endDate: string;
  landlordSignatures: SignatureStatus[];
  tenantSignatures: SignatureStatus[];
  onViewClick?: () => void;
  onSignClick: (signature: string) => void;
  isDarkMode: boolean;
  currentUserId: string;
  landlordProfile: PersonProfile;
  tenantProfile: PersonProfile;
}

const hasUserSigned = (
  currentUserId: string,
  landlordSignatures: SignatureStatus[],
  tenantSignatures: SignatureStatus[]
): boolean => {
  const allSignatures = [...landlordSignatures, ...tenantSignatures];
  return allSignatures.some(
    (signature) => signature.user_id === currentUserId && signature.is_signed
  );
};

const getSignerName = (
  signer: SignatureStatus,
  landlordProfile: PersonProfile,
  tenantProfile: PersonProfile
): string => {
  if (signer.name) return signer.name;

  return signer.email_address === landlordProfile.email_address
    ? `${landlordProfile.first_name} ${landlordProfile.last_name}`
    : `${tenantProfile.first_name} ${tenantProfile.last_name}`;
};

export const RentalContractSignatureWidget: React.FC<
  RentalContractSignatureWidgetProps
> = ({
  contractId,
  unitName,
  startDate,
  endDate,
  landlordSignatures = [],
  tenantSignatures = [],
  onViewClick,
  onSignClick,
  isDarkMode,
  currentUserId,
  landlordProfile,
  tenantProfile,
}) => {
  const formatDate = (date: string) => new Date(date).toLocaleDateString();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [signature, setSignature] = useState<string>("");

  const handleSign = () => {
    if (signature && onSignClick) {
      onSignClick(signature);
      setIsModalOpen(false);
      setSignature("");
    }
  };

  return (
    <div
      className={`
        tw-overflow-hidden tw-rounded-xl tw-border 
        ${isDarkMode ? "tw-border-gray-700" : "tw-border-gray-200"}
        ${isDarkMode ? "tw-bg-gray-800" : "tw-bg-white"}
        tw-shadow-sm hover:tw-shadow-md tw-transition-all tw-duration-200
      `}
    >
      {/* Header */}
      <div
        className={`
        tw-flex tw-items-center tw-gap-x-4 tw-border-b tw-border-gray-900/5 tw-p-6
        ${isDarkMode ? "tw-bg-gray-800/50" : "tw-bg-gray-50"}
      `}
      >
        <div
          className={`
          tw-flex tw-size-12 tw-items-center tw-justify-center tw-rounded-lg tw-ring-1 tw-ring-gray-900/10
          ${isDarkMode ? "tw-bg-gray-700" : "tw-bg-white"}
        `}
        >
          <DocumentIcon />
        </div>
        <div>
          <h3
            className={`tw-text-sm tw-font-medium ${
              isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
            }`}
          >
            Rental Contract
          </h3>
          <p
            className={`tw-text-sm ${
              isDarkMode ? "tw-text-gray-400" : "tw-text-gray-500"
            }`}
          >
            {unitName}
          </p>
        </div>

        <div className="tw-ml-auto tw-flex tw-gap-2">
          <button
            onClick={onViewClick}
            className={`
              tw-rounded-md tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-shadow-sm tw-ring-1 tw-ring-inset
              ${
                isDarkMode
                  ? "tw-bg-gray-700 tw-text-gray-100 tw-ring-gray-600 hover:tw-bg-gray-600"
                  : "tw-bg-white tw-text-gray-900 tw-ring-gray-300 hover:tw-bg-gray-50"
              }
            `}
          >
            View
          </button>
          {!hasUserSigned(
            currentUserId,
            landlordSignatures,
            tenantSignatures
          ) && (
            <button
              onClick={() => setIsModalOpen(true)}
              className="tw-rounded-md tw-bg-blue-600 tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-blue-500"
            >
              Sign
            </button>
          )}
        </div>
      </div>

      {/* Content */}
      <dl
        className={`tw-divide-y tw-px-6 tw-py-4 tw-text-sm ${
          isDarkMode ? "tw-divide-gray-700" : "tw-divide-gray-100"
        }`}
      >
        {/* Dates */}
        <div className="tw-flex tw-justify-between tw-gap-x-4 tw-py-3">
          <dt className={isDarkMode ? "tw-text-gray-400" : "tw-text-gray-500"}>
            Period
          </dt>
          <dd className={isDarkMode ? "tw-text-gray-300" : "tw-text-gray-700"}>
            {formatDate(startDate)} - {formatDate(endDate)}
          </dd>
        </div>

        {/* Signatures */}
        <div className="tw-flex tw-flex-col tw-gap-y-2 tw-py-3">
          <dt className={isDarkMode ? "tw-text-gray-400" : "tw-text-gray-500"}>
            Signatures
          </dt>
          <dd className="tw-space-y-2">
            {[...landlordSignatures, ...tenantSignatures].map(
              (signer, index) => (
                <div
                  key={index}
                  className="tw-flex tw-items-center tw-justify-between"
                >
                  <span
                    className={
                      isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
                    }
                  >
                    {getSignerName(signer, landlordProfile, tenantProfile)}
                  </span>
                  <div className="tw-flex tw-items-center tw-gap-x-2">
                    {signer.signed_at && (
                      <span
                        className={`tw-text-xs ${
                          isDarkMode ? "tw-text-gray-400" : "tw-text-gray-500"
                        }`}
                      >
                        {formatDate(signer.signed_at)}
                      </span>
                    )}
                    <span
                      className={`tw-rounded-md tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-ring-1 tw-ring-inset ${
                        signatureStatuses[
                          signer.is_signed ? "signed" : "required"
                        ]
                      }`}
                    >
                      {signer.is_signed ? "Signed" : "Required"}
                    </span>
                  </div>
                </div>
              )
            )}
          </dd>
        </div>
      </dl>

      <SignatureModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSign={handleSign}
        signature={signature}
        setSignature={setSignature}
        isDarkMode={isDarkMode}
      />
    </div>
  );
};
