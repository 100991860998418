import React, { useEffect, useState } from "react";

import { useSubscriptionStatus } from "../../../../context/subscription/SubscriptionStatusContext";
import { useSubscriptionBilling } from "../../../../context/subscription/SubscriptionBillingContext";
import { toast } from "react-toastify";
import BillingHeader from "./sub/billingHeader";
import {
  getBillingDetailsFromStripe,
  getSubscriptionStatusFromStripe,
} from "../../../../services/authentication/subscription/subscriptionManagementService";
import PageTitleBox from "../../../reusable/title/pageTitleBox";
import LoaderView from "../../../reusable/loading/loaderView";
import { devLog } from "../../../../services/utils/logger";
import { useThemeClasses } from "../../../../services/utils/hooks/useThemeClasses";

const Billing = ({ isDarkMode }) => {
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const [isLoading, setIsLoading] = useState(false);
  const pageTitle = "Billing";

  //this should have been the subscription invoice
  const { subscriptionStatus } = useSubscriptionStatus();
  const { billingDetails, setBillingDetails } = useSubscriptionBilling();

  //TODO: get the current subscription information so it can be used to ancle or update billing info (put update on another view, updating payment as well)
  useEffect(() => {
    //get active subscriptions from stripe

    fetchBillingDetailsFromStripe();
  }, []);

  const fetchBillingDetailsFromStripe = async () => {
    setIsLoading(true);

    try {
      const billingDetails = await getBillingDetailsFromStripe();
      if (!billingDetails) {
        toast.error("No billing details received");
      }
      devLog("Billing Details from Stripe");
      devLog(billingDetails);
      setBillingDetails(billingDetails);
    } catch (ex) {
      console.error("Error fetching billing details:", ex);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      {/*Loader */}
      {isLoading && <LoaderView />}
      {/*/Loader */}

      {/* ============================================================== */}
      {/* Start right Content here */}
      {/* ============================================================== */}

      <div className={`${getThemeClasses()}`}>
        <div className="container-fluid">
          <PageTitleBox pageTitle={pageTitle} previousPageTitle="" />
          {/* end page title */}
          <div className="row">
            <div className="col-xl-12">
              {/* Reusable start */}

              <BillingHeader
                invoice={billingDetails}
                subscription={subscriptionStatus}
              />

              {/* Reusable end */}
            </div>
            <div className="col-xl-6">
              <div className="row">
                <div className="col-md-6">
                  {/*}  <SpendingCard
                    mostSpentCategory={mostSpentCategory}
                    percentageChange={percentageChange}
                    />*/}
                </div>
                <div className="col-md-6">
                  {/*} <BudgetLeftCard
                    leftToBudget={leftToBudget}
                    totalBudget={totalBudget}
                    />*/}
                </div>
                <div className="col-md-6">
                  {/*} <TotalSpendCard
                    totalSpentPercentageChange={totalSpentPercentageChange}
                    totalSpent={totalSpent}
                    secondLatestBudgetSummary={secondLatestBudgetSummary}
                    />*/}
                </div>
                <div className="col-md-6">
                  {/* <SavingCard
                    mostSavedCategory={mostSavedCategory}
                    percentageChange={percentageChangeInSavings}
                    />*/}
                </div>
              </div>
            </div>
          </div>
          {/* end col*/}
        </div>{" "}
        {/* end row*/}
        <div className="row">
          {/* Expense Transaction Grid */}
          {/* {expensesAndTransactions &&
            expensesAndTransactions?.length > 0 &&
            expensesAndTransactions[0]?.expense_id && (
              <ExpenseGrid
                expenses={expensesAndTransactions}
                title='Current Expenses'
              />
            )}*/}

          {/* end col */}
        </div>
        {/* end row */}
        {/* container-fluid */}
      </div>
      {/* End Page-content */}

      {/* End Page-content */}
    </React.Fragment>
  );
};

export default Billing;
