import React, { useEffect, useState } from "react";
import Joi from "joi-browser";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import PageTitleBox from "../../../reusable/title/pageTitleBox";
import Input from "../../../reusable/form/Input";

import { getPortfolioByOwnershipId } from "../../../../services/portfolio/portfolioManagement";
import config from "../../../../constants/config.json";
import { fetchSubPropertyTypesByPropertyTypeId } from "../../../../services/systemDefined/property/sysPropertyTypeManagement";
import { fetchPropertyByPortfolioId } from "../../../../services/property/retrieve/propertyRetrievalService";
import "simplebar"; // for the side effects
import "simplebar/dist/simplebar.min.css";
import HTMLEditor from "../../../reusable/form/ckeditor/cKEditorComponent";
import {
  createOrUpdateUserRentalContractTemplate,
  getUserRentalContractTemplateById,
  updateUserRentalContractTemplate,
} from "../../../../services/rental_contract/rentalContractService";
import { selectCustomStyle } from "../../../../styletheme/custom/selectStyle";
import LoaderView from "../../../reusable/loading/loaderView";
import { useThemeClasses } from "../../../../services/utils/hooks/useThemeClasses";

const UpdateContractTemplate = ({ isDarkMode }) => {
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  //wont be a param so i need to show the portfolio and property to assign the contractor, the property is optional which means they cover the entire portfolio
  const pageTitle = "Update Contract Template";
  const { contractTemplateId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const [contractTemplate, setContractTemplate] = useState({
    contract_template_id: "",
    user_id: "",
    portfolio_id: "",
    property_id: "",
    contract_name: "",
    contract_description: "",
    contract_template: "",
    created_at: Date.now(),
    updated_at: Date.now(),
    is_active: true,
    is_deleted: false,
  });

  const schema = {
    contract_template_id: Joi.string().optional().label("Contract Template Id"),
    user_id: Joi.string().optional().label("User"),
    portfolio_id: Joi.string().allow("").label("Portfolio"),
    property_id: Joi.string().allow("").label("Property"),
    contract_name: Joi.string().required().label("Contract Name"),
    contract_description: Joi.string()
      .optional()
      .allow("")
      .label("Contract Description"),
    contract_template: Joi.string().required().label("Contract Template"),
    created_at: Joi.date().optional().label("Created At"),
    updated_at: Joi.date().optional().label("Updated At"),
    is_active: Joi.boolean().optional().label("Is Active"),
    is_deleted: Joi.boolean().optional().label("Is Deleted"),
  };

  const [errors, setErrors] = useState({});

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(contractTemplate, schema, options);
    console.log("Validation Error:", error);

    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const validateProperty = ({ name, value }) => {
    // console.log('Name:', name);
    //console.log('Value:', value);
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const options = { abortEarly: false };
    console.log("Schema:", schemaLocal);

    const { error } = Joi.validate(obj, schemaLocal, options);

    console.log("Error in  validateProperty:", error);

    return error ? error.details[0].message : null;
  };
  //handle the form input changes
  const handleChange = (input) => {
    const currentErrors = { ...errors };
    let name, value;

    // Check if input is an event object (has a currentTarget property)
    if (input && input.currentTarget) {
      name = input.currentTarget.name;
      value = input.currentTarget.value;
    } else {
      name = "contract_template";
      value = input;
    }
    console.log("Name:", name);
    console.log("Value:", value);
    const errorMessage = validateProperty({ name, value });
    if (errorMessage) currentErrors[name] = errorMessage;
    else delete currentErrors[name];

    const data = { ...contractTemplate };
    if (value !== undefined) {
      data[name] = value;
    }

    setContractTemplate(data);
    setErrors(currentErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;

    try {
      console.log("contractTemplate:", contractTemplate);

      const response = await updateUserRentalContractTemplate(contractTemplate);
      console.log(response);
      if (response.is_error) {
        toast.error(response.server_message);
        return;
      }

      toast.success("Contract Template has been successfully updated!");
      navigate(`/contractTemplate/${contractTemplate.contract_template_id}`);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        console.log("Error:", ex.response.data); //need to log this
        const currentErrors = { ...errors };
        currentErrors.email_address = ex.response.data;
        setErrors(currentErrors);
      }
    }
  };

  //dropdown list for the portfolio
  const [existingPortfolioOptions, setExistingPortfolioOptions] = useState([
    { value: "", label: "", data: {} },
  ]);

  //selected portfolio options
  const [selectedPortfolioOption, setSelectedPortfolioOption] = useState(null);

  const handlePortfolioChange = (option) => {
    if (!option) {
      // Clear the portfolio selection
      setContractTemplate((prevState) => ({
        ...prevState,
        portfolio_id: "",
      }));
      setSelectedPortfolioOption(null);
      return;
    }

    console.log("Portfolio changed:", option);

    setContractTemplate((prevState) => ({
      ...prevState,
      portfolio_id: option.value,
    }));

    setSelectedPortfolioOption(option);

    getPropertiesByPortfolioId(option.value, contractTemplate.property_id);
  };

  //get properties section
  const [existingPropertiesOptions, setExistingPropertiesOptions] = useState([
    { value: "", label: "", data: {} },
  ]);

  //selected portfolio options
  const [selectedPropertyOption, setSelectedPropertyOption] = useState(null);

  const getPropertiesByPortfolioId = async (_portfolio_id, _property_id) => {
    const response = await fetchPropertyByPortfolioId(_portfolio_id);

    if (!response) {
      console.log("No properties found for this portfolio");
      return;
    }

    const options = response.map((property) => ({
      value: property.property_id,
      label: property.property_alias_name,
      data: property,
    }));
    setExistingPropertiesOptions(options);

    if (_property_id) {
      const selectedProperty = options.find(
        (property) => property.value === _property_id
      );
      if (selectedProperty) {
        setSelectedPropertyOption(selectedProperty);
        setContractTemplate((prevState) => ({
          ...prevState,
          property_id: _property_id,
        }));
      }
    }
  };

  const handlePropertyChange = (option) => {
    if (!option) {
      console.log("Cleared pressed");
      setSelectedPropertyOption(null);
      contractTemplate.property_id = "";
      return;
    }
    // This function will be called whenever the portfolio changes
    // 'option' is the selected option from the CreatableSelect
    console.log("Property changed:", option);
    // You can update the state or perform other side effects here
    contractTemplate.property_id = option.value;

    //set the selected portfolio
    setSelectedPropertyOption(option);
  };

  const fetchRentalContractTemplate = async () => {
    try {
      const contractTemplateData = await getUserRentalContractTemplateById(
        contractTemplateId
      );
      console.log("Fetched contractTemplate", contractTemplateData);

      setContractTemplate((prevState) => ({
        ...prevState,
        ...contractTemplateData,
        contract_template_id:
          contractTemplateData.contract_template_id || contractTemplateId,
        user_id:
          contractTemplateData.user_id ||
          localStorage.getItem(config.user_id) ||
          "",
        contract_name: contractTemplateData.contract_name || "",
      }));

      // Fetch portfolios and set the selected portfolio and property
      await getExistingPortfolios(
        contractTemplateData.portfolio_id,
        contractTemplateData.property_id
      );
    } catch (error) {
      console.error("Error fetching contract template:", error);
      toast.error("Failed to load contract template");
    } finally {
      setIsLoading(false);
    }
  };

  const getExistingPortfolios = async (_portfolio_id, _property_id) => {
    const userId = localStorage.getItem(config.user_id);
    const response = await getPortfolioByOwnershipId(userId);

    if (!response) {
      console.log("No portfolios found for this user");
      return;
    }

    const options = response.map((portfolio) => ({
      value: portfolio.portfolio_id,
      label: portfolio.portfolio_name,
      data: portfolio,
    }));
    setExistingPortfolioOptions(options);

    if (_portfolio_id) {
      const selectedPortfolio = options.find(
        (portfolio) => portfolio.value === _portfolio_id
      );
      if (selectedPortfolio) {
        setSelectedPortfolioOption(selectedPortfolio);
        setContractTemplate((prevState) => ({
          ...prevState,
          portfolio_id: _portfolio_id,
        }));

        // Fetch properties for the selected portfolio
        await getPropertiesByPortfolioId(_portfolio_id, _property_id);
      }
    }
  };

  useEffect(() => {
    fetchRentalContractTemplate();
  }, [contractTemplateId]);

  useEffect(() => {
    console.log("Updated contractTemplate state:", contractTemplate);
  }, [contractTemplate]);

  if (isLoading) {
    return <LoaderView />;
  }

  return (
    <React.Fragment>
      <div className={`${getThemeClasses()}`}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageTitle="Contract Template"
            previousPageLink={`/contractTemplate/${contractTemplateId}`}
          />

          <div className="row ">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Update Contract Template
                  </h4>
                  <div className="flex-shrink-0"></div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <div className="live-preview">
                      <div className="row gy-4">
                        {/* Hidden inputs for required fields */}
                        <input
                          type="hidden"
                          name="contract_template_id"
                          value={contractTemplate.contract_template_id}
                        />
                        <input
                          type="hidden"
                          name="user_id"
                          value={contractTemplate.user_id}
                        />

                        {/* Visible form fields */}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="contract_name"
                              label="Contract Name"
                              type="text"
                              value={contractTemplate.contract_name}
                              onChange={handleChange}
                              error={errors.contract_name}
                            />
                          </div>
                        </div>
                        {/* Select Portfolio cant add a new one*/}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <label htmlFor="form-grid-category">
                              Portfolio
                            </label>
                            <Select
                              name="portfolio_id"
                              options={existingPortfolioOptions}
                              onChange={handlePortfolioChange}
                              value={selectedPortfolioOption}
                              isClearable
                              styles={selectCustomStyle(isDarkMode)}
                            />
                          </div>
                        </div>
                        {/* Select Property*/}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <label htmlFor="form-grid-category">
                              Property (Optional)
                            </label>
                            <Select
                              name="property_id"
                              options={existingPropertiesOptions}
                              onChange={handlePropertyChange}
                              value={selectedPropertyOption}
                              isClearable
                              styles={selectCustomStyle(isDarkMode)}
                            />
                          </div>
                        </div>

                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="contract_description"
                              label="Contract Description"
                              type={"text"}
                              value={contractTemplate.contract_description}
                              onChange={handleChange}
                              error={errors.contract_description}
                            />
                          </div>
                        </div>
                        <div>
                          <div className="row g-3 pt-3">
                            <div className="col-lg-12">
                              <div className="row">
                                <div className="col-md-8 d-flex flex-column">
                                  <label
                                    for="exampleFormControlTextarea1"
                                    className="form-label"
                                  >
                                    Contract Template
                                  </label>

                                  <HTMLEditor
                                    handleChange={handleChange}
                                    name="contract_template"
                                    content={contractTemplate.contract_template}
                                    height="400px"
                                  />
                                </div>
                                {contractTemplate.contract_template &&
                                  contractTemplate.contract_template !== "" && (
                                    <div className="col-md-4">
                                      <label
                                        for="exampleFormControlTextarea1"
                                        className="form-label"
                                      >
                                        Preview
                                      </label>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            contractTemplate.contract_template.replace(
                                              /<img /g,
                                              '<img style="max-width: 100%; height: auto;" '
                                            ),
                                        }}
                                        style={{
                                          padding: "10px",
                                          border: "1px solid #ddd",
                                          borderRadius: "5px",
                                          overflow: "auto", // Changed from 'hidden' to 'auto'
                                          height: "440px",
                                        }}
                                      />
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Add to the list*/}
                        <div className="row gy-4 mt-3">
                          <div className="col-12 d-flex justify-content-between">
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              onClick={() =>
                                navigate(
                                  `/contractTemplate/${contractTemplateId}`
                                )
                              }
                            >
                              Cancel
                            </button>

                            <button
                              type="submit"
                              className="btn btn-success"
                              disabled={validate()}
                            >
                              Update Contract
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UpdateContractTemplate;
