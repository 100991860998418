import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  PaymentReceipt,
  PaymentStatus,
} from "../../../types/payment/payment-receipt";
import { getPaymentReceipt } from "../../../services/payment/paymentReceiptService";
import { formatCurrency } from "../../../services/utils/currencyUtils";
import { formatDate } from "../../../services/utils/dateUtils";
import { Card, Button, ThemeProvider, createTheme } from "@mui/material";
import PageTitleBox from "../../reusable/title/pageTitleBox";
import { toast } from "react-toastify";
import { useThemeClasses } from "../../../services/utils/hooks/useThemeClasses";
import DownloadIcon from "@mui/icons-material/Download";
import ShimmerEffect from "../../reusable/loading/ShimmerEffect";
import { devLog } from "services/utils/logger";

interface ReceiptViewProps {
  isDarkMode?: boolean;
}

const ReceiptView: React.FC<ReceiptViewProps> = ({ isDarkMode = false }) => {
  const { receiptId, propertyUnitId } = useParams<{
    receiptId: string;
    propertyUnitId: string;
  }>();
  const navigate = useNavigate();
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const [receipt, setReceipt] = useState<PaymentReceipt | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? "dark" : "light",
      background: {
        default: isDarkMode ? "#1a1a1a" : "#ffffff",
        paper: isDarkMode ? "#2d2d2d" : "#ffffff",
      },
      text: {
        primary: isDarkMode ? "#ffffff" : "#000000",
        secondary: isDarkMode
          ? "rgba(255, 255, 255, 0.7)"
          : "rgba(0, 0, 0, 0.6)",
      },
    },
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: isDarkMode ? "#2d2d2d" : "#ffffff",
            transition: "background-color 0.3s ease",
          },
        },
      },
    },
  });

  useEffect(() => {
    if (receiptId) {
      fetchReceipt(receiptId);
    }
  }, [receiptId]);

  const fetchReceipt = async (_receiptId: string) => {
    try {
      setIsLoading(true);
      const data = await getPaymentReceipt(_receiptId);
      setReceipt(data);
      devLog("Receipt fetched", data);
    } catch (error) {
      toast.error("Failed to load receipt");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadReceipt = () => {
    if (!receipt?.pdf_url) {
      toast.error("No PDF available for download");
      return;
    }

    // Open PDF in new tab
    window.open(receipt.pdf_url, "_blank");
  };

  if (isLoading) {
    return (
      <div className={getThemeClasses()}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle="Loading Receipt..."
            previousPageTitle="Payment Receipts"
            previousPageLink={`/paymentReceipts/${propertyUnitId}`}
          />
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <ShimmerEffect
                type="detail"
                height="600px"
                width="100%"
                darkMode={isDarkMode}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!receipt) {
    return (
      <div className={getThemeClasses()}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle="Receipt Not Found"
            previousPageTitle="Payment Receipts"
            previousPageLink={`/paymentReceipts/${propertyUnitId}`}
          />
          <div className="alert alert-danger">Receipt not found</div>
        </div>
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={getThemeClasses()}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle={`Receipt #${receipt.receipt_number}`}
            previousPageTitle="Payment Receipts"
            previousPageLink={`/paymentReceipts/${propertyUnitId}`}
          />

          <div className="row justify-content-center">
            <div className="col-lg-8 tw-max-h-[80vh] tw-overflow-y-auto">
              <Card
                className="shadow-sm"
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                  "& .text-muted": {
                    color: `${theme.palette.text.secondary} !important`,
                  },
                  "& .table": {
                    color: theme.palette.text.primary,
                    "& th": {
                      color: theme.palette.text.secondary,
                      borderColor: isDarkMode
                        ? "rgba(255, 255, 255, 0.1)"
                        : "rgba(0, 0, 0, 0.1)",
                    },
                    "& td": {
                      borderColor: isDarkMode
                        ? "rgba(255, 255, 255, 0.1)"
                        : "rgba(0, 0, 0, 0.1)",
                    },
                  },
                  "& .border-top, & .border-bottom": {
                    borderColor: `${
                      isDarkMode
                        ? "rgba(255, 255, 255, 0.1)"
                        : "rgba(0, 0, 0, 0.1)"
                    } !important`,
                  },
                }}
              >
                <div className="card-body p-5">
                  {/* Header */}
                  <div className="d-flex justify-content-between align-items-start mb-4">
                    <div>
                      <h2
                        className="mb-1"
                        style={{ color: theme.palette.text.primary }}
                      >
                        Payment Receipt
                      </h2>
                      <div className="text-muted">
                        #{receipt.receipt_number}
                      </div>
                    </div>
                    <div className="text-end">
                      <div className="mb-2">
                        <Button
                          variant="outlined"
                          startIcon={<DownloadIcon />}
                          onClick={handleDownloadReceipt}
                          disabled={!receipt.pdf_url}
                          size="small"
                          sx={{
                            color: theme.palette.text.primary,
                            borderColor: theme.palette.text.secondary,
                            "&:hover": {
                              borderColor: theme.palette.text.primary,
                              backgroundColor: isDarkMode
                                ? "rgba(255, 255, 255, 0.08)"
                                : "rgba(0, 0, 0, 0.04)",
                            },
                          }}
                        >
                          Download PDF
                        </Button>
                      </div>
                      <div className="text-muted mb-1">
                        Date: {formatDate(receipt.receipt_date)}
                      </div>
                      <span
                        className={`badge ${
                          receipt.payment_status === PaymentStatus.COMPLETED
                            ? "bg-success"
                            : "bg-warning"
                        }`}
                        style={{ opacity: isDarkMode ? 0.9 : 1 }}
                      >
                        {receipt.payment_status}
                      </span>
                    </div>
                  </div>

                  {/* Tenant & Property Info */}
                  <div className="row mb-5">
                    <div className="col-md-6">
                      <h5 className="text-muted mb-2">Tenant Information</h5>
                      <div className="mb-1">
                        {receipt.tenant_name || "Unknown"}
                      </div>
                    </div>
                    <div className="col-md-6 text-md-end">
                      <h5 className="text-muted mb-2">Property Information</h5>
                      <div className="mb-1">
                        Unit: {receipt?.property_unit_name}
                      </div>
                      <div className="mb-1">{receipt?.property_address}</div>
                      <div className="text-muted small">
                        Method: {receipt.payment_method}
                      </div>
                    </div>
                  </div>

                  {/* Line Items */}
                  <div className="table-responsive mb-4">
                    <table className="table table-borderless">
                      <thead className="border-bottom">
                        <tr>
                          <th scope="col">Item</th>
                          <th scope="col">Description</th>
                          <th scope="col">Period</th>
                          <th scope="col" className="text-end">
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {receipt.line_items.map((item, index) => (
                          <tr key={index}>
                            <td>{item.item_name}</td>
                            <td>{item.description || "-"}</td>
                            <td>
                              {item.period_start
                                ? `${formatDate(
                                    item.period_start
                                  )} - ${formatDate(item.period_end!)}`
                                : "-"}
                            </td>
                            <td className="text-end">
                              {formatCurrency(item.amount, receipt.currency)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot className="border-top">
                        <tr>
                          <td colSpan={3} className="text-end fw-bold">
                            Total
                          </td>
                          <td className="text-end fw-bold">
                            {formatCurrency(
                              receipt.total_amount,
                              receipt.currency
                            )}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>

                  {/* Additional Information */}
                  {receipt.notes && (
                    <div className="mb-4">
                      <h5 className="text-muted mb-2">Notes</h5>
                      <p className="mb-0">{receipt.notes}</p>
                    </div>
                  )}

                  {/* Footer */}
                  <div className="border-top pt-4 mt-4 pb-5">
                    <div className="row text-muted small">
                      <div className="col-md-6">
                        Created by: {receipt.created_by}
                        <br />
                        Created: {formatDate(receipt.created_at!)}
                      </div>
                      {receipt.updated_at && (
                        <div className="col-md-6 text-md-end">
                          Last updated: {formatDate(receipt.updated_at)}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default ReceiptView;
