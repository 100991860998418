import React from "react";
import { StripeConnectState } from "../../types/stripe-connect";
import ConnectButton from "./ConnectButton";
import { useStripeConnect } from "../../hooks/useStripeConnect";
import config from "../../constants/config.json";
import { useLocation, useNavigate } from "react-router-dom";

interface KYCBannerProps {
  userId: string;
  stripeAccountId?: string;
  isDarkMode?: boolean;
  state: StripeConnectState;
  onComplete: () => void;
}

const KYCBanner: React.FC<KYCBannerProps> = ({
  userId,
  stripeAccountId,
  isDarkMode = false,
  state,
  onComplete,
}) => {
  const { startOnboarding, resumeOnboarding } = useStripeConnect(
    stripeAccountId || ""
  );
  const location = useLocation();
  const navigate = useNavigate();

  // Don't render if account is verified
  if (state?.status && state.status === "active") return null;

  const handleConnect = async () => {
    // If not on profile page, navigate there first with instructions
    if (location.pathname !== "/profile") {
      navigate("/profile", {
        state: {
          showStripeInstructions: true,
          message:
            "Please fill out the Personal Information section below and click Save. Once saved, click Connect with Stripe to complete the verification process.",
        },
      });
      return;
    }

    try {
      await startOnboarding({
        user_id: userId,
        first_name: localStorage.getItem(config.first_name) || "",
        last_name: localStorage.getItem(config.last_name) || "",
        email_address: localStorage.getItem(config.email) || "",
      });
      onComplete();
    } catch (error) {
      console.error("Error starting onboarding:", error);
    }
  };

  const handleResume = async () => {
    try {
      await resumeOnboarding();
      onComplete();
    } catch (error) {
      console.error("Error resuming onboarding:", error);
    }
  };

  // If we have a Stripe account ID and status is not active, show resume button
  const showResumeButton = stripeAccountId && state.status !== "active";
  // If we don't have a Stripe account ID, show connect button
  const showConnectButton = !stripeAccountId;

  return (
    <div
      className={`tw-p-6 tw-rounded-lg tw-border ${
        isDarkMode ? "tw-border-gray-700" : "tw-border-gray-200"
      }`}
    >
      <div className="tw-flex tw-items-start tw-justify-between">
        <div className="tw-flex tw-items-start tw-gap-3">
          <div
            className={`tw-p-2 tw-rounded-full ${
              isDarkMode ? "tw-bg-yellow-900/20" : "tw-bg-yellow-50"
            }`}
          >
            <svg
              className="tw-w-5 tw-h-5 tw-text-yellow-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          <div>
            <h3
              className={`tw-text-lg tw-font-medium ${
                isDarkMode ? "tw-text-white" : "tw-text-gray-900"
              }`}
            >
              Account Verification Required
            </h3>
            <p
              className={`tw-mt-1 tw-text-sm ${
                isDarkMode ? "tw-text-gray-400" : "tw-text-gray-500"
              }`}
            >
              To receive payments and manage your properties, please complete
              the verification process. This helps us comply with financial
              regulations and ensure secure transactions.
            </p>
          </div>
        </div>
        <div className="tw-ml-6">
          {showConnectButton && (
            <button
              onClick={handleConnect}
              disabled={state.isLoading}
              className={`tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-text-sm tw-font-medium tw-rounded-md tw-shadow-sm tw-text-white tw-bg-primary hover:tw-bg-primary-dark focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-primary ${
                state.isLoading ? "tw-opacity-50 tw-cursor-not-allowed" : ""
              }`}
            >
              {state.isLoading ? "Connecting..." : "Connect with Stripe"}
            </button>
          )}
          {showResumeButton && (
            <button
              onClick={handleResume}
              disabled={state.isLoading}
              className={`tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-text-sm tw-font-medium tw-rounded-md tw-shadow-sm tw-text-white tw-bg-primary hover:tw-bg-primary-dark focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-primary ${
                state.isLoading ? "tw-opacity-50 tw-cursor-not-allowed" : ""
              }`}
            >
              {state.isLoading ? "Resuming..." : "Resume Verification"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default KYCBanner;
