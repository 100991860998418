export type AccountHolderType = "individual" | "company" | "";

export interface UserProfile {
  user_id: string;
  phone_number: string;
  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
  business_name: string;
  business_tax_id: string;
  business_website: string;
  dob_day: string;
  dob_month: string;
  dob_year: string;
  ssn_last_4: string;
  bank_account_holder_name: string;
  bank_account_holder_type: AccountHolderType;
  bank_routing_number: string;
  bank_account_number: string;
}

export const defaultUserProfile: UserProfile = {
  user_id: "",
  phone_number: "",
  address_line1: "",
  address_line2: "",
  city: "",
  state: "",
  postal_code: "",
  country: "US",
  business_name: "",
  business_tax_id: "",
  business_website: "",
  dob_day: "",
  dob_month: "",
  dob_year: "",
  ssn_last_4: "",
  bank_account_holder_name: "",
  bank_account_holder_type: "",
  bank_routing_number: "",
  bank_account_number: "",
};
